import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Product } from '../../../models/Product';
import { ProductsService } from '../../../services/Products.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ProductsFilterFields extends Product {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface ProductsResult extends ApiResults {
  results: Product[];
}

interface ProductsContextProps {
  products: ProductsResult | undefined;
  fetchProducts: () => Promise<any>;
  deleteProduct: (id: number) => Promise<void>;
  deleteManyProducts: (items: string[]) => Promise<void>;
  filterFields: ProductsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ProductsFilterFields>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ProductsContext = createContext<ProductsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ProductsPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [products, setProducts] = React.useState<ProductsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<ProductsFilterFields>({} as ProductsFilterFields);

  const fetchProducts = async () => {
    dispatch(showSpinner());
    try {
      const response = await ProductsService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setProducts(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error?.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteProduct = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ProductsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchProducts();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyProducts = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ProductsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchProducts();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchProducts();
  }, [filterFields, page, rowsPerPage]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchProducts();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const contextValue: ProductsContextProps = {
    products,
    fetchProducts,
    deleteProduct,
    deleteManyProducts,
    filterFields,
    setFilterFields,
    setShouldRefresh,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ProductsContext.Provider value={contextValue}>{children}</ProductsContext.Provider>;
};

export const useProductsPageContext = () => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error('useProductsContext must be used within a ProductsProvider');
  }
  return context;
};
