import { yupResolver } from '@hookform/resolvers/yup';
import { HelpCenter } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, IconButton, InputLabel, Popover, Stack, Typography } from '@mui/material'; // Import Tab components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Client } from '../../../models/Client';
import { FormMessage } from '../../../models/FormMessage';
import { User, allUserTypes } from '../../../models/User';
import { ErpClientsService } from '../../../services/ErpClients.service';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { UserSchema } from '../../../utils/forms/validations/formValidations';
import { findSelectedOption, setInputErrorsFromApi } from '../../../utils/utils';
import { ClientOptions } from '../../@service-order-page/ServiceOrderFormDialog/TabsContent/RegisterTab';
import { useUsersPageContext } from '../context/UsersPageContext';

interface Props {
  editItem?: User;
  onClose: () => void;
}

const creationUserTypes = [
  { id: 'ADMIN', name: 'Administrador' },
  { id: 'IPACOL', name: 'Ipacol' },
  { id: 'ASSISTANCE', name: 'Assistência' }
];

export const UsersFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchUsers } = useUsersPageContext();

  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [clientsOptions, setClientsOptions] = useState<ClientOptions[]>([]);
  const [showUserTypeDetails, setShowUserTypeDetails] = useState(null);
  const [selectedClient, setSelectedClient] = useState<SelectOption | null>(null);
  const [clientError, setClientError] = useState<SelectOption | null>(null);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<User>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(UserSchema),
    defaultValues: editItem
      ? {
          ...editItem,
          type: findSelectedOption(allUserTypes, editItem.type)
        }
      : {
          username: '',
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          confirm_password: '',
          is_active: true,
          type: allUserTypes[0]
        }
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: editItem?.is_active
  });

  const onSubmitForm: SubmitHandler<User> = async (data: User) => {
    let submitData = {
      ...data,
      client: selectedClient?.id || null
    };

    if (!editItem?.id && !data.password) {
      setError('password', {
        type: 'manual',
        message: 'A senha é obrigatória para um novo usuário'
      });
      return;
    }

    if (data.password && data.password !== data.confirm_password) {
      setError('confirm_password', {
        type: 'manual',
        message: 'As senhas não conferem'
      });
      return;
    }

    if (!data.password && !data.confirm_password) {
      delete submitData.password;
      delete submitData.confirm_password;
    }

    submitData.type = data.type.id;

    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await UsersService.update(submitData) : await UsersService.create(submitData);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Usuário atualizado com sucesso!' : 'Usuário criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchUsers();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
      if (error.data.client) {
        setClientError(error.data.client);
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectClient = (value: SelectOption) => {
    setSelectedClient(value);
  };

  const fetchClients = async (watchClientSearch?: string) => {
    try {
      const response = await ErpClientsService.get({
        page_size: 25,
        search: watchClientSearch
      });
      if (checkResponseStatus(response)) {
        setClientsOptions(
          response?.data.results.map((client: Client) => {
            return {
              id: client.id,
              name: `${client?.id} - ${client?.company_name}`,
              from_erp: client.from_erp
            };
          })
        );
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar clientes', severity: 'error' }));
    }
  };

  const retrieveClient = async (clientId: string) => {
    try {
      const response = await ErpClientsService.retrieve(clientId);
      if (checkResponseStatus(response) && response?.data) {
        const client = response.data as Client;
        setSelectedClient({ id: client.id, name: `${client?.id} - ${client?.company_name}` });
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar clientes', severity: 'error' }));
    }
  };

  const handleShowUserTypeDetails = (event: any) => {
    setShowUserTypeDetails(event.currentTarget);
  };

  const handleHideUserTypeDetails = () => {
    setShowUserTypeDetails(null);
  };

  useEffect(() => {
    if (editItem?.client) {
      retrieveClient(editItem.client);
    }
  }, []);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Usuário
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Usuário</InputLabel>
              <ControlledTextInput
                autoComplete="none"
                name="username"
                placeholder="Usuário"
                control={control}
                errorMessage={errors.username?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">E-mail</InputLabel>
              <ControlledTextInput
                placeholder="E-mail"
                name="email"
                type="email"
                control={control}
                errorMessage={errors.email?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Nome</InputLabel>
              <ControlledTextInput
                placeholder="Nome"
                name="first_name"
                control={control}
                errorMessage={errors.first_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Sobrenome</InputLabel>
              <ControlledTextInput
                placeholder="Sobrenome"
                name="last_name"
                control={control}
                errorMessage={errors.last_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center', pb: 1 }}>
                <InputLabel id="status">Tipo Usuário</InputLabel>
                <IconButton onMouseEnter={handleShowUserTypeDetails}>
                  <HelpCenter color="primary" fontSize="small" />
                </IconButton>
              </Stack>
              <ControlledComboBox
                name="type"
                control={control}
                errorMessage={errors.type?.message?.toString()}
                selectOptions={editItem?.id ? allUserTypes : creationUserTypes}
                disabled={editItem?.type == 'CLIENT'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="status">Situação</InputLabel>
              <ControlledCheckbox
                name="is_active"
                control={control}
                label={watchStatus ? 'Ativo' : 'Inativo'}
                errorMessage={errors.is_active?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="client">Cliente</InputLabel>
              <AutocompleteSearch
                value={selectedClient}
                onSearch={fetchClients}
                selectOptions={clientsOptions}
                onSelect={(event, value: SelectOption) => {
                  handleSelectClient(value);
                }}
                onChange={(event, value) => {
                  !value && handleSelectClient(value);
                }}
                errorMessage={clientError?.toString()}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Nova Senha</InputLabel>
              <ControlledTextInput
                autoComplete="new-password"
                placeholder="Nova Senha"
                name="password"
                type="password"
                control={control}
                errorMessage={errors.password?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Confirmar Senha</InputLabel>
              <ControlledTextInput
                autoComplete="new-password"
                placeholder="Confirmar Senha"
                name="confirm_password"
                type="password"
                control={control}
                errorMessage={errors.confirm_password?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>

      <Popover
        id="user-type-details-popover"
        open={!!showUserTypeDetails}
        anchorEl={showUserTypeDetails}
        onClose={handleHideUserTypeDetails}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack p={2} rowGap={2}>
          <Typography>
            <b>Administrador:</b> Acesso total ao sistema.
          </Typography>
          <Typography>
            <b>Ipacol:</b> Usuário interno - Acesso ao portal com base nas permissões.
          </Typography>
          <Typography>
            <b>Assistência:</b> Usuário externo - Acesso ao portal com base nas permissões.
          </Typography>
          <Typography>
            <b>Cliente: </b>Usuário externo - só pode ser criado a partir do registro de ordem de serviço (externo) ou na tela de Ordem de
            Serviço no botão (Novo Cliente)
          </Typography>
        </Stack>
      </Popover>
    </Dialog>
  );
};
