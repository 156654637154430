import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputLabel, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { DropAttachmentComponent } from '../../../components/basics/DropAttachmentComponent';
import { useUploadFile } from '../../../hooks/useUploadFileHook';
import { Manual } from '../../../models/Manual';
import { ErpProductsService } from '../../../services/ErpProducts.service';
import { ManualsService } from '../../../services/Manuals.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useManualsPageContext } from '../context/ManualsPageContext';

interface Props {
  editItem?: Manual;
  onClose: () => void;
}

const getDefaultValue = (editItem?: Manual) => {
  return editItem?.products_erp?.map((product) => ({ id: product.id, name: product.description })) || ([] as SelectOption[]);
};

export const ManualFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { setShouldRefresh } = useManualsPageContext();
  const { uploadFiles } = useUploadFile();

  const [uploadedFiles, setUploadedFiles] = useState<any[]>(editItem?.attachments || []);
  const [productsOptions, setProductsOptions] = useState<SelectOption[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<SelectOption[]>(getDefaultValue(editItem));

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<Partial<Manual>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: editItem ?? {
      description: '',
      is_active: true
    }
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const handleUploadFiles = (files: any) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDeleteFile = (file: any) => {
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleSelectProduct = (value: SelectOption[]) => {
    setSelectedProduct(value);
  };

  const fetchProducts = async (searchTerm: string) => {
    try {
      const response = await ErpProductsService.searchProduct(searchTerm);
      if (checkResponseStatus(response)) {
        setProductsOptions(response?.data?.results?.map((product: any) => ({ id: product.id, name: product.description })));
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          severity: 'error',
          message: error?.message || 'Erro ao buscar materiais'
        })
      );
    }
  };

  const formValidations = () => {
    if ((selectedProduct as SelectOption[])?.length == 0) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          severity: 'error',
          message: 'Produto não selecionado'
        })
      );
      return false;
    }
    return true;
  };

  const createOrUpdateManual = async (data: Partial<Manual>) => {
    if (!formValidations()) return;
    const files = await uploadFiles(uploadedFiles);

    let submitData = {
      id: editItem?.id || undefined,
      description: data.description,
      attachments: files,
      products_erp: selectedProduct?.map((product) => product.id),
      is_active: watchStatus
    };

    dispatch(showSpinner());
    try {
      editItem?.id ? await ManualsService.update(submitData) : await ManualsService.create(submitData);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Manual atualizado com sucesso!' : 'Manual criado com sucesso!',
          severity: 'success'
        })
      );
      setShouldRefresh(true);
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Manual
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="model">Modelo</InputLabel>
            <AutocompleteSearch
              multiple
              value={selectedProduct as SelectOption[]}
              defaultValue={getDefaultValue(editItem)}
              onSearch={fetchProducts}
              selectOptions={productsOptions}
              onSelect={(event, value) => handleSelectProduct(value as SelectOption[])}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="description">Descrição</InputLabel>
            <ControlledTextInput
              name="description"
              placeholder="Descrição"
              control={control}
              errorMessage={errors.description?.message?.toString()}
              minRows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <DropAttachmentComponent uploadedFiles={uploadedFiles} onUploadFile={handleUploadFiles} onDeleteFile={handleDeleteFile} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Situação:</Typography>
            <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateManual)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
