import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import { StyledTableHead } from '../../../../../../../components/basics/StyledTableHead';
import { Material } from '../../../../../../../models/Material';
import { formatCurrency, hexToRgba } from '../../../../../../../utils/utils';

interface Props {
  materials: Material[];
}

export const MaterialsComparisonValuesTable = ({ materials }: Props) => {
  const theme = useTheme();

  const returnBackgroundColor = (material: Material) => {
    return Number(material?.final_quantity) > material.quantity ||
      Number(material?.final_price) > Number(material.price) ||
      material.final_quantity === 0
      ? hexToRgba(theme.palette.error.main, 0.2)
      : hexToRgba(theme.palette.success.main, 0.2);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <StyledTableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Quantidade Estimada</TableCell>
            <TableCell>Quantidade Real</TableCell>
            <TableCell>Valor Estimado</TableCell>
            <TableCell>Valor Real</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {materials.map((material) => (
            <TableRow
              key={material.id}
              style={{
                backgroundColor: returnBackgroundColor(material)
              }}
            >
              <TableCell>{material.material}</TableCell>
              <TableCell>{material.description}</TableCell>
              <TableCell>{material.quantity}</TableCell>
              <TableCell>{material.final_quantity}</TableCell>
              <TableCell>{formatCurrency(material.price ?? 0)}</TableCell>
              <TableCell>{formatCurrency(material.final_price ?? 0)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
