import { AlertColor } from '@mui/material';
import { Attachment } from './Attachment';
import { Client } from './Client';
import { Expense } from './Expense';
import { Material, ResaleMaterial } from './Material';
import { Product } from './Product';
import { Technical } from './Technical';

export interface ServiceOrder {
  id: number;
  number: number;
  client?: Client | number;
  product: Product;
  technical: Technical;
  description: string;
  signature: Attachment;
  invoices_return_attachments: any[];
  evidence_date: string | null;
  evidence_observation: string;
  evidence_attachments: Attachment[];
  conclusion_attachments: Attachment[];
  conclusion_description: string;
  conclusion_date: string | null;
  completed: boolean | null;
  attachments: Attachment[];
  company_materials: Material[] | [];
  resale_materials: ResaleMaterial[] | [];
  expenses: Expense[] | [];
  created_at: string;
  updated_at: string;
  status: ServiceOrderStatus;
  status_label: string;
  reproved_description?: string;
  approved_description?: string;
  payment_authorized?: boolean;
  client_observation?: string;
}

export type ServiceOrderStatus =
  | 'REGISTER' // Cadastrada STATUS UTILIZADO APENAS NO FRONTEND
  | 'WAITING_TECHNICAL_ANALYSIS'
  | 'WAITING_MATERIALS_EXPENSES'
  | 'WAITING_APPROVAL'
  | 'WAITING_EVIDENCES'
  | 'WAITING_CONCLUSION'
  | 'REPROVED'
  | 'COMPLETED';

export const SERVICE_ORDER_STATUS = [
  { status: 'REGISTER', name: 'Registro', index: 0 },
  { status: 'WAITING_TECHNICAL_ANALYSIS', name: 'Aguardando Análise Técnica', index: 1 },
  { status: 'WAITING_MATERIALS_EXPENSES', name: 'Aguardando Materiais e Despesas', index: 2 },
  { status: 'WAITING_APPROVAL', name: 'Aguardando Aprovação', index: 3 },
  { status: 'WAITING_EVIDENCES', name: 'Aguardando Evidências', index: 4 },
  { status: 'WAITING_CONCLUSION', name: 'Aguardando Conclusão', index: 5 },
  { status: 'REPROVED', name: 'Reprovado', index: 0 },
  { status: 'COMPLETED', name: 'Completo', index: 5 }
];

export const SERVICE_ORDER_STATUS_OPTIONS = SERVICE_ORDER_STATUS.map((item) => ({
  id: item.status,
  name: item.name
}));

export const getStatusTab = (status: ServiceOrderStatus) => {
  //retornar o índice da tab de acordo com o status
  let index = SERVICE_ORDER_STATUS.find((item) => item.status === status)?.index;
  return index || 0;
};

export const getSeverityByStatus = (status: ServiceOrderStatus) => {
  let severityList = [
    { status: 'REGISTER', severity: 'info' },
    { status: 'WAITING_TECHNICAL_ANALYSIS', severity: 'info' },
    { status: 'WAITING_MATERIALS_EXPENSES', severity: 'info' },
    { status: 'WAITING_APPROVAL', severity: 'warning' },
    { status: 'WAITING_EVIDENCES', severity: 'info' },
    { status: 'WAITING_CONCLUSION', severity: 'info' },
    { status: 'REPROVED', severity: 'error' },
    { status: 'COMPLETED', severity: 'success' }
  ];

  let severity = severityList.find((item) => item.status === status)?.severity;
  return (severity || 'info') as AlertColor;
};

export const formatServiceOrderNumber = (number: number | string) => {
  return `#${String(number).padStart(5, '0')}`;
};
