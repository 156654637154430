import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissionsHook';
import { ServiceOrder } from '../../../models/ServiceOrder';
import { ServiceOrderDetailsDialog } from '../ServiceOrderDetailsDialog';
import { ServiceOrderFormDialog } from '../ServiceOrderFormDialog';
import { ServiceOrderFormContext } from '../ServiceOrderFormDialog/context/ServiceOrderContext';
import { ServiceOrdersFilterDialog } from '../ServiceOrdersFilterDialog';
import { ServiceOrdersTable } from '../ServiceOrdersTable';
import { useServiceOrdersPageContext } from '../context/ServiceOrdersPageContext';
import { ButtonsContainer } from './styles';

export const ServiceOrdersPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'WORK_ORDER'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<ServiceOrder | undefined>(undefined);
  const [viewItem, setViewItem] = useState<ServiceOrder | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields, fetchServiceOrders } = useServiceOrdersPageContext();

  const handleAddServiceOrder = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: ServiceOrder) => {
    setViewItem(item);
  };

  const handleEditItem = (item: ServiceOrder) => {
    setEditItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setViewItem(undefined);
  };

  const handleCloseFormDialog = () => {
    setEditItem(undefined);
    setNewItem(false);
    fetchServiceOrders();
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 1000);
      debounced();
      return debounced.cancel;
    } else {
      setFilterFields({
        ...filterFields,
        search: undefined
      });
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        <Box mr={1}>
          <ControlledTextInput
            control={control}
            name="search"
            label="Busca de Ordem de Serviço"
            placeholder="Digite para Pesquisar"
            sx={{ mr: 1 }}
            type="search"
          />
        </Box>
        <Tooltip title="Filtros">
          <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
            Filtros
          </Button>
        </Tooltip>
        {permissions.hasCreatePermission && (
          <Tooltip title="Adicionar ordem de serviço">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddServiceOrder}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <ServiceOrdersTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} />
      {showFilter && <ServiceOrdersFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <ServiceOrderDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && (
        <ServiceOrderFormContext editItem={editItem}>
          <ServiceOrderFormDialog editItem={editItem} onClose={handleCloseFormDialog} />
        </ServiceOrderFormContext>
      )}
    </>
  );
};
