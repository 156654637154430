import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FinancialRegistry } from '../../../models/FinancialRegistry';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { findSelectedOption } from '../../../utils/utils';
import { PayServiceOrderFilterFields, usePayServiceOrderPageContext } from '../context/PayServiceOrderPageContext';

interface Props {
  onClose: () => void;
}

const filterStatusOptions: SelectOption[] = [
  { id: null, name: 'Todos' },
  { id: 1, name: 'Pago' },
  { id: 0, name: 'Aberto' }
];

const getDefaultValues = (filter?: FinancialRegistry, userOptions?: SelectOption[]): PayServiceOrderFilterFields => {
  return {
    work_order: filter?.work_order || '',
    number: filter?.number || '',
    due_at: filter?.due_at || '',
    paid_at: filter?.paid_at || '',
    was_paid: findSelectedOption(filterStatusOptions, filter?.was_paid) ?? filterStatusOptions[0].id,
    technical:
      filter?.technical && userOptions?.length > 0 ? findSelectedOption(userOptions, filter?.technical) : { id: null, name: 'Todos' }
  };
};

export const PayServiceOrderFilterDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields } = usePayServiceOrderPageContext();

  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<PayServiceOrderFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields, userOptions)
  });

  const fetchUsers = async () => {
    dispatch(showSpinner({ message: 'Aguarde, buscando dados' }));
    try {
      const response = await UsersService.get({
        type: ['ASSISTANCE'],
        page_size: 0
      });
      if (checkResponseStatus(response)) {
        setUserOptions(
          response?.data.results.map((user: any) => ({
            id: user.id,
            name: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email
          }))
        );
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar técnicos', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    reset(getDefaultValues(filterFields, userOptions));
  }, [userOptions]);

  const onSubmit: SubmitHandler<PayServiceOrderFilterFields> = async (data: PayServiceOrderFilterFields) => {
    if (data.was_paid?.id != null) {
      data.was_paid = data.was_paid.id;
    } else {
      delete data.was_paid;
    }
    setFilterFields({ ...data, technical: data.technical.id } as PayServiceOrderFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as PayServiceOrderFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="technical">Técnico</InputLabel>
              <ControlledComboBox
                control={control}
                name="technical"
                placeholder="Selecione um técnico"
                selectOptions={userOptions}
                errorMessage={errors.technical?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="work_order">Ordem</InputLabel>
              <ControlledTextInput
                placeholder="Ordem"
                name="work_order"
                control={control}
                errorMessage={errors.work_order?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="was_paid">Situação</InputLabel>
              <ControlledComboBox
                name="was_paid"
                placeholder="Situação"
                control={control}
                errorMessage={errors.was_paid?.message?.toString()}
                selectOptions={filterStatusOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="number">N° Pagamento</InputLabel>
              <ControlledTextInput
                placeholder="N° Pagamento"
                name="number"
                control={control}
                errorMessage={errors.number?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="due_at">Data de Vencimento</InputLabel>
              <ControlledTextInput
                placeholder="Data de Vencimento"
                name="due_at"
                control={control}
                errorMessage={errors.due_at?.message?.toString()}
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="paid_at">Data de Pagamento</InputLabel>
              <ControlledTextInput
                placeholder="Data de Pagamento"
                name="paid_at"
                control={control}
                errorMessage={errors.paid_at?.message?.toString()}
                type="date"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
