import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';

import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, Card, CardContent, Divider, Grid, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlledCheckbox } from '../../../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import DropAttachmentThumbComponent from '../../../../../components/basics/DropAttachmentThumbComponent';
import { useUploadFile } from '../../../../../hooks/useUploadFileHook';
import { Defect } from '../../../../../models/Defect';
import { DefectGroup } from '../../../../../models/DefectGroup';
import { DefectLocation } from '../../../../../models/DefectLocation';
import { DefectsLocationsService } from '../../../../../services/DefectsLocations.service';
import { ServiceOrderTechnicalAnalysesService } from '../../../../../services/ServiceOrderTechnicalAnalyses.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../../store/store';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { TechnicalAnalysisSchema } from '../../../../../utils/forms/validations/formValidations';
import { formatDateToApi, hexToRgba, setInputErrorsFromApi } from '../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../context/ServiceOrderContext';
import { AnalysisHistoryCard } from './AnalysisHistoryCard';

export interface TechnicalAnalysisDefectsList {
  formIdHash: string;
  location: number;
  group: number;
  defects_groups_relationships: number[]; //equivalente ao relashionship_id do DEFEITO no backend
  defaultValue?: boolean;
}

export interface TechnicalAnalysisFormFields {
  support_address: string;
  use_client_address: boolean;
  work_order: number;
  attachments: number[];
  service_type: SelectOption;
  date: string;
  description: string;
  defects: {
    location: string;
    defects_groups_relationships: number[];
  }[];
}

export const TechnicalAnalysisTab = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.globalSpinner);

  const { uploadFiles } = useUploadFile();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const { serviceOrder, fetchServiceOrder, serviceOrderPartialUpdate } = useServiceOrderFormContext();

  const [availableLocations, setAvailableLocations] = useState<DefectLocation[]>([]);
  const [defectsLocations, setDefectsLocations] = useState<DefectLocation[]>([]);

  const [defectsList, setDefectsList] = useState<TechnicalAnalysisDefectsList[]>([]);

  const FINISHED_ORDER = serviceOrder.status == 'REPROVED' || serviceOrder.status == 'COMPLETED';
  const [useClientAddress, setUseClientAddress] = useState(true);

  const handleUseClientAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('use_client_address', event.target.checked);
    setUseClientAddress(event.target.checked);
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<TechnicalAnalysisFormFields>({
    mode: 'onChange',
    resolver: yupResolver(TechnicalAnalysisSchema),
    defaultValues: {
      service_type: { id: 'WARRANTY', name: 'Garantia' }
    }
  });

  const updateServiceOrderStatus = async () => {
    try {
      dispatch(showSpinner());
      const response = await serviceOrderPartialUpdate({ status: 'WAITING_MATERIALS_EXPENSES' });
      if (checkResponseStatus(response)) {
        await fetchServiceOrder();
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message || 'Erro ao incluir análise técnica', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const formValidations = () => {
    if (defectsList.length == 0 || defectsList.find((item) => !item.location || !item.group || !item.defects_groups_relationships.length)) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'É necessário preencher todos os campos dos defeitos', severity: 'error' }));
      return false;
    }
    return true;
  };

  const onSubmit = async (data: TechnicalAnalysisFormFields) => {
    if (!formValidations()) return;

    //upload dos arquivos para a API
    const filesIds = await uploadFiles(uploadedFiles);

    const formSubmit = {
      work_order: serviceOrder.id,
      service_type: (data.service_type as SelectOption).id,
      date: (data.date && formatDateToApi(data.date)) || null,
      description: data.description,
      support_address: data.support_address,
      use_client_address: data.use_client_address,
      attachments: filesIds,
      defects: defectsList
    };

    try {
      dispatch(showSpinner());
      const response = await ServiceOrderTechnicalAnalysesService.create(formSubmit);
      if (checkResponseStatus(response)) {
        dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'Análise técnica incluída com sucesso', severity: 'success' }));
        await updateServiceOrderStatus();
      }
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(showSnackbarAlert({ title: 'Erro', message: formError, severity: 'error' }));
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleUploadFiles = (files: any) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDeleteFile = (file: any) => {
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleAddNewDefectLocation = () => {
    setDefectsList([
      ...defectsList,
      {
        formIdHash: uniqueId(),
        location: 0,
        group: 0,
        defects_groups_relationships: []
      }
    ]);
  };

  const handleDeleteDefectLocation = (formIdHash: string) => {
    const newDefectsList = defectsList.filter((defect) => defect.formIdHash !== formIdHash);
    setDefectsList(newDefectsList);
  };

  const handleDefectLocationChange = (event: any, value: any, index: number) => {
    const newDefectsList = [...defectsList];
    newDefectsList[index].location = value?.id || 0;
    newDefectsList[index].group = 0;
    newDefectsList[index].defects_groups_relationships = [];
    setDefectsList(newDefectsList);
  };

  const handleDefectGroupChange = (event: any, value: any, index: number) => {
    const newDefectsList = [...defectsList];
    newDefectsList[index].group = value?.id;
    newDefectsList[index].defects_groups_relationships = [];
    setDefectsList(newDefectsList);
  };

  const handleDefectsChange = (event: any, value: any, index: number) => {
    const newDefectsList = [...defectsList];
    newDefectsList[index].defects_groups_relationships = value.map((item: Defect) => item.relationship_id);
    setDefectsList(newDefectsList);
  };

  const getDefectsGroupsByLocationId = (locationId: number) => {
    const location = defectsLocations.find((location) => location.id == locationId);
    return (location?.groups || []) as DefectGroup[];
  };

  const getDefectsByGroupId = (locationId: number, groupId: number) => {
    const location = defectsLocations.find((location) => location.id == locationId);
    const group = location?.groups?.find((group: any) => group.id == groupId) as DefectGroup;
    return (group?.defects || []) as Defect[];
  };

  const fetchDefectsLocations = async () => {
    dispatch(showSpinner());
    try {
      const response = await DefectsLocationsService.getAll(true);
      if (checkResponseStatus(response)) {
        let defectsLocations = response?.data?.results || [];
        setDefectsLocations(defectsLocations);
        setAvailableLocations(defectsLocations);
      }
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar defeitos', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    //mostrar como disponível apenas o que já não está presente na defectsList
    const newAvailableLocations = defectsLocations.filter((location) => {
      const locationAlreadySelected = defectsList.find((defect) => defect.location == location.id);
      return !locationAlreadySelected;
    });
    setAvailableLocations(newAvailableLocations);
  }, [defectsList]);

  useEffect(() => {
    fetchDefectsLocations();
  }, []);

  return (
    <>
      <Box p={2} m={2}>
        {/* Conteúdo da aba Análise Técnica */}
        {!FINISHED_ORDER && (
          <>
            <Typography variant="h3" gutterBottom>
              Análise Técnica
            </Typography>

            <Card sx={{ mt: 2, p: 2 }} elevation={2}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom>
                      Dados do Produto
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Nro de Série: {serviceOrder?.product?.serial_number}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Descrição: {serviceOrder?.product?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom>
                      Tipo
                    </Typography>
                    <ControlledComboBox
                      control={control}
                      name="service_type"
                      placeholder="Selecione um tipo"
                      selectOptions={[
                        { id: 'WARRANTY', name: 'Garantia' },
                        { id: 'ASSISTANCE', name: 'Assistência' },
                        { id: 'TECHNICAL_DELIVERY', name: 'Entrega Técnica' }
                      ]}
                      errorMessage={errors.service_type?.message?.toString()}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 4 }} />
                    <Typography variant="h5" gutterBottom>
                      Defeitos
                    </Typography>
                  </Grid>

                  <>
                    {/* SELECTS DE LOCAL DO DEFEITO E DEFEITOS */}
                    {defectsList?.map((defect, index) => {
                      return (
                        <Grid item xs={12} key={defect.formIdHash}>
                          <Grid container spacing={2}>
                            <Grid item sm={12} md={4}>
                              <InputLabel htmlFor="defeito">Selecione o local do(s) defeito(s)</InputLabel>
                              <Autocomplete
                                size="small"
                                options={availableLocations}
                                getOptionLabel={(option) => String(option.description)}
                                onChange={(event, value) => handleDefectLocationChange(event, value, index)}
                                renderInput={(params) => <TextField {...params} size="small" label="" />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText="Nenhum local disponível"
                              />
                            </Grid>

                            <Grid item sm={12} md={4}>
                              <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <InputLabel htmlFor="defeito">Grupo(s) de defeitos</InputLabel>
                                <Autocomplete
                                  size="small"
                                  options={getDefectsGroupsByLocationId(Number(defect.location))}
                                  getOptionLabel={(option) => String(option.description)}
                                  onChange={(event, value) => handleDefectGroupChange(event, value, index)}
                                  renderInput={(params) => <TextField {...params} size="small" label="" />}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  noOptionsText="Nenhum grupo disponível"
                                />
                              </Box>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <InputLabel htmlFor="defeito">Selecione o(s) defeito(s)</InputLabel>
                                <Autocomplete
                                  multiple
                                  size="small"
                                  options={getDefectsByGroupId(Number(defect.location), Number(defect.group))}
                                  getOptionLabel={(option) => String(option.description)}
                                  onChange={(event, value) => handleDefectsChange(event, value, index)}
                                  renderInput={(params) => <TextField {...params} size="small" label="" />}
                                  noOptionsText="Nenhum defeito disponível"
                                />
                              </Box>
                            </Grid>

                            {Boolean(index == defectsList.length - 1) && defectsList.length > 1 && (
                              <Grid
                                item
                                sm={12}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderTop: 'solid 0.5px',
                                  borderColor: hexToRgba(theme.palette.primary.main, 0.5),
                                  divider: 'true',
                                  mt: 2,
                                  ml: 2
                                }}
                              >
                                <Button
                                  variant="text"
                                  color="primary"
                                  startIcon={<CloseIcon />}
                                  onClick={() => handleDeleteDefectLocation(defect.formIdHash)}
                                  sx={{ mt: -1 }}
                                >
                                  Excluir Local
                                </Button>
                              </Grid>
                            )}

                            <Divider />
                          </Grid>
                        </Grid>
                      );
                    })}
                    {availableLocations.length > 0 && (
                      <Grid item sm={12} sx={{ mt: -1 }}>
                        <InputLabel sx={{ mb: 1 }}>Adicionar local de defeito</InputLabel>
                        <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddNewDefectLocation}>
                          Adicionar
                        </Button>
                      </Grid>
                    )}
                  </>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 4 }} />
                    <Typography variant="h5" gutterBottom>
                      Dados da Análise
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel htmlFor="analysis_date">Data</InputLabel>
                    <ControlledTextInput
                      control={control}
                      name="date"
                      mask="99/99/9999"
                      placeholder="dd/mm/aaaa"
                      errorMessage={errors.date?.message?.toString()}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputLabel htmlFor="support_address">Local para Atendimento</InputLabel>
                    <ControlledTextInput
                      control={control}
                      name="support_address"
                      placeholder="Local para Atendimento"
                      errorMessage={errors.support_address?.message?.toString()}
                      disabled={useClientAddress}
                    />
                    <ControlledCheckbox
                      control={control}
                      name="use_client_address"
                      label="Utilizar endereço do cliente"
                      checked={useClientAddress}
                      onChange={handleUseClientAddressChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel htmlFor="description">Descrição</InputLabel>
                    <ControlledTextInput
                      control={control}
                      name="description"
                      placeholder="Descrição do problema"
                      minRows={4}
                      errorMessage={errors.description?.message?.toString()}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 4 }} />
                    <Typography variant="h5" gutterBottom sx={{ pb: 2 }}>
                      Anexos (Fotos, Vídeos e Arquivos)
                    </Typography>
                    <DropAttachmentThumbComponent
                      uploadedFiles={uploadedFiles}
                      onUploadFile={handleUploadFiles}
                      onDeleteFile={handleDeleteFile}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="success" startIcon={<ArrowForwardIcon />} onClick={handleSubmit(onSubmit)}>
                      Incluir
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        )}

        {/* CARD DE HISTORICO DE ANÁLISES */}
        <Box sx={{ mt: FINISHED_ORDER ? 0 : 4 }}>
          <AnalysisHistoryCard />
        </Box>
      </Box>
    </>
  );
};
