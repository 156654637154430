import { TableRow } from '@mui/material';
import styled from 'styled-components';

export const StyledTableRow = styled(TableRow)(({ theme }) => {
  return {
    '&:last-child td, &:last-child th': {
      border: 0
    }
  };
});
