import { DefectsPage } from '../../../sections/@defects-page/DefectsPage';
import { DefectsPageContext } from '../../../sections/@defects-page/context/DefectsPageContext';

export const DefectsPageWrapper = () => {
  return (
    <DefectsPageContext>
      <DefectsPage />
    </DefectsPageContext>
  );
};
