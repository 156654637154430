import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { useDownloadFileHook } from '../../../hooks/useDownloadFileHook';

interface Props {
  uploadedFiles: any[];
  onUploadFile?: (files: any[]) => void;
  onDeleteFile?: (file: any) => void;
  disabled?: boolean;
}

export const DropAttachmentComponent = ({ uploadedFiles, onUploadFile, onDeleteFile, disabled }: Props) => {
  const { downloadFile } = useDownloadFileHook();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const filesUnder50MB = acceptedFiles.filter((file) => file.size <= 50 * 1024 * 1024); // 50 MB in bytes
      if (filesUnder50MB.length === acceptedFiles.length) {
        onUploadFile && onUploadFile(filesUnder50MB);
      } else {
        alert('O tamanho do arquivo deve ser no máximo 50 MB.');
      }
    }
  });

  return (
    <>
      {!disabled && (
        <div {...getRootProps()}>
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            sx={{
              display: 'flex',
              flex: 1,
              p: 2,
              border: 'solid 0.5px #d9d9d9',
              borderRadius: '4px',
              justifyContent: 'space-evenly'
            }}
          >
            <AttachFileIcon fontSize="small" />
            <Typography variant="body2" gutterBottom>
              Arraste e largue seu(s) arquivo(s) aqui para anexar ou <Link to="#">localize em seu computador.</Link>
              <br />
              Você pode anexar vários arquivos de uma vez.
            </Typography>
            <Typography variant="caption">Tamanho máximo de 50 MB</Typography>
          </Stack>
          <input {...getInputProps()} />
        </div>
      )}

      {uploadedFiles?.length > 0 ? (
        <List sx={{ mt: disabled ? 0 : 2 }}>
          <Typography variant="body1" gutterBottom color="secondary">
            Arquivos anexados:
          </Typography>
          <Divider />
          {uploadedFiles?.map((file, index) => (
            <ListItem key={index}>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                {onDeleteFile && (
                  <IconButton onClick={() => onDeleteFile(file)}>
                    <DeleteIcon color="primary" />
                  </IconButton>
                )}
                {file?.file && (
                  <IconButton onClick={() => downloadFile(file)}>
                    <DownloadIcon color="primary" />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="caption">Nenhum arquivo anexado.</Typography>
      )}
    </>
  );
};
