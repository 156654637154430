import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Card, CardContent, Divider, Grid, IconButton, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { Client } from '../../../../../models/Client';
import { Expense } from '../../../../../models/Expense';
import { Material, ResaleMaterial } from '../../../../../models/Material';
import { ServiceOrder } from '../../../../../models/ServiceOrder';
import { ErpProductsService } from '../../../../../services/ErpProducts.service';
import { ExpensesService } from '../../../../../services/Expenses.service';
import { ServiceOrdersService } from '../../../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { formatCurrency, formatCurrencyToNumber } from '../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../context/ServiceOrderContext';

export interface ExpenseObj extends Expense {
  multiplier?: number;
}

export const MaterialsExpensesTab = () => {
  const dispatch = useDispatch();
  const { serviceOrder, fetchServiceOrder, serviceOrderPartialUpdate } = useServiceOrderFormContext();

  const [materialsOptions, setMaterialsOptions] = useState<any[]>([]);
  const [materialFields, setMaterialFields] = useState<Material[]>([]);

  const [resaleMaterialsFields, setResaleMaterialsFields] = useState<ResaleMaterial[]>([]);

  const [expensesOptions, setExpensesOptions] = useState<any[]>([]);
  const [expenseFields, setExpenseFields] = useState<ExpenseObj[]>([]);

  const FINISHED_ORDER = serviceOrder.status == 'REPROVED' || serviceOrder.status == 'COMPLETED';
  const WAITING_MATERIALS_EXPENSES = serviceOrder.status == 'WAITING_MATERIALS_EXPENSES';

  const quantityFieldRef = useRef(null);

  const formValidations = () => {
    if (materialFields.length > 0) {
      if (materialFields.some((item) => !item.material || !item.quantity)) {
        dispatch(
          showSnackbarAlert({
            severity: 'error',
            title: 'Erro ao salvar',
            message: 'Preencha todos os campos de material'
          })
        );
        return false;
      }
    }

    if (expenseFields.length > 0) {
      if (expenseFields.some((item) => !item.expense || !item.cost || item.cost == '0')) {
        dispatch(
          showSnackbarAlert({
            severity: 'error',
            title: 'Erro ao salvar',
            message: 'Preencha todos os campos de despesa'
          })
        );
        return false;
      }
    }

    return true;
  };

  const updateServiceOrderStatus = async () => {
    dispatch(showSpinner());
    try {
      await serviceOrderPartialUpdate({ status: 'WAITING_APPROVAL', reproved_description: '' });
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.message || 'Erro ao atualizar status da ordem de serviço', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const onSubmit = async () => {
    if (!formValidations()) return;

    let dataToSubmit = {
      id: serviceOrder.id,
      client: (serviceOrder?.client as Client).id as number,
      serial_number: serviceOrder.product.serial_number,
      company_materials: materialFields as Material[],
      resale_materials: resaleMaterialsFields.map((item) => ({
        material_description: item.material_description,
        quantity: item.quantity,
        price: formatCurrencyToNumber(item.price)
      })),
      expenses: expenseFields.map((item) => ({
        expense: Number(item.expense),
        quantity: item.quantity,
        cost: formatCurrencyToNumber(item.cost)
      }))
    };

    try {
      dispatch(showSpinner());
      const response = await ServiceOrdersService.update(dataToSubmit);
      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            severity: 'success',
            message: 'Os materiais e despesas foram incluídos na ordem de serviço'
          })
        );
      }

      await updateServiceOrderStatus();
      await fetchServiceOrder();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          severity: 'error',
          message: error?.message || 'Erro ao atualizar ordem de serviço'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchMaterials = async (searchTerm: string) => {
    try {
      const response = await ErpProductsService.searchProduct(searchTerm);
      if (checkResponseStatus(response)) {
        setMaterialsOptions(
          response?.data?.results?.map((material: any) => ({ id: material.id, name: material.id + ' - ' + material.description }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          severity: 'error',
          message: error?.message || 'Erro ao buscar materiais'
        })
      );
    }
  };

  const fetchExpenses = async (searchTerm: string) => {
    try {
      const response = await ExpensesService.searchExpenses(searchTerm);
      if (checkResponseStatus(response)) {
        setExpensesOptions(
          response?.data?.results.map((expense: any) => ({
            id: expense.id,
            name: expense.description,
            multiplier: expense.multiplier || 1
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          severity: 'error',
          message: error?.message || 'Erro ao buscar despesas'
        })
      );
    }
  };

  const handleAddMaterial = () => {
    setMaterialsOptions([]);
    setMaterialFields([
      ...materialFields,
      {
        material: '',
        quantity: 0,
        description: '',
        id: 0,
        name: '',
        price: '',
        final_price: null,
        final_quantity: null,
        created_at: '',
        updated_at: ''
      }
    ]);
  };

  const handleDeleteMaterial = (index: number) => {
    setMaterialFields(materialFields.filter((item, i) => i !== index));
  };

  const handleAddResaleMaterial = () => {
    setMaterialsOptions([]);
    setResaleMaterialsFields([...resaleMaterialsFields, { material_description: '', quantity: 0, price: 0, description: '' }]);
  };

  const handleDeleteResaleMaterial = (index: number) => {
    setResaleMaterialsFields(resaleMaterialsFields.filter((item, i) => i !== index));
  };

  const handleAddExpense = () => {
    setExpensesOptions([]);
    setExpenseFields([...expenseFields, { expense: '', quantity: 0, cost: '', description: '' }]);
  };

  const handleDeleteExpense = (index: number) => {
    setExpenseFields(expenseFields.filter((item, i) => i !== index));
  };

  const handleSelectMaterial = (value: SelectOption, index: number) => {
    let updatedMaterialFields = [...materialFields];
    updatedMaterialFields[index].material = value.id;
    updatedMaterialFields[index].description = value.name!;
    setMaterialFields(updatedMaterialFields);
  };

  const handleSelectResaleMaterial = (value: string, index: number) => {
    let updatedMaterialFields = [...resaleMaterialsFields];
    updatedMaterialFields[index].material_description = value;
    setResaleMaterialsFields(updatedMaterialFields);
  };

  const handleSelectExpense = (value: SelectOption, index: number) => {
    let updatedExpenseFields = [...expenseFields];
    updatedExpenseFields[index].expense = value.id;
    updatedExpenseFields[index].multiplier = value.multiplier || 1;
    updatedExpenseFields[index].description = value.name!;
    setExpenseFields(updatedExpenseFields);
  };

  const handleChangeMaterialQuantity = (event: any, index: number) => {
    const updatedMaterialFields = [...materialFields];
    updatedMaterialFields[index].quantity = event.target.value;
    setMaterialFields(updatedMaterialFields);
  };

  const handleChangeResaleMaterialQuantity = (event: any, index: number) => {
    const updatedMaterialFields = [...resaleMaterialsFields];
    updatedMaterialFields[index].quantity = event.target.value;
    setResaleMaterialsFields(updatedMaterialFields);
  };

  const handleChangeResaleMaterialValue = (event: any, index: number) => {
    const updatedMaterialFields = [...resaleMaterialsFields];
    updatedMaterialFields[index].price = event.target.value;
    setResaleMaterialsFields(updatedMaterialFields);
  };

  const handleChangeExpenseQuantity = (event: any, index: number) => {
    const updatedExpenseFields = [...expenseFields];
    updatedExpenseFields[index].quantity = event.target.value;
    updatedExpenseFields[index].cost = Number(updatedExpenseFields[index].multiplier) * Number(event.target.value);
    setExpenseFields(updatedExpenseFields);
  };

  const handleChangeExpenseValue = (event: any, index: number) => {
    const updatedExpenseFields = [...expenseFields];
    updatedExpenseFields[index].cost = event.target.value;
    setExpenseFields(updatedExpenseFields);
  };

  const checkReproveReason = () => {
    if (serviceOrder?.reproved_description) {
      dispatch(
        showSnackbarAlert({
          title: 'Aviso',
          message: `A ordem de serviço foi reprovada pelo motivo: ${serviceOrder.reproved_description}`,
          severity: 'warning'
        })
      );
    }
  };

  const loadDefaultValues = (serviceOrder: ServiceOrder) => {
    if (serviceOrder.company_materials.length > 0) {
      setMaterialFields(
        serviceOrder?.company_materials?.map((item: Material) => ({
          ...item,
          material: item.material,
          quantity: item.quantity,
          description: item.material + ' - ' + item.description
        }))
      );
    }

    if (serviceOrder.resale_materials.length > 0) {
      setResaleMaterialsFields(
        serviceOrder?.resale_materials?.map((item: ResaleMaterial) => ({
          ...item,
          material_description: item.material_description,
          quantity: item.quantity,
          price: formatCurrency(item.price.toString()),
          description: item.material_description
        }))
      );
    }

    if (serviceOrder.expenses.length > 0) {
      setExpenseFields(
        serviceOrder?.expenses?.map((item: ExpenseObj) => ({
          ...item,
          expense: item.expense,
          quantity: item.quantity,
          cost: formatCurrency(item.cost!.toString()),
          description: item.description?.toString() || ''
        }))
      );
    }

    checkReproveReason();
  };

  useEffect(() => {
    loadDefaultValues(serviceOrder);
  }, [serviceOrder]);

  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Análise Técnica */}
      <Typography variant="h3" gutterBottom>
        Materiais e Despesas
      </Typography>

      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Dados do Produto
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nro de Série: {serviceOrder?.product?.serial_number}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Descrição: {serviceOrder?.product?.description}
              </Typography>
              <Divider sx={{ mt: 4 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom sx={{ pb: materialFields.length > 0 ? 3 : 0 }}>
                Peças/Materiais
              </Typography>
              <Stack rowGap={2}>
                {materialFields?.map((item, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={10}>
                      <InputLabel htmlFor="material">Material</InputLabel>
                      <AutocompleteSearch
                        value={item.material ? { id: item.material, name: item.description } : null}
                        onSearch={fetchMaterials}
                        selectOptions={materialsOptions}
                        nextFieldRef={quantityFieldRef}
                        onSelect={(event, value) => handleSelectMaterial(value, index)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Box>
                          <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                          <TextField
                            value={item.quantity}
                            type="number"
                            size="small"
                            fullWidth={FINISHED_ORDER}
                            inputRef={quantityFieldRef}
                            onChange={(event) => handleChangeMaterialQuantity(event, index)}
                          />
                        </Box>
                        {!FINISHED_ORDER && WAITING_MATERIALS_EXPENSES && (
                          <IconButton onClick={() => handleDeleteMaterial(index)} color="primary">
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Stack>

              {!FINISHED_ORDER && WAITING_MATERIALS_EXPENSES && (
                <Button variant="outlined" onClick={handleAddMaterial} startIcon={<AddIcon />} sx={{ mt: 4 }}>
                  Adicionar Item
                </Button>
              )}
              <Divider sx={{ mt: 4 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom sx={{ pb: resaleMaterialsFields.length > 0 ? 3 : 0 }}>
                Peças/Materiais Revenda
              </Typography>
              <Stack rowGap={2}>
                {resaleMaterialsFields?.map((item, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <InputLabel htmlFor="material">Material</InputLabel>
                      <TextField
                        value={item.material_description}
                        size="small"
                        fullWidth
                        onChange={(event) => handleSelectResaleMaterial(event.target.value, index)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Box>
                          <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                          <TextField
                            name="quantity"
                            value={item.quantity}
                            type="number"
                            size="small"
                            fullWidth={FINISHED_ORDER}
                            inputRef={quantityFieldRef}
                            onChange={(event) => handleChangeResaleMaterialQuantity(event, index)}
                          />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="cost">Valor</InputLabel>
                          <NumericFormat
                            defaultValue={item.price}
                            fixedDecimalScale
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            allowNegative={false}
                            customInput={TextField}
                            InputProps={{ size: 'small', placeholder: 'Valor' }}
                            onChange={(event) => handleChangeResaleMaterialValue(event, index)}
                            fullWidth={FINISHED_ORDER}
                          />
                        </Box>
                        {!FINISHED_ORDER && WAITING_MATERIALS_EXPENSES && (
                          <IconButton onClick={() => handleDeleteResaleMaterial(index)} color="primary">
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Stack>

              {!FINISHED_ORDER && WAITING_MATERIALS_EXPENSES && (
                <Button variant="outlined" onClick={handleAddResaleMaterial} startIcon={<AddIcon />} sx={{ mt: 4 }}>
                  Adicionar Item
                </Button>
              )}
              <Divider sx={{ mt: 4 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom sx={{ pb: expenseFields.length > 0 ? 3 : 0 }}>
                Despesas
              </Typography>
              <Stack rowGap={2}>
                {expenseFields?.map((item, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <InputLabel htmlFor="expense">Despesa</InputLabel>
                      <AutocompleteSearch
                        value={item.expense ? { id: item.expense, name: item.description } : null}
                        onSearch={fetchExpenses}
                        selectOptions={expensesOptions}
                        nextFieldRef={quantityFieldRef}
                        onSelect={(event, value) => handleSelectExpense(value, index)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Box>
                          <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                          <TextField
                            value={item.quantity}
                            type="number"
                            size="small"
                            fullWidth={FINISHED_ORDER}
                            inputRef={quantityFieldRef}
                            onChange={(event) => handleChangeExpenseQuantity(event, index)}
                          />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="cost">Valor</InputLabel>
                          <NumericFormat
                            defaultValue={item.cost}
                            value={item.cost}
                            fixedDecimalScale
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            allowNegative={false}
                            customInput={TextField}
                            InputProps={{ size: 'small', placeholder: 'Valor' }}
                            onChange={(event) => handleChangeExpenseValue(event, index)}
                            fullWidth={FINISHED_ORDER}
                          />
                        </Box>
                        {!FINISHED_ORDER && WAITING_MATERIALS_EXPENSES && (
                          <IconButton onClick={() => handleDeleteExpense(index)} color="primary">
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Stack>

              {!FINISHED_ORDER && WAITING_MATERIALS_EXPENSES && (
                <Button variant="outlined" onClick={handleAddExpense} startIcon={<AddIcon />} sx={{ mt: 4 }}>
                  Adicionar Despesa
                </Button>
              )}
            </Grid>

            {!FINISHED_ORDER && WAITING_MATERIALS_EXPENSES && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="success" startIcon={<SaveIcon />} onClick={onSubmit}>
                  Solicitar Aprovação
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
