import FilterListIcon from '@mui/icons-material/FilterList';
import PaidIcon from '@mui/icons-material/Paid';
import { Button, Tooltip } from '@mui/material';
import { useState } from 'react';

import { PaymentAuthorizationFilterDialog } from '../PaymentAuthorizationFilterDialog';
import { PaymentAuthorizationFormDialog } from '../PaymentAuthorizationFormDialog';
import { PaymentAuthorizationTable } from '../PaymentAuthorizationTable';
import { ButtonsContainer } from './styles';

export const PaymentAuthorizationPage = () => {
  const [newItem, setNewItem] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [saved, setSaved] = useState(0);

  const updateSelectedItem = (newValue) => {
    setSelectedItem(newValue);
  };

  const updateSaved = (value) => {
    setSaved(value);
  };

  const handleAddFinancialRegistry = () => {
    setNewItem(true);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    updateSaved(0);
  };

  return (
    <>
      <ButtonsContainer>
        <Tooltip title="Filtros">
          <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
            Filtros
          </Button>
        </Tooltip>
        <Tooltip title="Autorizar Pagamento">
          <Button startIcon={<PaidIcon />} variant="contained" onClick={handleAddFinancialRegistry}>
            Autorizar Pagamento
          </Button>
        </Tooltip>
      </ButtonsContainer>
      <PaymentAuthorizationTable updateSelectedItem={updateSelectedItem} saved={saved} />
      {newItem && <PaymentAuthorizationFormDialog onClose={handleCloseDialogs} idServiceOrder={selectedItem} updateSaved={updateSaved} />}
      {showFilter && <PaymentAuthorizationFilterDialog onClose={handleCloseDialogs} />}
    </>
  );
};
