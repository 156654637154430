import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, IconButton, Paper, Stack, Switch, Table, TableContainer, Tooltip, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableBody } from '../../../../../components/basics/StyledTableBody';
import { StyledTableCell } from '../../../../../components/basics/StyledTableCell';
import { StyledTableRow } from '../../../../../components/basics/StyledTableRow';
import { StyledTableCellHeader } from '../../../../../components/basics/TableHeaderComponent/styles';
import { PermissionGroup } from '../../../../../models/PermissionGroup';
import { User } from '../../../../../models/User';
import { UserPermission } from '../../../../../models/UserPermission';
import { PermissionGroupsService } from '../../../../../services/PermissionGroups.service';
import { UserPermissionsService } from '../../../../../services/UserPermissions.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../../store/store';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { PermissionGroupsDetailsDialog } from '../../../../@permission-groups-page/PermissionGroupsDetailsDialog';

interface Props {
  user: User;
  onClose: () => void;
}

interface PermissionGroupForm extends PermissionGroup {
  isActive: boolean;
}

export const PermissionGroupsTab = ({ user, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [userPermissionData, setUserPermissionData] = useState<UserPermission>({});

  const [groups, setGroups] = useState<PermissionGroupForm[]>([]);
  const [groupDetails, setGroupDetails] = useState<PermissionGroup | null>(null);

  const { loading } = useSelector((state: RootState) => state.globalSpinner);

  const handleSwitchChange = (groupId: number, checked: boolean) => {
    const updatedGroups = groups.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          isActive: checked
        };
      }
      return group;
    });
    setGroups(updatedGroups);
  };

  const onSubmit = async () => {
    const groupsToSave = groups.filter((group) => group.isActive);
    let groupsIds = groupsToSave.map((group) => group.id?.toString()) as string[];

    dispatch(showSpinner());

    try {
      const response = await UserPermissionsService.update({
        id: userPermissionData.id,
        user: (userPermissionData.user as User).id?.toString(),
        groups: groupsIds
      });
      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Permissões do usuário atualizadas com sucesso',
            severity: 'success'
          })
        );
        onClose();
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao atualizar permissões do usuário',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleShowGroupDetails = (group: PermissionGroup) => {
    setGroupDetails(group);
  };

  const fetchUserPermissions = async () => {
    try {
      dispatch(showSpinner());
      const response = await UserPermissionsService.get({
        user: user?.id?.toString()
      });
      if (checkResponseStatus(response)) {
        let userPermission = response?.data.results?.[0];
        setUserPermissionData(userPermission);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchGroups = async () => {
    try {
      dispatch(showSpinner());
      const response = await PermissionGroupsService.getAll();
      if (response?.data?.results) {
        const groups = response.data.results;
        const groupsWithActive = groups.map((group: any) => {
          const isActive = userPermissionData.groups?.some((permissionGroup: PermissionGroup) => permissionGroup.id === group.id);
          return {
            ...group,
            isActive
          };
        });
        setGroups(groupsWithActive);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar grupos de permissões',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    if (userPermissionData?.id) {
      fetchGroups();
    }
  }, [userPermissionData]);

  useEffect(() => {
    if (user?.id) {
      fetchUserPermissions();
    }
  }, [user]);

  return (
    <>
      {!loading && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', px: 4, pt: 1 }}>
            <Box>
              <Typography variant="h5">Selecione os grupos de permissões que o usuário terá acesso.</Typography>
              <Typography variant="body1">
                Toque no botão para ativar ou desativar um grupo para o usuário. <br /> Você pode ver as permissões do grupo clicando em
                detalhes.
              </Typography>
              <TableContainer component={Paper} sx={{ mt: 6, borderRadius: '4px', p: 1 }} elevation={1}>
                <Table aria-label="simple table">
                  <StyledTableRow>
                    <StyledTableCellHeader>
                      <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                        Grupo
                      </Typography>
                    </StyledTableCellHeader>
                    <StyledTableCellHeader>
                      <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                        Ativo
                      </Typography>
                    </StyledTableCellHeader>
                    <StyledTableCellHeader>
                      <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                        Detalhes
                      </Typography>
                    </StyledTableCellHeader>
                  </StyledTableRow>
                  <StyledTableBody>
                    {groups.map((group, index) => (
                      <StyledTableRow key={group.id} key={index}>
                        <StyledTableCell>
                          <Typography variant="h6">{group.name}</Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            checked={group.isActive}
                            onChange={() => handleSwitchChange(group.id, !group.isActive)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton onClick={() => handleShowGroupDetails(group)}>
                            <Tooltip title="Toque para visualizar o grupo">
                              <VisibilityIcon />
                            </Tooltip>
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </StyledTableBody>
                </Table>
              </TableContainer>
            </Box>
            <Stack flexDirection="row" columnGap={2} justifyContent="flex-end" mt={2}>
              <Button variant="contained" color="primary" onClick={onSubmit}>
                Salvar
              </Button>
              <Button variant="outlined" color="primary" onClick={onClose}>
                Cancelar
              </Button>
            </Stack>
          </Box>
          {groupDetails?.id && <PermissionGroupsDetailsDialog item={groupDetails} onClose={() => setGroupDetails(null)} />}
        </>
      )}
    </>
  );
};
