import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import { StyledTableHead } from '../../../../../../../components/basics/StyledTableHead';
import { formatCurrency, hexToRgba } from '../../../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../../../context/ServiceOrderContext';

export const TotalValuesTable = () => {
  const theme = useTheme();
  const { serviceOrder } = useServiceOrderFormContext();

  const totalValues = [
    {
      description: 'Materiais Ipacol',
      estimatedValue: serviceOrder?.company_materials?.reduce(
        (acc, material) => acc + Number(material.quantity) * Number(material.price),
        0
      ),
      realValue: serviceOrder?.company_materials?.reduce(
        (acc, material) => acc + Number(material.final_quantity) * Number(material.final_price),
        0
      )
    },
    {
      description: 'Materiais Revenda',
      estimatedValue: serviceOrder?.resale_materials?.reduce(
        (acc, material) => acc + Number(material.quantity) * Number(material.price),
        0
      ),
      realValue: serviceOrder?.resale_materials?.reduce(
        (acc, material) => acc + Number(material.final_quantity) * Number(material.final_price),
        0
      )
    },
    {
      description: 'Despesas',
      estimatedValue: serviceOrder?.expenses?.reduce((acc, expense) => acc + Number(expense.cost), 0),
      realValue: serviceOrder?.expenses?.reduce((acc, expense) => acc + Number(expense.final_cost), 0)
    }
  ];

  const returnBackgroundColor = (estimated: number, real: number) => {
    return Number(real) > Number(estimated) ? hexToRgba(theme.palette.error.main, 0.2) : hexToRgba(theme.palette.success.main, 0.2);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <StyledTableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Valor Estimado</TableCell>
            <TableCell>Valor Real</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {totalValues.map((totalValue, index) => (
            <TableRow key={index} sx={{ backgroundColor: returnBackgroundColor(totalValue.estimatedValue, totalValue.realValue) }}>
              <TableCell>{totalValue.description}</TableCell>
              <TableCell>{formatCurrency(totalValue.estimatedValue)}</TableCell>
              <TableCell>{formatCurrency(totalValue.realValue)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
