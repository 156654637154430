import { lazy } from 'react';
import { Loadable } from '../components/basics/LazyLoaderComponent/Loadable';
import MainLayout from '../layout/MainLayout';
import { ClientsPageWrapper } from '../pages/private/Client';
import { CompaniesPageWrapper } from '../pages/private/Companies';
import { CompanyParametersPageWrapper } from '../pages/private/CompanyParameters';
import { DefectsPageWrapper } from '../pages/private/Defects';
import { DefectsGroupsPageWrapper } from '../pages/private/DefectsGroups';
import { DefectsLocationsPageWrapper } from '../pages/private/DefectsLocations';
import { ExpensesPageWrapper } from '../pages/private/Expenses';
import { ManualsPageWrapper } from '../pages/private/Manuals';
import { PayServiceOrderPageWrapper } from '../pages/private/PayServiceOrder';
import { PaymentAuthorizationPageWrapper } from '../pages/private/PaymentAuthorization';
import { PermissionGroupsPageWrapper } from '../pages/private/PermissionGroups';
import { ProductsPageWrapper } from '../pages/private/Products';
import { ServiceOrderWrapper } from '../pages/private/ServiceOrder';
import { UsersPageWrapper } from '../pages/private/Users';
import { ROUTES } from './routes';

const DashboardDefault = Loadable(lazy(() => import('../pages/private/Dashboard')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: ROUTES.authenticated.dashboard.home,
      element: <DashboardDefault />
    },
    {
      path: ROUTES.authenticated.serviceOrder.serviceOrders,
      element: <ServiceOrderWrapper />
    },
    {
      path: ROUTES.authenticated.serviceOrder.defects,
      element: <DefectsPageWrapper />
    },
    {
      path: ROUTES.authenticated.serviceOrder.defectsLocations,
      element: <DefectsLocationsPageWrapper />
    },
    {
      path: ROUTES.authenticated.serviceOrder.defectsGroups,
      element: <DefectsGroupsPageWrapper />
    },
    {
      path: ROUTES.authenticated.serviceOrder.expenses,
      element: <ExpensesPageWrapper />
    },
    {
      path: ROUTES.authenticated.registers.companies,
      element: <CompaniesPageWrapper />
    },
    {
      path: ROUTES.authenticated.registers.manuals,
      element: <ManualsPageWrapper />
    },
    {
      path: ROUTES.authenticated.registers.users,
      element: <UsersPageWrapper />
    },
    {
      path: ROUTES.authenticated.registers.products,
      element: <ProductsPageWrapper />
    },
    {
      path: ROUTES.authenticated.auxiliary.permissionGroups,
      element: <PermissionGroupsPageWrapper />
    },
    {
      path: ROUTES.authenticated.auxiliary.customerActivation,
      element: <ClientsPageWrapper />
    },
    {
      path: ROUTES.authenticated.financial.paymentAutorization,
      element: <PaymentAuthorizationPageWrapper />
    },
    {
      path: ROUTES.authenticated.financial.payServiceOrder,
      element: <PayServiceOrderPageWrapper />
    },
    {
      path: ROUTES.authenticated.auxiliary.parameters,
      element: <CompanyParametersPageWrapper />
    }
    // {
    //   path: ROUTES.authenticated.user.profile,
    //   element: <ProfilePageWrapper />
    // },
  ]
};

export default MainRoutes;
