import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { DefectLocation } from '../models/DefectLocation';
import { DefectsLocationsFilterFields } from '../sections/@defects-locations-page/context/DefectsPlacesPageContext';

const DefectsLocationsUrl = '/work-orders/defects/locations/';

export const DefectsLocationsService = {
  getAll: async (isActive?: boolean) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(DefectsLocationsUrl, {
        params: {
          is_active: isActive,
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: DefectsLocationsFilterFields, page: number = 0, page_size: number = 1000) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(DefectsLocationsUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: DefectLocation) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(DefectsLocationsUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  partialUpdate: async (data: DefectLocation) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.patch(`${DefectsLocationsUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: DefectLocation) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${DefectsLocationsUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${DefectsLocationsUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${DefectsLocationsUrl}batch-delete/`, {
        ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
