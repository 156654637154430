import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Stack } from '@mui/system';
import { Transition } from '../TransitionComponent';

interface Props {
  open?: boolean;
  title?: string;

  description?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;

  showCloseButton?: boolean;
  onClose?: () => void;

  children?: any;
  fullWidth?: boolean;
  fullScreen?: boolean;
}

export const DialogComponent = ({
  open = true,
  title,
  description,
  children,
  fullScreen = false,
  fullWidth = false,
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Confirmar',
  showCloseButton,
  onClose,
  onCancel,
  onConfirm
}: Props) => {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} onClose={onCancel} fullScreen={ismobile || fullScreen} TransitionComponent={Transition} fullWidth={fullWidth}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', marginRight: '1rem' }}>
            <DialogTitle variant="h4" color="primary.main">
              {title}
            </DialogTitle>
            {showCloseButton && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: (theme) => theme.palette.primary.main
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
          <Divider />
          <DialogContent sx={{ mt: 2 }}>
            {description ? (
              <Stack spacing={4}>
                <Typography variant="body1">{description}</Typography>
              </Stack>
            ) : (
              <>{children}</>
            )}
          </DialogContent>
          {(onCancel || onConfirm) && (
            <DialogActions sx={{ p: 2 }}>
              {onCancel && (
                <Button startIcon={<CloseIcon />} fullWidth={ismobile || (!fullScreen && !fullWidth)} variant="outlined" onClick={onCancel}>
                  {cancelButtonText}
                </Button>
              )}
              {onConfirm && (
                <Button
                  startIcon={<CheckIcon />}
                  fullWidth={ismobile || (!fullScreen && !fullWidth)}
                  variant="contained"
                  onClick={onConfirm}
                >
                  {confirmButtonText}
                </Button>
              )}
            </DialogActions>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
