import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { INavGroup, INavItem } from '../../../../../../../models/Menu';
import { activeNavGroup, activeNavItem } from '../../../../../../../store/slicers/menu.slicers';
import { NavGroup } from '../../NavGroup';
import NavItem from '../../NavItem';

interface Props {
  item: INavGroup;
  groupOpen: boolean;
  navItemSelected: boolean;
}

export const DrawerOpenWithSubmenus = ({ item, groupOpen, navItemSelected }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClick = () => {
    dispatch(activeNavGroup({ openNavGroup: item.id }));

    if (item?.url) {
      dispatch(activeNavItem({ openNavItem: '' }));
    }
  };

  const itemTarget = item?.target ? '_blank' : '_self';

  // Determine o tipo de componente com base na propriedade 'external'
  const listItemProps = item?.external
    ? { component: 'a', href: item.url, target: itemTarget }
    : item?.url
    ? { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) }
    : {};

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ pl: item.level * 1.6 }} {...listItemProps}>
        <ListItemIcon
          sx={{
            color: navItemSelected ? theme.palette.primary.main : theme.palette.common.black,
            minWidth: 'auto',
            mr: 1
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            variant: 'h6',
            fontSize: item.level === 1 ? '0.875rem' : '0.8rem',
            color: navItemSelected ? 'primary.main' : 'inherit'
          }}
        />
        <>
          {/* SE NÃO TIVER URL PARA O ITEM, MOSTRA O ICONE DO SUBMENU. */}
          {!item.url && (
            <>
              {groupOpen ? (
                <ExpandLess color={navItemSelected ? 'primary' : 'inherit'} fontSize={item.level == 1 ? 'medium' : 'small'} />
              ) : (
                <ExpandMore color={navItemSelected ? 'primary' : 'inherit'} fontSize={item.level == 1 ? 'medium' : 'small'} />
              )}
            </>
          )}
        </>
      </ListItemButton>

      {/* SE O MENU TIVER ITENS FILHOS, RENDERIZA OS MESMOS */}
      {item?.children && item?.children?.length > 0 && (
        <Collapse in={groupOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item?.children?.map((menuItem: INavItem | INavGroup) => {
              //se houver um submenu, renderiza o componente NavGroup
              if (menuItem.type === 'group') {
                return <NavGroup key={menuItem.id} item={menuItem as INavGroup} />;
              } else {
                return <NavItem key={menuItem.id} item={menuItem} level={item.level} />;
              }
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};
