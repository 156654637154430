import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { ServiceOrder } from '../../../models/ServiceOrder';
import { PaymentAuthorizationService } from '../../../services/PaymentAuthorization.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface PaymentAuthorizationFilterFields extends ServiceOrder {
  order_by?: string;
  page?: number;
  page_size?: number;
}

export interface PaymentAuthorizationResult extends ApiResults {
  results: ServiceOrder[];
}

interface PaymentAuthorizationContextProps {
  paymentAuthorizations: PaymentAuthorizationResult | undefined;
  fetchPaymentAuthorizations: () => void;
  filterFields: PaymentAuthorizationFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<PaymentAuthorizationFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const PaymentAuthorizationPageContext = createContext<PaymentAuthorizationContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const PaymentAuthorizationPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [paymentAuthorizations, setPaymentAuthorizations] = useState<PaymentAuthorizationResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<PaymentAuthorizationFilterFields>({} as PaymentAuthorizationFilterFields);

  const fetchPaymentAuthorizations = async () => {
    dispatch(showSpinner());
    try {
      const response = await PaymentAuthorizationService.get({
        ...filterFields,
        is_assistance: 1,
        status: 'COMPLETED',
        payment_authorized: false
      });
      if (response && checkResponseStatus(response)) {
        const result = response?.data?.results;
        setPaymentAuthorizations(result);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchPaymentAuthorizations();
  }, [filterFields]);

  const contextValue: PaymentAuthorizationContextProps = {
    paymentAuthorizations,
    fetchPaymentAuthorizations,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <PaymentAuthorizationPageContext.Provider value={contextValue}>{children}</PaymentAuthorizationPageContext.Provider>;
};

export const usePaymentAuthorizationPageContext = () => {
  const context = useContext(PaymentAuthorizationPageContext);
  if (!context) {
    throw new Error('usePaymentAuthorizationPageContext must be used within a PaymentAuthorizationPageContextProvider');
  }
  return context;
};
