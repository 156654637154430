import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Company } from '../../../models/Company';
import { CompaniesService } from '../../../services/Companies.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CompanySchema } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useCompaniesPageContext } from '../context/CompaniesPageContext';

interface Props {
  editItem?: Company;
  onClose: () => void;
}

export const CompanyFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchCompanies } = useCompaniesPageContext();

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<Company>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CompanySchema),
    defaultValues: editItem ?? {
      company_name: '',
      trade_name: '',
      is_active: true,
      is_main: false
    }
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const watchIsMain = useWatch({
    control,
    name: 'is_main',
    defaultValue: getValues('is_main')
  });

  const createOrUpdateCompany: SubmitHandler<Company> = async (data: Company) => {
    dispatch(showSpinner());
    try {
      editItem?.id ? await CompaniesService.update(data) : await CompaniesService.create(data);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Empresa atualizada com sucesso!' : 'Empresa criada com sucesso!',
          severity: 'success'
        })
      );
      fetchCompanies();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Empresa
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateCompany)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company_name">Razão Social</InputLabel>
              <ControlledTextInput
                name="company_name"
                placeholder="Razão Social"
                control={control}
                errorMessage={errors.company_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="trade_name">Nome Fantasia</InputLabel>
              <ControlledTextInput
                placeholder="Nome Fantasia"
                name="trade_name"
                control={control}
                errorMessage={errors.trade_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Empresa Principal?:</Typography>
              <ControlledCheckbox name="is_main" label={watchIsMain ? 'Sim' : 'Não'} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateCompany)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
