import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Client } from '../../../models/Client';
import { Product } from '../../../models/Product';
import { ClientsService } from '../../../services/Clients.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { ProductsFilterFields, useProductsPageContext } from '../context/ProductsPageContext';

interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: Product): Partial<ProductsFilterFields> => {
  return {
    serial_number: filter?.serial_number ?? '',
    quality_sequence: filter?.quality_sequence ?? ''
  };
};

export const ProductsFilterDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields } = useProductsPageContext();

  const [clientsOptions, setClientsOptions] = useState<SelectOption[]>([]);
  const [selectedClient, setSelectedClient] = useState<SelectOption>({} as SelectOption);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Partial<ProductsFilterFields>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit = async (data: Partial<ProductsFilterFields>) => {
    data.client = selectedClient?.id ?? null;
    setFilterFields({ ...data } as ProductsFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ProductsFilterFields);
    onClose();
  };

  const fetchClients = async (watchClientSearch?: string) => {
    try {
      const response = await ClientsService.getWithErp({
        search: watchClientSearch
      });
      if (checkResponseStatus(response)) {
        setClientsOptions(
          response?.data.results.map((client: Client) => ({
            id: client.id,
            name: client.first_name ? `${client.first_name} ${client.last_name}` : client.company_name,
            from_erp: client.from_erp
          }))
        );
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar clientes', severity: 'error' }));
    }
  };

  const handleSelectClient = (value: SelectOption) => {
    setSelectedClient(value);
  };

  useEffect(() => {
    if (filterFields?.client) {
      const client = filterFields.client as Client;
      setSelectedClient({ id: client.id, name: client.company_name });
      setClientsOptions([{ id: client.id, name: client.company_name }]);
    }
  }, [filterFields]);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="client">Cliente</InputLabel>
              <AutocompleteSearch
                value={selectedClient}
                onSearch={fetchClients}
                selectOptions={clientsOptions}
                onSelect={(event, value: SelectOption) => handleSelectClient(value)}
                errorMessage={errors.client?.id?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="serial_number">Número de Série</InputLabel>
              <ControlledTextInput
                name="serial_number"
                placeholder="Número de Série"
                control={control}
                errorMessage={errors.serial_number?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="quality_sequence">Sequência de Qualidade</InputLabel>
              <ControlledTextInput
                name="quality_sequence"
                placeholder="Sequência de Qualidade"
                control={control}
                errorMessage={errors.quality_sequence?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
