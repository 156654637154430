import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { StyledTableCellHeader } from './styles';

export type Order = 'asc' | 'desc';

export interface HeadCell {
  disablePadding: boolean;
  disableSorting: boolean;
  id: keyof any;
  label: string;
  align: 'left' | 'right' | 'center';
}

interface EnhancedTableProps {
  headCells: readonly HeadCell[];
  numSelected: number;
  onRequestSort: (property: any) => void;
  onSelectAllClick: (selected: boolean) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  hideCheckbox?: boolean;
}

export const TableHeaderComponent = ({
  headCells,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  hideCheckbox = false
}: EnhancedTableProps) => {
  const [selectedAll, setSelectedAll] = React.useState(false);

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(property);
  };

  const handleSelectAllClick = () => {
    setSelectedAll(!selectedAll);
    onSelectAllClick(!selectedAll);
  };

  return (
    <TableHead>
      <TableRow>
        {!hideCheckbox && (
          <StyledTableCellHeader padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={selectedAll}
              onChange={handleSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </StyledTableCellHeader>
        )}
        {headCells.map((headCell, index) => (
          <StyledTableCellHeader
            key={index}
            align={headCell.align || 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSorting ? (
              <span>{headCell.label}</span>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id as string)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableCellHeader>
        ))}
      </TableRow>
    </TableHead>
  );
};
