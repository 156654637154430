import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project imports
import { INavGroup, INavItem } from '../../models/Menu';
import MainCard from './MainCard';

// ==============================|| BREADCRUMBS ||============================== //

interface BreadcrumbsProps {
  navigation: {
    items: INavGroup[];
  };
  title: boolean;
}

const Breadcrumbs = ({ navigation, title, ...others }: BreadcrumbsProps) => {
  const location = useLocation();
  const [main, setMain] = useState<INavGroup | undefined>(undefined);
  const [item, setItem] = useState<INavItem | undefined>(undefined);

  // set active item state
  const findActiveItem = (menu: INavGroup | INavItem) => {
    if ((menu as INavGroup).children) {
      (menu as INavGroup)?.children?.forEach((child) => {
        if (child.type === 'group' || child.type === 'item') {
          if (location.pathname === child.url) {
            setMain(menu as INavGroup);
            setItem(child);
          }
          if (child.type === 'group') {
            findActiveItem(child);
          }
        }
      });
    }
  };

  useEffect(() => {
    navigation.items?.forEach((menu) => {
      if (menu.type === 'group') {
        findActiveItem(menu);
      } else if (menu.type === 'item') {
        if (location.pathname === menu.url) {
          setItem(menu);
        }
      }
    });
  }, [location.pathname, navigation]);

  let mainContent;
  let itemContent;
  let breadcrumbContent = <Typography />;

  // collapse item
  if (main && main.type === 'group') {
    mainContent = (
      <Typography component={Link} to={document.location.pathname} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
        {main.title}
      </Typography>
    );
  }

  // items
  if (item && item.type === 'item') {
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {item.title}
      </Typography>
    );

    // main
    if (item.breadcrumbs) {
      breadcrumbContent = (
        <MainCard border={false} sx={{ mb: 3, bgcolor: 'transparent' }} {...others} content={false}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item>
              <MuiBreadcrumbs aria-label="breadcrumb">
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
          </Grid>
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
