import { PayServiceOrderPage } from '../../../sections/@pay-service-orders/PayServiceOrderPage';
import { PayServiceOrderPageContextProvider } from '../../../sections/@pay-service-orders/context/PayServiceOrderPageContext';

export const PayServiceOrderPageWrapper = () => {
  return (
    <PayServiceOrderPageContextProvider>
      <PayServiceOrderPage />
    </PayServiceOrderPageContextProvider>
  );
};
