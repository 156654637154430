import { Box, Card, CardContent, Divider, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Props } from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
import { Material, ResaleMaterial } from '../../../../../models/Material';
import { ServiceOrder } from '../../../../../models/ServiceOrder';
import { formatCurrency } from '../../../../../utils/utils';
import { ExpenseObj } from '../../../ServiceOrderFormDialog/TabsContent/MaterialsExpensesTab';

interface Props {
  serviceOrder: ServiceOrder;
}

export const MaterialsExpensesTab = ({ serviceOrder }: Props) => {
  const [materialFields, setMaterialFields] = useState<Material[]>([]);
  const [resaleMaterialsFields, setResaleMaterialsFields] = useState<ResaleMaterial[]>([]);
  const [expenseFields, setExpenseFields] = useState<ExpenseObj[]>([]);

  const loadDefaultValues = (serviceOrder: ServiceOrder) => {
    if (serviceOrder.company_materials.length > 0) {
      setMaterialFields(
        serviceOrder?.company_materials?.map((item: Material) => ({
          ...item,
          material: item.material,
          quantity: item.quantity,
          description: item.material + ' - ' + item.description
        }))
      );
    }

    if (serviceOrder.resale_materials.length > 0) {
      setResaleMaterialsFields(
        serviceOrder?.resale_materials?.map((item: ResaleMaterial) => ({
          ...item,
          material_description: item.material_description,
          quantity: item.quantity,
          price: formatCurrency(item.price.toString())
        }))
      );
    }

    if (serviceOrder.expenses.length > 0) {
      setExpenseFields(
        serviceOrder?.expenses?.map((item: ExpenseObj) => ({
          ...item,
          expense: item.expense,
          quantity: item.quantity,
          cost: formatCurrency(item.cost!.toString()),
          description: item.description?.toString() || ''
        }))
      );
    }
  };

  useEffect(() => {
    loadDefaultValues(serviceOrder);
  }, [serviceOrder]);

  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Análise Técnica */}
      <Typography variant="h3" gutterBottom>
        Materiais e Despesas
      </Typography>

      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          {/* <form onSubmit={onSubmit}> */}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom sx={{ pb: 3 }}>
                Peças/Materiais Ipacol
              </Typography>
              <Stack rowGap={2}>
                {materialFields.map((item, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <TextField value={item.description} label="Material" size="small" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <TextField value={item.quantity} label="Quantidade" type="number" size="small" fullWidth />
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
              <Divider sx={{ mt: 4 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom sx={{ pb: 3 }}>
                Peças/Materiais Revenda
              </Typography>
              <Stack rowGap={2}>
                {resaleMaterialsFields.map((item, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <InputLabel htmlFor="material">Material</InputLabel>
                      <TextField value={item.material_description} size="small" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Box>
                          <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                          <TextField name="quantity" value={item.quantity} type="number" size="small" />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="cost">Valor</InputLabel>
                          <NumericFormat
                            defaultValue={item.price}
                            fixedDecimalScale
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            allowNegative={false}
                            customInput={TextField}
                            InputProps={{ size: 'small', placeholder: 'Valor' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
              <Divider sx={{ mt: 4 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom sx={{ pb: 3 }}>
                Despesas
              </Typography>
              <Stack rowGap={2}>
                {expenseFields.map((item, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <InputLabel htmlFor="expense">Despesa</InputLabel>
                      <TextField value={item.expense} size="small" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Box>
                          <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                          <TextField value={item.quantity} type="number" size="small" />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="cost">Valor</InputLabel>
                          <NumericFormat
                            defaultValue={item.cost}
                            value={item.cost}
                            fixedDecimalScale
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            allowNegative={false}
                            customInput={TextField}
                            InputProps={{ size: 'small', placeholder: 'Valor' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
