import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const ProductsUrl = '/erp/products/';

export const ErpProductsService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ProductsUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getBySerialNumber: async (serialNumber: string) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ProductsUrl, {
        params: {
          serial_number: serialNumber
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  searchProduct: async (search: string) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ProductsUrl, {
        params: {
          search
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
