import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { User, allUserTypes } from '../../../models/User';
import { ErpClientsService } from '../../../services/ErpClients.service';
import { Client } from '../../../models/Client';
import { checkResponseStatus } from '../../../utils/api/response';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

interface Props {
  item: User;
  onClose: () => void;
}

export const UsersDetailsDialog = ({ item, onClose }: Props) => {
  const dispatch = useDispatch();
  const [clientDescription, setClientDescription] = useState<string>('N/A');

  const retrieveClient = async (clientId: string) => {
    try {
      const response = await ErpClientsService.retrieve(clientId);
      if (checkResponseStatus(response) && response?.data) {
        const client = response.data as Client;
        setClientDescription(`${client?.id} - ${client?.company_name}`);
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar clientes', severity: 'error' }));
    }
  };

  useEffect(() => {
    item?.client && retrieveClient(item?.client);
  }, [])
  
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Usuário
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Nome Usuário:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.username}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Email:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Nome:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.first_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Sobrenome:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.last_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Situação:
            </Typography>
            <>{item.is_active ? <Alert severity="success">Ativo</Alert> : <Alert severity="error">Inativo</Alert>}</>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Tipo do Usuário:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {allUserTypes.find((t) => t.id == item.type)?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Cliente:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {clientDescription}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
