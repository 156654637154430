import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Grid, InputLabel, Stack } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnimateButton } from '../../../components/@extended/AnimateButton';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FormMessage } from '../../../models/FormMessage';
import { ProductsService } from '../../../services/Products.service';

interface Props {
  onRegister: (serviceOrderNumber: string) => void;
}

interface Form {
  serviceOrderNumber: string;
}

export const ServiceOrderNumberComponent = ({ onRegister }: Props) => {
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      serviceOrderNumber: ''
    }
  });

  const onSubmit = async (data: Form) => {
    setFormMessage(null);
    setIsSubmitting(true);

    try {
      //o check serial number tem q ficar como A até finalizarmos o ENDPOINT.
      await ProductsService.checkSerialNumber(data.serviceOrderNumber);
      setFormMessage({
        severity: 'success',
        message: 'Número de série encontrado'
      });
      onRegister(data.serviceOrderNumber);
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: 'Número de série não encontrado'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {formMessage?.message && (
            <Grid item xs={12}>
              <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                {formMessage.message}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack spacing={2}>
              <InputLabel htmlFor="serviceOrderNumber">Informe o número de série do seu equipamento.</InputLabel>
              <ControlledTextInput
                placeholder="Número de Série"
                name="serviceOrderNumber"
                control={control}
                errorMessage={errors.serviceOrderNumber?.message}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <AnimateButton>
              <LoadingButton
                disableElevation
                disabled={isSubmitting}
                loading={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
              >
                Buscar
              </LoadingButton>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
