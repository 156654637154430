import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogComponent } from '../../../../../components/basics/DialogComponent';
import { ServiceOrdersService } from '../../../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { useServiceOrderFormContext } from '../../context/ServiceOrderContext';
import { EvidencesData, EvidencesDataHandles } from './components/EvidencesData';
import { EvidencesMaterialsExpenses, EvidencesMaterialsHandles } from './components/EvidencesMaterialsExpenses';

interface Props {
  onClose: () => void;
}

export const EvidencesTab = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const evidendesDataRef = useRef<EvidencesDataHandles | null>(null);
  const evidencesMaterialsExpensesRef = useRef<EvidencesMaterialsHandles | null>(null);

  const { serviceOrder, fetchServiceOrder } = useServiceOrderFormContext();

  const FINISHED_ORDER = serviceOrder.status == 'COMPLETED' || serviceOrder.status == 'REPROVED';
  const WAITING_EVIDENCES = serviceOrder.status == 'WAITING_EVIDENCES';

  const [concludeDialog, setConcludeDialog] = useState<boolean>(false);

  const handleSave = async (updateStatus: boolean) => {
    setConcludeDialog(false);

    const evidencesData = await evidendesDataRef?.current?.handleFormSubmit();
    const materialsExpensesData = await evidencesMaterialsExpensesRef?.current?.handleSubmit();

    if (!evidencesData || !materialsExpensesData) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Preencha todos os campos obrigatórios', severity: 'error' }));
      return;
    }

    let data = {};

    if (evidencesData && materialsExpensesData) {
      data = {
        ...evidencesData,
        ...materialsExpensesData
      };
      if (updateStatus) {
        data = {
          ...data,
          status: 'WAITING_CONCLUSION'
        };
      }
    } else {
      return;
    }

    try {
      dispatch(showSpinner());
      const response = await ServiceOrdersService.partialUpdate(data.id, data);
      if (checkResponseStatus(response)) {
        dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'Evidências salvas com sucesso!', severity: 'success' }));
        fetchServiceOrder();
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.message || 'Erro ao salvar as evidências, verifique os campos informados',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleConcludeDialog = async () => {
    setConcludeDialog(!concludeDialog);
  };

  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Conclusão */}
      <Typography variant="h3" gutterBottom>
        Evidências
      </Typography>

      {/* CARD de dados das evidencias */}
      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          {/* componente com as informações básicas das evidências */}
          <EvidencesData ref={evidendesDataRef} />

          {/* Componente com os materiais e despesas das evidencias */}
          <EvidencesMaterialsExpenses ref={evidencesMaterialsExpensesRef} />

          {!FINISHED_ORDER && (
            <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="outlined" color="primary" onClick={onClose}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleSave(false)}>
                Salvar
              </Button>
              <Button variant="contained" color="success" startIcon={<CheckIcon />} onClick={() => handleConcludeDialog()}>
                Enviar para Conclusão
              </Button>
            </Stack>
          )}
        </CardContent>
      </Card>

      {/* Diálogo de confirmação da ORDEM DE SERVIÇO */}
      <DialogComponent
        open={concludeDialog}
        title="Enviar para Conclusão"
        description="Deseja enviar a ordem de serviço para conclusão?"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={() => handleSave(true)}
        onCancel={handleConcludeDialog}
      />
    </Box>
  );
};
