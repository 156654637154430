import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Client } from '../../../models/Client';
import { ClientsService } from '../../../services/Clients.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ClientsFilterFields extends Client {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface ClientsResult extends ApiResults {
  results: Client[];
}

interface ClientsContextProps {
  users: ClientsResult | undefined;
  fetchClients: () => void;
  deleteClient: (id: number) => Promise<void>;
  deleteManyClients: (items: string[]) => Promise<void>;
  handleApproveClient: (item: Client) => void;
  filterFields: ClientsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ClientsFilterFields>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ClientsPageContext = createContext<ClientsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ClientsPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [users, setClients] = useState<ClientsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<ClientsFilterFields>({} as ClientsFilterFields);

  const fetchClients = async () => {
    dispatch(showSpinner());

    let filter = {
      ...filterFields,
      is_active: false
    };

    try {
      const response = await ClientsService.get(filter, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setClients(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteClient = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ClientsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchClients();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyClients = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ClientsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchClients();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleApproveClient = async (item: Client) => {
    dispatch(showSpinner());
    try {
      await ClientsService.update({
        ...item,
        is_active: true
      });
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Cliente ativado com sucesso!',
          severity: 'success'
        })
      );
      fetchClients();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchClients();
  }, [filterFields, page, rowsPerPage]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchClients();
      setShouldRefresh(false);
    }
  }, []);

  const contextValue: ClientsContextProps = {
    users,
    fetchClients,
    deleteClient,
    deleteManyClients,
    handleApproveClient,
    filterFields,
    setFilterFields,
    setShouldRefresh,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ClientsPageContext.Provider value={contextValue}>{children}</ClientsPageContext.Provider>;
};

export const useClientsPageContext = () => {
  const context = useContext(ClientsPageContext);
  if (!context) {
    throw new Error('useClientsPageContext must be used within a ClientsPageContextProvider');
  }
  return context;
};
