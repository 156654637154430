import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { Company } from '../models/Company';
import { DefectsFilterFields } from '../sections/@defects-page/context/DefectsPageContext';

const DefectsUrl = '/work-orders/defects/';

export const DefectsService = {
  getAll: async (isActive?: boolean) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(DefectsUrl, {
        params: {
          is_active: isActive,
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: DefectsFilterFields, page: number = 0, page_size: number = 1000) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(DefectsUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getGroups: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${DefectsUrl}groups/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: Company) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(DefectsUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: Company) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${DefectsUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${DefectsUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${DefectsUrl}batch-delete/`, {
        ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
