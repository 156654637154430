import { Box, Card, CardContent, Grid, InputLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { DropAttachmentComponent } from '../../../../../components/basics/DropAttachmentComponent';
import { Client } from '../../../../../models/Client';
import { ServiceOrder } from '../../../../../models/ServiceOrder';
import { User, getUserName } from '../../../../../models/User';

interface ClientOptions extends SelectOption {
  from_erp: boolean;
}

interface Props {
  serviceOrder: ServiceOrder;
}

export const RegisterTab = ({ serviceOrder }: Props) => {
  const [selectedClient, setSelectedClient] = useState<SelectOption | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const { control, reset } = useForm();

  const setFormDefaultValues = () => {
    let clientName = (serviceOrder?.client as Client)?.company_name
      ? (serviceOrder?.client as Client)?.company_name
      : `${(serviceOrder?.client as Client)?.first_name} ${(serviceOrder?.client as Client)?.last_name}`;

    setSelectedClient({
      id: serviceOrder?.client?.id,
      name: clientName
    });
    reset({
      serial_number: serviceOrder?.product?.serial_number,
      product_name: serviceOrder?.product?.description,
      description: serviceOrder?.description
    });
    setUploadedFiles(serviceOrder?.attachments);
  };

  useEffect(() => {
    if (serviceOrder?.id) {
      setFormDefaultValues();
    }
  }, [serviceOrder]);

  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Registro */}
      <Typography variant="h3" gutterBottom>
        Registro
      </Typography>

      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <InputLabel htmlFor="client">Cliente</InputLabel>
              <Typography variant="h6">{selectedClient?.name}</Typography>
            </Grid>
            <Grid item sm={4}>
              <InputLabel htmlFor="serial_number">Número de Série</InputLabel>
              <ControlledTextInput control={control} name="serial_number" placeholder="Número de Série" />
            </Grid>
            <Grid item sm={8}>
              <InputLabel htmlFor="product_name">Descrição do Produto</InputLabel>
              <ControlledTextInput control={control} name="product_name" placeholder="Descrição do Produto" disabled />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="description">Registro do Problema</InputLabel>
              <ControlledTextInput control={control} name="description" placeholder="Registro do Problema" minRows={4} disabled />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="attachments">Anexos (Fotos, Vídeos e Arquivos)</InputLabel>
              <DropAttachmentComponent uploadedFiles={uploadedFiles} disabled={!!serviceOrder.id} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="technical">Técnico</InputLabel>
              <Typography>{getUserName(serviceOrder?.technical as User)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="status">Tipo do Técnico</InputLabel>
              <Typography>{serviceOrder?.technical?.type}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
