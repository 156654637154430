export interface User {
  id?: number;
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  confirm_password?: string;
  is_active?: boolean;
  date_joined?: string;
  last_login?: string;
  language?: string;
  session_permissions?: Permission[];
  type: UserType | UserType[];
  client: string;
}

export type UserType = 'IPACOL' | 'ASSISTANCE' | 'CLIENT' | 'ADMIN';

export const allUserTypes = [
  { id: 'ADMIN', name: 'Administrador' },
  { id: 'IPACOL', name: 'Ipacol' },
  { id: 'ASSISTANCE', name: 'Assistência' },
  { id: 'CLIENT', name: 'Cliente' }
];

export interface Permission {
  id: number;
  module: string;
  permission: string;
  module_label: string;
  permission_label: string;
  submodule: string;
  submodule_label: string;
}

export const getUserName = (user: User) => {
  if (user?.first_name) {
    return `${user.first_name} ${user?.last_name}`.trim();
  } else if (user?.username) {
    return user.username;
  } else if (user?.email) {
    return user.email;
  } else {
    return '';
  }
};
