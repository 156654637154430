import { PaymentAuthorizationPage } from '../../../sections/@payment-authorization/PaymentAuthorizationPage';
import { PaymentAuthorizationPageContextProvider } from '../../../sections/@payment-authorization/context/PaymentAuthorizationPageContext';

export const PaymentAuthorizationPageWrapper = () => {
  return (
    <PaymentAuthorizationPageContextProvider>
      <PaymentAuthorizationPage />
    </PaymentAuthorizationPageContextProvider>
  );
};
