export const ROUTES = {
  public: {
    login: '/login',
    signUp: '/cadastro',
    accountConfirmation: '/confirmacao-de-conta',
    serviceOrder: '/registro-ordem-servico'
  },
  authenticated: {
    dashboard: {
      home: '/inicio',
      homeClient: '/ordens-servico'
    },
    user: {
      profile: '/perfil'
    },
    registers: {
      companies: '/empresas',
      users: '/usuarios',
      products: '/produtos',
      manuals: '/manuais'
    },
    serviceOrder: {
      serviceOrders: '/ordens-servico',
      defects: '/defeitos',
      defectsLocations: '/locais-defeitos',
      defectsGroups: '/grupos-defeitos',
      expenses: '/despesas'
    },
    auxiliary: {
      permissionGroups: '/grupos-permissoes',
      customerActivation: '/ativacao-cliente',
      parameters: '/parameters'
    },
    financial: {
      paymentAutorization: '/autorizar-pagamento',
      payServiceOrder: '/pagar-ordem-servico'
    }
  }
};
