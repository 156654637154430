import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Button, Card, CardContent, Grid, InputLabel, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { DialogComponent } from '../../../../../components/basics/DialogComponent';
import DropAttachmentThumbComponent from '../../../../../components/basics/DropAttachmentThumbComponent';
import { useUploadFile } from '../../../../../hooks/useUploadFileHook';
import { Client } from '../../../../../models/Client';
import { ServiceOrderStatus } from '../../../../../models/ServiceOrder';
import { ServiceOrdersService } from '../../../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { ConclusionSchema } from '../../../../../utils/forms/validations/formValidations';
import { formatDate, formatDateToApi, hexToRgba } from '../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../context/ServiceOrderContext';
import { ExpensesComparisonValuesTable } from './components/ExpensesComparisonValuesTable';
import { MaterialsComparisonValuesTable } from './components/MaterialsComparisonValuesTable';
import { ResaleMaterialsComparisonValuesTable } from './components/ResaleMaterialsComparisonValuesTable';
import { TotalValuesTable } from './components/TotalValuesTable';

export const ConclusionTab = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { uploadFiles } = useUploadFile();
  const { serviceOrder, serviceOrderPartialUpdate, fetchServiceOrder } = useServiceOrderFormContext();

  const FINISHED_ORDER = serviceOrder.status == 'COMPLETED' || serviceOrder.status == 'REPROVED';
  const WAITING_CONCLUSION = serviceOrder.status == 'WAITING_CONCLUSION';

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: {
      conclusion_date: serviceOrder.conclusion_date ? formatDate(serviceOrder.conclusion_date) : '',
      conclusion_description: serviceOrder.conclusion_description
    },
    resolver: yupResolver(ConclusionSchema)
  });

  const [concludeDialog, setConcludeDialog] = useState<boolean>(false);
  const [reOpenDialog, setReOpenDialog] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<any[]>(serviceOrder.conclusion_attachments || []);
  const [invoiceFiles, setInvoiceFiles] = useState<any[]>(serviceOrder.invoices_return_attachments || []);

  const uploadAttachments = async () => {
    let uploadedAttachments: any[] = [];
    let uploadedInvoiceFiles: any[] = [];

    if (uploadedFiles.length > 0) {
      try {
        uploadedAttachments = await uploadFiles(uploadedFiles);
      } catch (error) {
        dispatch(showSnackbarAlert({ message: 'Erro ao enviar anexos.', severity: 'error' }));
      }
    }

    if (invoiceFiles.length > 0) {
      try {
        uploadedInvoiceFiles = await uploadFiles(invoiceFiles);
      } catch (error) {
        dispatch(showSnackbarAlert({ message: 'Erro ao enviar anexos.', severity: 'error' }));
      }
    }

    return {
      uploadedAttachments,
      uploadedInvoiceFiles
    };
  };

  const updateServiceOrderStatus = async (status: ServiceOrderStatus) => {
    try {
      dispatch(showSpinner());
      const response = await serviceOrderPartialUpdate({ status });
      if (checkResponseStatus(response)) {
        await fetchServiceOrder();
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error.message || 'Erro ao atualizar o status da ordem de serviço', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const onSubmit = async (data: any) => {
    setConcludeDialog(false);

    dispatch(
      showSpinner({
        message: 'Concluindo ordem de serviço...'
      })
    );

    const { uploadedAttachments, uploadedInvoiceFiles } = await uploadAttachments();

    let submitData = {
      ...data,
      id: serviceOrder.id,
      client: (serviceOrder.client as Client).id,
      serial_number: serviceOrder.product.serial_number,
      conclusion_date: formatDateToApi(data.conclusion_date),
      conclusion_description: data.conclusion_description,
      conclusion_attachments: uploadedAttachments,
      invoices_return_attachments: uploadedInvoiceFiles,
      status: 'COMPLETED'
    };

    try {
      const response = await ServiceOrdersService.update(submitData);
      if (checkResponseStatus(response)) {
        dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'Ordem de serviço concluída com sucesso.', severity: 'success' }));
        await fetchServiceOrder();
      }
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao concluir ordem de serviço.', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleUploadFiles = (files: any) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDeleteFile = (file: any) => {
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleUploadInvoiceFiles = (files: any) => {
    setInvoiceFiles([...invoiceFiles, ...files]);
  };

  const handleDeleteInvoiceFile = (file: any) => {
    const updatedInvoiceFiles = invoiceFiles.filter((invoiceFile) => invoiceFile.name !== file.name);
    setInvoiceFiles(updatedInvoiceFiles);
  };

  const handleConcludeDialog = async () => {
    //validar os erros do formulário antes de exibir o modal
    const isValid = await trigger(['conclusion_date', 'conclusion_description']);
    if (!isValid) return;

    setConcludeDialog(!concludeDialog);
  };

  const handleReOpenDialog = () => {
    setReOpenDialog(!reOpenDialog);
  };

  const handleReOpenConfirm = async () => {
    handleReOpenDialog();
    await updateServiceOrderStatus('WAITING_TECHNICAL_ANALYSIS');
  };

  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Conclusão */}
      <Typography variant="h3" gutterBottom>
        Conclusão
      </Typography>

      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Dados do Produto
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nro de Série: {serviceOrder?.product?.serial_number}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Descrição: {serviceOrder?.product?.description}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Legenda
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: hexToRgba(theme.palette.error.main, 0.2)
                  }}
                />
                <Typography variant="body1" gutterBottom>
                  Valores reais acima dos valores estimados
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: hexToRgba(theme.palette.success.main, 0.2)
                  }}
                />
                <Typography variant="body1" gutterBottom>
                  Valores reais abaixo dos valores estimados
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              {serviceOrder.company_materials.length > 0 ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Materiais Ipacol
                  </Typography>
                  <MaterialsComparisonValuesTable materials={serviceOrder.company_materials} />
                </>
              ) : (
                <Typography variant="h5" gutterBottom>
                  Não há materiais Ipacol
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              {serviceOrder.resale_materials.length > 0 ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Materiais Revenda
                  </Typography>
                  <ResaleMaterialsComparisonValuesTable materials={serviceOrder.resale_materials} />
                </>
              ) : (
                <>
                  <Typography variant="h5" gutterBottom>
                    Não há materiais Revenda
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              {serviceOrder.expenses.length > 0 ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Despesas
                  </Typography>
                  <ExpensesComparisonValuesTable expenses={serviceOrder.expenses} />
                </>
              ) : (
                <>
                  <Typography variant="h5" gutterBottom>
                    Não há despesas
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Totais
              </Typography>
              <TotalValuesTable />
            </Grid>

            {!FINISHED_ORDER && (
              <>
                <Grid item xs={12}>
                  <InputLabel>Data de Conclusão</InputLabel>
                  <ControlledTextInput
                    name="conclusion_date"
                    placeholder="dd/mm/aaaa"
                    control={control}
                    fullWidth
                    mask="99/99/9999"
                    errorMessage={errors.conclusion_date?.message?.toString()}
                    required
                    disabled={FINISHED_ORDER || !WAITING_CONCLUSION}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Descreva a conclusão da ordem de serviço</InputLabel>
                  <ControlledTextInput
                    name="conclusion_description"
                    placeholder="Conclusão"
                    control={control}
                    fullWidth
                    minRows={4}
                    errorMessage={errors.conclusion_description?.message?.toString()}
                    required
                    disabled={FINISHED_ORDER || !WAITING_CONCLUSION}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Anexos (Fotos, Vídeos e Arquivos)
              </Typography>
              <DropAttachmentThumbComponent
                uploadedFiles={uploadedFiles}
                onUploadFile={handleUploadFiles}
                onDeleteFile={handleDeleteFile}
                disabled={FINISHED_ORDER || !WAITING_CONCLUSION}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Nota Fiscal de Devolução dos itens (se houver)
              </Typography>
              <DropAttachmentThumbComponent
                uploadedFiles={invoiceFiles}
                onUploadFile={handleUploadInvoiceFiles}
                onDeleteFile={handleDeleteInvoiceFile}
                disabled={FINISHED_ORDER || !WAITING_CONCLUSION}
              />
            </Grid>

            {FINISHED_ORDER && !serviceOrder.payment_authorized ? (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" startIcon={<ReplayIcon />} onClick={() => handleReOpenDialog()}>
                  Reabrir Ordem
                </Button>
              </Grid>
            ) : (
              WAITING_CONCLUSION && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" color="success" startIcon={<CheckIcon />} onClick={() => handleConcludeDialog()}>
                    Concluir
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* CONCLUSION DIALOG */}
      <DialogComponent
        open={concludeDialog}
        title="Concluir Ordem de Serviço"
        description="Deseja concluir a ordem de serviço?"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={handleSubmit(onSubmit)}
        onCancel={handleConcludeDialog}
      />

      {/* REOPEN DIALOG */}
      <DialogComponent
        open={reOpenDialog}
        title="Reabrir Ordem de Serviço"
        description="Deseja reabrir a ordem de serviço?"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={handleReOpenConfirm}
        onCancel={handleReOpenDialog}
      />
    </Box>
  );
};
