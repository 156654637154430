import { yupResolver } from '@hookform/resolvers/yup';
import BlockIcon from '@mui/icons-material/Block';
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, Grid, InputLabel, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../../../../components/basics/ControlledTextInput';
import { DialogComponent } from '../../../../../../components/basics/DialogComponent';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { ServiceOrderReprovedSchema } from '../../../../../../utils/forms/validations/formValidations';
import { useServiceOrderFormContext } from '../../../context/ServiceOrderContext';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface SubmitData {
  work_order: number;
  to_technical: number;
  description: string;
}

export const ReproveReasonDialog = ({ open, onClose }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(ServiceOrderReprovedSchema)
  });

  const dispatch = useDispatch();

  const { serviceOrderPartialUpdate, fetchServiceOrder } = useServiceOrderFormContext();

  const handleCancel = async (data: any) => {
    try {
      dispatch(showSpinner());

      const response = await serviceOrderPartialUpdate({ status: 'REPROVED' });
      if (checkResponseStatus(response)) {
        dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'A ordem de serviço foi cancelada', severity: 'success' }));
        onClose();
        await fetchServiceOrder();
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message, severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
      onClose();
    }
  };

  const handleNewBudget = async (data: any) => {
    try {
      dispatch(showSpinner());
      //fazer a atualização do status para aguardando materiais e despesas
      const response = await serviceOrderPartialUpdate({
        status: 'WAITING_MATERIALS_EXPENSES',
        reproved_description: data.reason
      });

      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'A ordem de serviço retornou para o status "Aguardando materiais e despesas"',
            severity: 'success'
          })
        );
        onClose();
        await fetchServiceOrder();
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message, severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
      onClose();
    }
  };

  return (
    <DialogComponent open={open} onClose={onClose} title="Reprovação" fullWidth showCloseButton>
      <Grid container>
        <Grid item xs={12}>
          <InputLabel htmlFor="reason" sx={{ pb: 1 }}>
            Motivo da reprovação
          </InputLabel>
          <ControlledTextInput
            control={control}
            name="reason"
            placeholder="Descreva o motivo da reprovação"
            minRows={4}
            errorMessage={errors.reason?.message?.toString()}
            required
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" startIcon={<BlockIcon />} sx={{ ml: 2 }} onClick={handleSubmit(handleCancel)}>
              Cancelar a Ordem
            </Button>
            <Button variant="contained" color="info" startIcon={<ReplayIcon />} onClick={handleSubmit(handleNewBudget)}>
              Solicitar Novo Orçamento
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};
