import { PermissionGroupsPage } from '../../../sections/@permission-groups-page/PermissionGroupsPage';
import { PermissionGroupsContextProvider } from '../../../sections/@permission-groups-page/context/PermissionGroupsContext';

export const PermissionGroupsPageWrapper = () => {
  return (
    <PermissionGroupsContextProvider>
      <PermissionGroupsPage />
    </PermissionGroupsContextProvider>
  );
};
