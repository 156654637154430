import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, Dialog, DialogTitle, Divider, Grid, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ServiceOrder, getSeverityByStatus, getStatusTab } from '../../../models/ServiceOrder';
import { ServiceOrdersService } from '../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { hexToRgba } from '../../../utils/utils';
import { ApproveTab } from './TabsContent/ApproveTab';
import { ConclusionTab } from './TabsContent/ConclusionTab';
import { EvidencesTab } from './TabsContent/EvidencesTab';
import { MaterialsExpensesTab } from './TabsContent/MaterialsExpensesTab';
import { RegisterTab } from './TabsContent/RegisterTab';
import { TechnicalAnalysisTab } from './TabsContent/TechnicalAnalysisTab';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { ASSISTANCE_TABS, serviceOrderTabs } from '../ServiceOrderFormDialog/context/ServiceOrderContext';

interface Props {
  item?: ServiceOrder;
  onClose: () => void;
}

export const ServiceOrderDetailsDialog = ({ item, onClose }: Props) => {
  const { user } = useAuthContext();
  const theme = useTheme();

  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [serviceOrder, setServiceOrder] = useState<ServiceOrder>({} as ServiceOrder);
  const [tabs, setTabs] = useState<any[]>([]);

  const IS_ASSISTANCE = user?.type === 'ASSISTANCE';

  const handleChangeTab = (newValue: number) => {
    if (!IS_ASSISTANCE || (IS_ASSISTANCE && ASSISTANCE_TABS.includes(newValue))){
      setCurrentTab(newValue);
    }
  };

  const fetchServiceOrder = async () => {
    try {
      dispatch(showSpinner());
      const response = await ServiceOrdersService.getById(Number(item?.id));
      if (response && checkResponseStatus(response)) {
        setServiceOrder(response.data);
        handleChangeTab(getStatusTab(response.data.status));
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message, severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const filterTabs = () => {
    let userTabs = serviceOrderTabs;
    if (IS_ASSISTANCE){
      userTabs = userTabs.filter((t, i) => ASSISTANCE_TABS.includes(i))
    }
    setTabs(userTabs);
  }

  useEffect(() => {
    if (item?.id) {
      fetchServiceOrder();
      filterTabs();
    }
  }, [item]);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '1rem' }}>
        <DialogTitle variant="h4" color="primary">
          Visualizar Ordem de Serviço
        </DialogTitle>
        <Button onClick={onClose} startIcon={<CloseIcon />} variant="text">
          Fechar
        </Button>
      </Stack>
      <Divider />

      <Grid container>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentTab}
            onChange={(event, newValue) => {
              handleChangeTab(newValue);
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                disabled={(index > 0 && !serviceOrder?.id) || tab.disabled}
                label={tab.label}
                style={{
                  textTransform: 'none',
                  backgroundColor: currentTab === index ? hexToRgba(theme.palette.primary.main, 0.1) : 'inherit'
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          {serviceOrder?.status && (
            <Box p={2} m={1} pb={0}>
              <Alert severity={getSeverityByStatus(serviceOrder?.status)} variant="standard">
                <Typography variant="body1">Situação: {String(serviceOrder?.status_label).toLocaleLowerCase()}</Typography>
              </Alert>
            </Box>
          )}
          {currentTab === 0 && <RegisterTab serviceOrder={serviceOrder} />}
          {currentTab === 1 && <TechnicalAnalysisTab serviceOrder={serviceOrder} />}
          {currentTab === 2 && <MaterialsExpensesTab serviceOrder={serviceOrder} />}
          {currentTab === 3 && <ApproveTab serviceOrder={serviceOrder} />}
          {currentTab === 4 && <EvidencesTab serviceOrder={serviceOrder} />}
          {currentTab === 5 && <ConclusionTab serviceOrder={serviceOrder} />}
          {/* {currentTab === 5 && <RefoundTab />} */}
        </Grid>
      </Grid>
    </Dialog>
  );
};
