import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Toolbar, Tooltip, Typography, alpha } from '@mui/material';
import { PermissionsObj } from '../../../hooks/useCrudPermissionsHook';

interface TableToolbarProps {
  numSelected: number;
  onDeleteSelected?: () => void;
  permissions: PermissionsObj;
}

export const TableToolbar = ({ numSelected, onDeleteSelected, permissions }: TableToolbarProps) => {
  return (
    <>
      {numSelected > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
            })
          }}
        >
          <>
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
              {numSelected} Selecionados
            </Typography>
            {permissions.hasDeletePermission && (
              <Tooltip title="Excluir os registros selecionados na tabela">
                <Button variant="contained" startIcon={<DeleteIcon />} onClick={onDeleteSelected}>
                  Excluir
                </Button>
              </Tooltip>
            )}
          </>
        </Toolbar>
      )}
    </>
  );
};
