// material-ui
import { useTheme } from '@mui/material/styles';

import { Box } from '@mui/system';
import Logo from '../../../../assets/images/media/logo.png';
import Logo512 from '../../../../assets/images/media/logo512x512.png';
import { DrawerHeaderStyled } from './styles';

// ==============================|| DRAWER HEADER ||============================== //
interface DrawerHeaderProps {
  open: boolean;
}

export const DrawerHeader = ({ open }: DrawerHeaderProps) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme}>
      {/* MINI LOGO */}
      {!open ? (
        <Box sx={{ display: 'flex', height: '71px' }}>
          <img src={Logo512} alt="logo" width="100%" color="red" />
        </Box>
      ) : (
        <Box sx={{ px: '2rem', py: '1rem', width: '260px' }}>
          <img src={Logo} alt="logo" width="100%" />
        </Box>
      )}
    </DrawerHeaderStyled>
  );
};
