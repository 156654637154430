import { Box, Divider, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { AutocompleteSearch } from '../../../../../../../components/basics/AutocompleteSearch';
import { Expense } from '../../../../../../../models/Expense';
import { Material, ResaleMaterial } from '../../../../../../../models/Material';
import { ServiceOrder } from '../../../../../../../models/ServiceOrder';
import { formatCurrency } from '../../../../../../../utils/utils';

interface MaterialObj extends Material {
  deleted: boolean;
}

interface ResaleMaterialObj extends ResaleMaterial {
  deleted: boolean;
}

interface ExpenseObj extends Expense {
  multiplier: number;
  deleted: boolean;
}

interface Props {
  serviceOrder: ServiceOrder;
}

export const EvidencesMaterialsExpenses = ({ serviceOrder }: Props) => {
  const [materialFields, setMaterialFields] = useState<Partial<MaterialObj>[]>([]);
  const [resaleMaterialsFields, setResaleMaterialsFields] = useState<Partial<ResaleMaterialObj>[]>([]);
  const [expenseFields, setExpenseFields] = useState<Partial<ExpenseObj>[]>([]);

  const FINISHED_ORDER = serviceOrder.status == 'REPROVED' || serviceOrder.status == 'COMPLETED';

  const loadDefaultValues = (serviceOrder: ServiceOrder) => {
    if (serviceOrder.company_materials.length > 0) {
      setMaterialFields(
        serviceOrder?.company_materials?.map((item: Material) => ({
          ...item,
          material: item.material,
          final_quantity: item.final_quantity ? item.final_quantity : item.quantity,
          description: item.material + ' - ' + item.description
        }))
      );
    }

    if (serviceOrder.resale_materials.length > 0) {
      setResaleMaterialsFields(
        serviceOrder?.resale_materials?.map((item: ResaleMaterial) => ({
          ...item,
          material_description: item.material_description,
          price: formatCurrency(item.price),
          final_price: formatCurrency(item.final_price ? item.final_price : item.price),
          final_quantity: item.final_quantity ? item.final_quantity : item.quantity
        }))
      );
    }

    if (serviceOrder.expenses.length > 0) {
      setExpenseFields(
        serviceOrder?.expenses?.map((item: Expense) => ({
          ...item,
          expense: item.expense,
          cost: formatCurrency(item.cost),
          final_cost: formatCurrency(item.final_cost ? item.final_cost : item.cost),
          final_quantity: item.final_quantity ? item.final_quantity : item.quantity,
          description: item.description?.toString() || '',
          deleted: false
        }))
      );
    }
  };

  useEffect(() => {
    loadDefaultValues(serviceOrder);
  }, [serviceOrder]);

  return (
    <Box sx={{ mt: 6 }}>
      {/* Conteúdo da aba Análise Técnica */}
      <Typography variant="h3" gutterBottom>
        Materiais e Despesas
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ pb: materialFields.length > 0 ? 3 : 0 }}>
            Peças/Materiais
          </Typography>
          <Stack rowGap={2}>
            {materialFields.length > 0 &&
              materialFields.map((item, index) => (
                <>
                  {
                    //se o material foi deletado, não exibe o campo
                    item.deleted ? (
                      <></>
                    ) : (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={10}>
                          <InputLabel htmlFor="material">Material</InputLabel>
                          <AutocompleteSearch value={item.material ? { id: item.material, name: item.description } : null} />
                        </Grid>
                        <Grid item xs={2}>
                          <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                            <Box>
                              <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                              <TextField value={item.final_quantity} type="number" size="small" fullWidth={FINISHED_ORDER} />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    )
                  }
                </>
              ))}
          </Stack>
          <Divider sx={{ mt: 4 }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ pb: resaleMaterialsFields.length > 0 ? 3 : 0 }}>
            Peças/Materiais Revenda
          </Typography>
          <Stack rowGap={2}>
            {resaleMaterialsFields.length > 0 &&
              resaleMaterialsFields.map((item, index) => (
                <>
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <InputLabel htmlFor="material">Material</InputLabel>
                      <TextField value={item.material_description} size="small" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Box>
                          <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                          <TextField name="quantity" value={item.final_quantity} type="number" size="small" fullWidth={FINISHED_ORDER} />
                        </Box>
                        <Box>
                          <InputLabel htmlFor="cost">Valor</InputLabel>
                          <NumericFormat
                            value={item.final_price}
                            fixedDecimalScale
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            allowNegative={false}
                            customInput={TextField}
                            InputProps={{ size: 'small', placeholder: 'Valor' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </>
              ))}
          </Stack>
          <Divider sx={{ mt: 4 }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ pb: expenseFields.length > 0 ? 3 : 0 }}>
            Despesas
          </Typography>
          <Stack rowGap={2}>
            {expenseFields.length > 0 &&
              expenseFields.map((item, index) => (
                <>
                  {item.deleted ? (
                    <></>
                  ) : (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={8}>
                        <InputLabel htmlFor="expense">Despesa</InputLabel>
                        <TextField value={item.expense} size="small" fullWidth />
                      </Grid>
                      <Grid item xs={4}>
                        <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                          <Box>
                            <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                            <TextField value={item.final_quantity} type="number" size="small" fullWidth={FINISHED_ORDER} />
                          </Box>
                          <Box>
                            <InputLabel htmlFor="cost">Valor</InputLabel>
                            <NumericFormat
                              value={item.final_cost}
                              fixedDecimalScale
                              decimalScale={2}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$ "
                              allowNegative={false}
                              customInput={TextField}
                              InputProps={{ size: 'small', placeholder: 'Valor' }}
                              fullWidth={FINISHED_ORDER}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
