import { Attachment } from './Attachment';

export interface TechnicalAnalysis {
  id: number;
  technical_supervisor: number;
  support_address: string;
  use_client_address: boolean;
  defects: DefectInfo[];
  work_order: number;
  description: string;
  attachments: Attachment[];
  service_type: string;
  date: string;
  created_at: string;
  updated_at: string;
}

export enum ServiceTypeEnum {
  WARRANTY = "Garantia",
  ASSISTANCE = "Assistência",
  TECHNICAL_DELIVERY = "Entrega Técnica",
}

interface Location {
  id: number;
  is_active: boolean;
  description: string;
}

interface Defect {
  id: number;
  is_active: boolean;
  description: string;
}

interface DefectGroupRelationship {
  id: number;
  defect: Defect;
  group: {
    id: number;
    is_active: boolean;
    description: string;
  };
}

interface DefectInfo {
  id: number;
  location: Location;
  defects_groups_relationships: DefectGroupRelationship[];
}
