import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

// material-ui
import { Box, ButtonBase, ClickAwayListener, Paper, Popper, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import MainCard from '../../../../components/@extended/MainCard';
import Transitions from '../../../../components/@extended/Transitions';

// assets
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import { ProfileTab } from './ProfileTab';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

export const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const { user, clearUser } = useAuthContext();

  const handleLogout = () => {
    clearUser();
  };

  const handleViewProfile = () => {
    setOpen(false);
    navigate('perfil');
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: { target: any }) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'secondary.lighter';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 1,
          borderRadius: 1
        }}
        color="text.primary"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="center">
          <Typography variant="h5" color="white">
            {user?.first_name} {user?.last_name}
          </Typography>
          <PersonOutlineOutlinedIcon fontSize="medium" sx={{ color: theme.palette.common.white }} />
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  //boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard border={false} content={false}>
                    <ProfileTab handleLogout={handleLogout} handleViewProfile={handleViewProfile} />
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};
