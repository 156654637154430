import { CompanyParametersPage } from '../../../sections/@company-parameters/CompanyParametersPage';
import { CompanyParametersPageContext } from '../../../sections/@company-parameters/context/CompanyParametersPageContext';

export const CompanyParametersPageWrapper = () => {
  return (
    <CompanyParametersPageContext>
      <CompanyParametersPage />
    </CompanyParametersPageContext>
  );
};
