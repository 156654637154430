import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissionsHook';
import { Company } from '../../../models/Company';
import { CompanyParameterFormDialog } from '../CompanyParameterFormDialog';
import { CompanyParametersTable } from '../CompanyParametersTable';
import { useCompanyParametersPageContext } from '../context/CompanyParametersPageContext';
import { ButtonsContainer } from './styles';

export const CompanyParametersPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'PARAMETER'
  });

  const [editItem, setEditItem] = useState<Company | undefined>(undefined);
  const [saved, setSaved] = useState(0);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields, page, rowsPerPage } = useCompanyParametersPageContext();

  const handleEditItem = (item: Company) => {
    setEditItem(item);
  };

  const updateSaved = () => {
    setSaved((prevSaved) => (!prevSaved ? 1 : 0));
  };

  const handleCloseDialogs = () => {
    setEditItem(undefined);
  };

  useEffect(() => {
    const debounced = debounce(() => {
      setFilterFields({
        ...filterFields,
        search: watchSearch
      });
    }, 500);
    debounced();
    return debounced.cancel;
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        <Box mr={1}>
          <ControlledTextInput
            control={control}
            name="search"
            label="Busca de Parametros"
            placeholder="Digite para Pesquisar"
            sx={{ mr: 1 }}
            type="search"
          />
        </Box>
      </ButtonsContainer>
      <CompanyParametersTable onEdit={handleEditItem} permissions={permissions} saved={saved} />
      {editItem && <CompanyParameterFormDialog editItem={editItem} onClose={handleCloseDialogs} updateSaved={updateSaved} />}
    </>
  );
};
