import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { CompanyParameters } from '../../../models/CompanyParameters';

export interface CompanyParametersFilterFields extends CompanyParameters {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface CompanyParametersResult extends ApiResults {
  results: CompanyParameters[];
}

interface CompanyParametersContextProps {
  filterFields: CompanyParametersFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<CompanyParametersFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const CompanyParametersContext = createContext<CompanyParametersContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const CompanyParametersPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [filterFields, setFilterFields] = React.useState<CompanyParametersFilterFields>({});

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  const contextValue: CompanyParametersContextProps = {
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <CompanyParametersContext.Provider value={contextValue}>{children}</CompanyParametersContext.Provider>;
};

export const useCompanyParametersPageContext = () => {
  const context = useContext(CompanyParametersContext);
  if (!context) {
    throw new Error('useCompanyParametersContext must be used within a CompaniesProvider');
  }
  return context;
};
