import { ExpensesPage } from '../../../sections/@expenses-page/ExpensesPage';
import { ExpensesPageContext } from '../../../sections/@expenses-page/context/ExpensesPageContext';

export const ExpensesPageWrapper = () => {
  return (
    <ExpensesPageContext>
      <ExpensesPage />
    </ExpensesPageContext>
  );
};
