import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropAttachmentComponent } from '../../../components/basics/DropAttachmentComponent';
import { useUploadFile } from '../../../hooks/useUploadFileHook';
import { FinancialRegistry } from '../../../models/FinancialRegistry';
import { FinancialRegistryService } from '../../../services/FinancialRegistry.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';

interface Props {
  item: FinancialRegistry;
  onClose: () => void;
  updateSaved: (value) => void;
}

export const PayServiceOrderAttachmentsDialog = ({ item, onClose, updateSaved }: Props) => {
  const dispatch = useDispatch();
  const { uploadFiles } = useUploadFile();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const handleUploadFiles = (files: any) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDeleteFile = (file: any) => {
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const uploadAttachments = async () => {
    let uploadedAttachments: any[] = [];

    if (uploadedFiles.length > 0) {
      try {
        uploadedAttachments = await uploadFiles(uploadedFiles);
      } catch (error) {
        dispatch(showSnackbarAlert({ message: 'Erro ao enviar anexos.', severity: 'error' }));
      }
    }
    return uploadedAttachments;
  };

  const addAttachments = async () => {
    dispatch(showSpinner());
    try {
      updateSaved(1);
      const attachments = await uploadAttachments();

      let submitData = {
        attachments: attachments
      };
      await FinancialRegistryService.partialUpdate(item?.id, submitData);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Anexos salvos com sucesso!',
          severity: 'success'
        })
      );
      onClose();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao salvar anexos',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    setUploadedFiles(item.attachments);
  }, []);

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Anexos do Pagamento da Ordem
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              <DropAttachmentComponent uploadedFiles={uploadedFiles} onUploadFile={handleUploadFiles} onDeleteFile={handleDeleteFile} />
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={addAttachments}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
