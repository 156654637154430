import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Divider, Grid, InputLabel, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { StyledTableHead } from '../../../components/basics/StyledTableHead';
import { FormMessage } from '../../../models/FormMessage';
import { FinancialRegistryService } from '../../../services/FinancialRegistry.service';
import { ServiceOrdersService } from '../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { formatCurrency, setInputErrorsFromApi } from '../../../utils/utils';

interface Props {
  onClose: () => void;
  updateSaved: (value) => void;
  idServiceOrder?: string;
}

export const PaymentAuthorizationFormDialog = ({ onClose, idServiceOrder, updateSaved }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [expenses, setExpenses] = useState([]);
  const [resaleMaterials, setResaleMaterials] = useState([]);
  const [selectedResaleMaterials, setSelectedResaleMaterials] = useState([]);
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [selectAllResaleMaterials, setSelectAllResaleMaterials] = useState(false);
  const [selectAllExpenses, setSelectAllExpenses] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver()
  });

  const handleSelectAllResaleMaterials = (event) => {
    setSelectAllResaleMaterials(event.target.checked);
    setSelectedResaleMaterials(event.target.checked ? resaleMaterials : []);
  };

  const handleSelectAllExpenses = (event) => {
    setSelectAllExpenses(event.target.checked);
    setSelectedExpenses(event.target.checked ? expenses : []);
  };

  const handleResaleMaterialCheckboxChange = (event, material) => {
    const selectedIndex = selectedResaleMaterials.indexOf(material);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedResaleMaterials, material);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedResaleMaterials.slice(1));
    } else if (selectedIndex === selectedResaleMaterials.length - 1) {
      newSelected = newSelected.concat(selectedResaleMaterials.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedResaleMaterials.slice(0, selectedIndex), selectedResaleMaterials.slice(selectedIndex + 1));
    }

    setSelectedResaleMaterials(newSelected);
  };

  const handleExpenseCheckboxChange = (event, expense) => {
    const selectedIndex = selectedExpenses.indexOf(expense);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedExpenses, expense);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedExpenses.slice(1));
    } else if (selectedIndex === selectedExpenses.length - 1) {
      newSelected = newSelected.concat(selectedExpenses.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedExpenses.slice(0, selectedIndex), selectedExpenses.slice(selectedIndex + 1));
    }

    setSelectedExpenses(newSelected);
  };

  const addFinancialRegistry: SubmitHandler<any> = async (data: any) => {
    dispatch(showSpinner());

    console.log(data);

    try {
      updateSaved(1);
      const payload = {
        ...data,
        expenses: selectedExpenses.map((expense) => expense.id),
        resale_materials: selectedResaleMaterials.map((material) => material.id),
        due_at: data.due_at,
        work_order: idServiceOrder,
        was_paid: false,
        attachments: []
      };

      await FinancialRegistryService.create(payload);

      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Pagamento autorizado com sucesso',
          severity: 'success'
        })
      );
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        setFormMessage({
          severity: 'error',
          message: formError
        });
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  const fetchServiceOrderInfo = async () => {
    dispatch(showSpinner());
    if (idServiceOrder) {
      try {
        const response = await ServiceOrdersService.getById(idServiceOrder);
        if (response?.data) {
          const result = response?.data;

          setExpenses(result.expenses);
          setResaleMaterials(result.resale_materials);
        }
      } catch (error: any) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: 'Erro ao buscar dados da Ordem de Serviço',
            severity: 'error'
          })
        );
      } finally {
        dispatch(hideSpinner());
        setLoading(false);
      }
    } else {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Nenhuma Ordem selecionada',
          severity: 'error'
        })
      );
      onClose();
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchServiceOrderInfo();
  }, []);

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Autorizar Pagamento
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(addFinancialRegistry)}>
        {!loading && (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel htmlFor="due_at">Data de Vencimento</InputLabel>
                <ControlledTextInput
                  name="due_at"
                  placeholder="dd/mm/aaaa"
                  control={control}
                  fullWidth
                  errorMessage={errors.due_at?.message?.toString()}
                  required
                  type="date"
                />
              </Grid>
              <Grid item xs={12}>
                {resaleMaterials.length > 0 ? (
                  <>
                    <Typography variant="h5" gutterBottom>
                      Materiais Revenda
                    </Typography>
                    <Grid>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                checked={selectAllResaleMaterials}
                                onChange={handleSelectAllResaleMaterials}
                                inputProps={{
                                  'aria-label': 'select all resale materials'
                                }}
                              />
                            </TableCell>
                            <TableCell>Código</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Quantidade Estimada</TableCell>
                            <TableCell>Quantidade Real</TableCell>
                            <TableCell>Valor Estimado</TableCell>
                            <TableCell>Valor Real</TableCell>
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {resaleMaterials.map((material) => (
                            <TableRow key={material.id}>
                              <TableCell>
                                <Checkbox
                                  color="primary"
                                  checked={selectedResaleMaterials.includes(material)}
                                  onChange={(event) => handleResaleMaterialCheckboxChange(event, material)}
                                  inputProps={{
                                    'aria-label': 'select all desserts'
                                  }}
                                />
                              </TableCell>
                              <TableCell>{material.material}</TableCell>
                              <TableCell>{material.description}</TableCell>
                              <TableCell>{material.quantity}</TableCell>
                              <TableCell>{material.final_quantity}</TableCell>
                              <TableCell>{formatCurrency(material.price ?? 0)}</TableCell>
                              <TableCell>{formatCurrency(material.final_price ?? 0)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </>
                ) : (
                  <Typography variant="h5" gutterBottom>
                    Não há materiais Revenda
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {expenses.length > 0 ? (
                  <>
                    <Typography variant="h5" gutterBottom>
                      Despesas
                    </Typography>
                    <Grid>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                checked={selectAllExpenses}
                                onChange={handleSelectAllExpenses}
                                inputProps={{
                                  'aria-label': 'select all expenses'
                                }}
                              />
                            </TableCell>
                            <TableCell>Código</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Quantidade Estimada</TableCell>
                            <TableCell>Quantidade Real</TableCell>
                            <TableCell>Valor Estimado</TableCell>
                            <TableCell>Valor Real</TableCell>
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {expenses.map((expense) => (
                            <TableRow key={expense.id}>
                              <TableCell>
                                <Checkbox
                                  color="primary"
                                  checked={selectedExpenses.includes(expense)}
                                  onChange={(event) => handleExpenseCheckboxChange(event, expense)}
                                  inputProps={{
                                    'aria-label': 'select all desserts'
                                  }}
                                />
                              </TableCell>
                              <TableCell>{expense.expense}</TableCell>
                              <TableCell>{expense.description}</TableCell>
                              <TableCell>{expense.quantity}</TableCell>
                              <TableCell>{expense.final_quantity}</TableCell>
                              <TableCell>{formatCurrency(expense.cost ?? 0)}</TableCell>
                              <TableCell>{formatCurrency(expense.final_cost ?? 0)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </>
                ) : (
                  <Typography variant="h5" gutterBottom>
                    Não há despesas
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        )}

        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(addFinancialRegistry)}>
            Autorizar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
