import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CheckIcon from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ServiceOrder, getStatusTab } from '../../../../models/ServiceOrder';
import { ServiceOrdersService } from '../../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../utils/api/response';
import { useAuthContext } from '../../../../context/AuthContextProvider';

export const serviceOrderTabs = [
  {
    label: 'Registro',
    icon: <AssignmentIcon />,
    disabled: false
  },
  {
    label: 'Análise Técnica',
    icon: <AssessmentIcon />,
    disabled: false
  },
  {
    label: 'Materiais e Despesas',
    icon: <SettingsIcon />,
    disabled: false
  },
  {
    label: 'Aprovação',
    icon: <CheckIcon />,
    disabled: false
  },
  {
    label: 'Evidências',
    icon: <CameraAltOutlinedIcon />,
    disabled: false
  },
  {
    label: 'Conclusão',
    icon: <AssignmentTurnedInIcon />,
    disabled: false
  }
  // { label: 'Ressarcimento Fornecedor', icon: <LocalAtmIcon /> }
];

interface ServiceOrderContextData {
  editItem?: ServiceOrder;
  tabs: any[];
  currentTab: number;
  handleChangeTab: (newValue: number) => void;
  serviceOrder: ServiceOrder;
  serviceOrderPartialUpdate: (data: Partial<ServiceOrder>) => Promise<any>;
  fetchServiceOrder: () => Promise<void>;
  handleSetServiceOrder: (serviceOrderData: ServiceOrder) => void;
}

export const ASSISTANCE_TABS = [0, 4];

const ServiceOrderContext = createContext<ServiceOrderContextData>({} as ServiceOrderContextData);

interface Props {
  editItem?: ServiceOrder;
  children: React.ReactNode;
}

export const ServiceOrderFormContext = ({ editItem, children }: Props) => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [tabs, setTabs] = useState<any[]>([]);
  const [serviceOrder, setServiceOrder] = useState<ServiceOrder>({} as ServiceOrder);

  const IS_ASSISTANCE = user?.type === 'ASSISTANCE';

  const handleChangeTab = (newValue: number) => {
    if (!IS_ASSISTANCE || (IS_ASSISTANCE && ASSISTANCE_TABS.includes(newValue))){
      setCurrentTab(newValue);
    }
  };

  const fetchServiceOrder = async () => {
    if (!editItem?.id && !serviceOrder.id) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Não foi possível carregar a ordem de serviço', severity: 'error' }));
    }

    try {
      dispatch(showSpinner());
      const response = await ServiceOrdersService.getById(Number(editItem?.id || serviceOrder.id));
      if (response && checkResponseStatus(response)) {
        setServiceOrder(response.data);
        handleChangeTab(getStatusTab(response.data.status));
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message, severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const serviceOrderPartialUpdate = async (data: Partial<ServiceOrder>) => {
    try {
      const response = await ServiceOrdersService.partialUpdate(editItem?.id || serviceOrder.id, data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const handleSetServiceOrder = (serviceOrderData: ServiceOrder) => {
    setServiceOrder({
      ...serviceOrder,
      ...serviceOrderData
    });
  };

  useEffect(() => {
    if (editItem?.id) {
      fetchServiceOrder();
    }
  }, [editItem]);

  const filterTabs = () => {
    let userTabs = serviceOrderTabs;
    if (IS_ASSISTANCE){
      userTabs = userTabs.filter((t, i) => ASSISTANCE_TABS.includes(i))
    }
    setTabs(userTabs);
  }

  useEffect(() => {
    filterTabs();
  }, [])

  return (
    <ServiceOrderContext.Provider
      value={{
        editItem,
        tabs,
        currentTab,
        handleChangeTab,
        fetchServiceOrder,
        serviceOrderPartialUpdate,
        serviceOrder,
        handleSetServiceOrder
      }}
    >
      {children}
    </ServiceOrderContext.Provider>
  );
};

export const useServiceOrderFormContext = () => {
  const context = useContext(ServiceOrderContext);
  if (!context) {
    throw new Error('useServiceOrderFormContext must be used within an ServiceOrderFormContext Provider');
  }
  return context;
};
