import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { AlertComponent } from '../../../components/basics/AlertComponent';
import { DialogComponent } from '../../../components/basics/DialogComponent';
import { StyledTableCell } from '../../../components/basics/StyledTableCell';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { TableToolbar } from '../../../components/basics/TableToolbarComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissionsHook';
import { Defect } from '../../../models/Defect';
import { useDefectsGroupsPageContext } from '../context/DefectsGroupsPageContext';

const headCells: readonly HeadCell[] = [
  {
    id: 'description',
    align: 'left',
    disablePadding: true,
    disableSorting: false,
    label: 'Descrição'
  },
  {
    id: 'is_active',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Situação'
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    disableSorting: true,
    label: 'Ações'
  }
];

interface Props {
  onView: (item: Defect) => void;
  onEdit: (item: Defect) => void;
  permissions: PermissionsObj;
}

export const DefectsGroupsTable = ({ onView, onEdit, permissions }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Defect>('description');
  const [selected, setSelected] = useState<string[]>([]);

  const [deleteItem, setDeleteItem] = useState<Defect | undefined>(undefined);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);

  const {
    defects,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage,
    deleteDefect,
    deleteManyDefectsGroups
  } = useDefectsGroupsPageContext();

  const handleDeleteItem = (item: Defect) => {
    setDeleteItem(item);
  };

  const handleDeleteSelectedItems = () => {
    setDeleteMany(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteItem?.id) {
      await deleteDefect(deleteItem.id);
    } else if (deleteMany) {
      await deleteManyDefectsGroups(selected);
    }
    setDeleteMany(false);
    setDeleteItem(undefined);
    setSelected([]);
  };

  const handleDeleteCancel = () => {
    setDeleteMany(false);
    setDeleteItem(undefined);
  };

  const handleRequestSort = (property: keyof Defect) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (selectedAll: boolean) => {
    if (selectedAll) {
      const newSelected = defects?.results.map((item: Defect) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    setSelected([]);
  }, [defects]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar numSelected={selected?.length} onDeleteSelected={handleDeleteSelectedItems} permissions={permissions} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={defects?.count || 0}
            />
            <TableBody>
              {defects && defects?.count > 0 ? (
                <>
                  {defects?.results?.map((row: Defect, index: number) => {
                    const isItemSelected = isSelected(row?.id!);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        onClick={(event) => handleClick(event, String(row.id))}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <StyledTableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" id={labelId} scope="row" padding="none" align="left">
                          {row.description}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.is_active ? (
                            <AlertComponent severity="success">Ativo</AlertComponent>
                          ) : (
                            <AlertComponent severity="error">Inativo</AlertComponent>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {permissions.hasReadPermission && (
                            <Tooltip title="Visualizar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onView(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasUpdatePermission && (
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onEdit(row);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasDeletePermission && (
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteItem(row as Defect);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={5}>
                    Nenhum registro encontrado
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {defects && defects?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={defects?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        )}
        {(deleteItem || deleteMany) && (
          <DialogComponent
            title={deleteItem ? 'Excluir Grupo' : 'Excluir Grupos'}
            description={
              deleteItem
                ? 'Tem certeza que deseja excluir este grupo de defeito?'
                : 'Tem certeza que deseja excluir estes grupos de defeitos?'
            }
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </Paper>
    </Box>
  );
};
