import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, Dialog, DialogTitle, Divider, Grid, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ServiceOrder, getSeverityByStatus } from '../../../models/ServiceOrder';
import { ServiceOrdersService } from '../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { hexToRgba } from '../../../utils/utils';
import { RegisterTab } from './TabsContent/RegisterTab';
import { TrackingTab } from './TabsContent/TrackingTab';

interface Props {
  item?: ServiceOrder;
  onClose: () => void;
}

export const ClientServiceOrderDetailsDialog = ({ item, onClose }: Props) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [serviceOrder, setServiceOrder] = useState<ServiceOrder>({} as ServiceOrder);

  const handleChangeTab = (newValue: number) => {
    setCurrentTab(newValue);
  };

  const fetchServiceOrder = async () => {
    try {
      dispatch(showSpinner());
      const response = await ServiceOrdersService.getById(Number(item?.id));
      if (response && checkResponseStatus(response)) {
        setServiceOrder(response.data);
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message, severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    if (item?.id) {
      fetchServiceOrder();
    }
  }, [item]);

  const tabs = [
    {
      label: 'Registro',
      icon: <AssignmentIcon />,
      disabled: false
    },
    {
      label: 'Acompanhamento',
      icon: <AssessmentIcon />,
      disabled: false
    }
  ];

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '1rem' }}>
        <DialogTitle variant="h4" color="primary">
          Visualizar Ordem de Serviço
        </DialogTitle>
        <Button onClick={onClose} startIcon={<CloseIcon />} variant="text">
          Fechar
        </Button>
      </Stack>
      <Divider />

      <Grid container>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentTab}
            onChange={(event, newValue) => {
              handleChangeTab(newValue);
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                disabled={(index > 0 && !serviceOrder?.id) || tab.disabled}
                label={tab.label}
                style={{
                  textTransform: 'none',
                  backgroundColor: currentTab === index ? hexToRgba(theme.palette.primary.main, 0.1) : 'inherit'
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          {serviceOrder?.status && (
            <Box p={2} m={1} pb={0}>
              <Alert severity={getSeverityByStatus(serviceOrder?.status)} variant="standard">
                <Typography variant="body1">Situação atual: {String(serviceOrder?.status_label).toLocaleLowerCase()}</Typography>
              </Alert>
            </Box>
          )}
          {currentTab === 0 && <RegisterTab serviceOrder={serviceOrder} />}
          {currentTab === 1 && <TrackingTab serviceOrder={serviceOrder} />}
        </Grid>
      </Grid>
    </Dialog>
  );
};
