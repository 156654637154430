import AttachFileIcon from '@mui/icons-material/AttachFile';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RestoreIcon from '@mui/icons-material/Restore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, TableHead, TableRow, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogComponent } from '../../../components/basics/DialogComponent';
import { StyledTableCell } from '../../../components/basics/StyledTableCell';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { StyledTableCellHeader } from '../../../components/basics/TableHeaderComponent/styles';
import { PermissionsObj } from '../../../hooks/useCrudPermissionsHook';
import { FinancialRegistry } from '../../../models/FinancialRegistry';
import { formatServiceOrderNumber } from '../../../models/ServiceOrder';
import { FinancialRegistryService } from '../../../services/FinancialRegistry.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { formatCurrency, formatDateToApi, formatSimpleDate } from '../../../utils/utils';
import { PayServiceOrderResult, usePayServiceOrderPageContext } from '../context/PayServiceOrderPageContext';

interface Props {
  onPreview: (item: FinancialRegistry) => void;
  onAttachments: (item: FinancialRegistry) => void;
  permissions: PermissionsObj;
  saved?: number;
}

export const PayServiceOrderTable = ({ onPreview, onAttachments, permissions, saved }: Props) => {
  const [selected, setSelected] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [payServiceOrder, setPayServiceOrder] = useState<FinancialRegistry | undefined>(undefined);
  const [refundServiceOrder, setRefundServiceOrder] = useState<FinancialRegistry | undefined>(undefined);
  const [shouldRefresh, setShouldRefresh] = useState(0);
  const [financialRegistries, setFinancialRegistries] = useState<PayServiceOrderResult[] | undefined>(undefined);

  const { filterFields, page, rowsPerPage, handlePageChange, handleOnChangeRowsPerPage } = usePayServiceOrderPageContext();

  const fetchFinancialRegistries = async () => {
    dispatch(showSpinner());

    try {
      const response = await FinancialRegistryService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        const registries = response?.data?.results.map((registry) => {
          let totalResaleMaterials = 0;
          let totalExpenses = 0;

          registry.resale_materials.map((material) => {
            totalResaleMaterials += material.final_price;
          });

          registry.expenses.map((expense) => {
            totalExpenses += expense.final_cost;
          });

          const total = totalResaleMaterials + totalExpenses;
          return { ...registry, total };
        });
        setFinancialRegistries(registries);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleRefundServiceOrder = (item: FinancialRegistry) => {
    setRefundServiceOrder(item);
  };

  const handleRefundServiceOrderCancel = () => {
    setRefundServiceOrder(undefined);
  };

  const handleRefundServiceOrderConfirm = async () => {
    if (refundServiceOrder?.id) {
      dispatch(showSpinner());
      try {
        await FinancialRegistryService.delete(refundServiceOrder.id);
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Pagamento estornado com sucesso',
            severity: 'success'
          })
        );
        setShouldRefresh(1);
      } catch (error: any) {
        dispatch(showSnackbarAlert({ title: 'Erro', message: 'Houve um erro ao realizar estorno', severity: 'error' }));
      } finally {
        dispatch(hideSpinner());
      }
    }
    setRefundServiceOrder(undefined);
  };

  const handlePayServiceOrder = (item: FinancialRegistry) => {
    setPayServiceOrder(item);
  };

  const handlePayServiceOrderCancel = () => {
    setPayServiceOrder(undefined);
  };

  const handlePayServiceOrderConfirm = async () => {
    if (payServiceOrder) {
      dispatch(showSpinner());
      try {
        const currentDate = formatDateToApi(new Date().toLocaleDateString());
        const payload = {
          was_paid: 1,
          paid_at: currentDate
        };
        await FinancialRegistryService.partialUpdate(payServiceOrder?.id, payload);

        setShouldRefresh(1);

        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Pagamento realizado com sucesso',
            severity: 'success'
          })
        );
      } catch (error: any) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: 'Erro ao pagar Ordem de Serviço',
            severity: 'error'
          })
        );
      } finally {
        dispatch(hideSpinner());
      }
    }
    setPayServiceOrder(undefined);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    fetchFinancialRegistries();
    if (shouldRefresh == 1) {
      setShouldRefresh(0);
    }
  }, [saved, shouldRefresh, filterFields]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <TableHead>
              <TableRow>
                <StyledTableCellHeader align={'left'} sortDirection={false}>
                  Ordem
                </StyledTableCellHeader>
                <StyledTableCellHeader align={'left'} sortDirection={false}>
                  N° Pagamento
                </StyledTableCellHeader>
                <StyledTableCellHeader align={'left'} sortDirection={false}>
                  Assistência
                </StyledTableCellHeader>
                <StyledTableCellHeader align={'left'} sortDirection={false}>
                  Data de Vencimento
                </StyledTableCellHeader>
                <StyledTableCellHeader align={'left'} sortDirection={false}>
                  Data de Pagamento
                </StyledTableCellHeader>
                <StyledTableCellHeader align={'left'} sortDirection={false}>
                  Valor
                </StyledTableCellHeader>
                <StyledTableCellHeader align={'right'} sortDirection={false}>
                  Ações
                </StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {financialRegistries && financialRegistries.length > 0 ? (
                <>
                  {financialRegistries?.map((row: FinancialRegistry, index: number) => {
                    const isItemSelected = isSelected(row?.id!);

                    return (
                      <StyledTableRow
                        hover
                        onClick={(event) => handleClick(event, String(row.id))}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <StyledTableCell scope="row" align="left">
                          {formatServiceOrderNumber(row.work_order) || '-'}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="left">
                          {row.number || '-'}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="left">
                          {row.technical?.first_name || '-'} {row.technical?.last_name || ''}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="left">
                          {row.due_at ? formatSimpleDate(row.due_at) : '-'}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="left">
                          {row.paid_at ? formatSimpleDate(row.paid_at) : '-'}
                        </StyledTableCell>
                        <StyledTableCell scope="row" align="left">
                          {row.total ? formatCurrency(row.total) : '-'}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {permissions.hasReadPermission && (
                            <Tooltip title="Preview">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onPreview(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasUpdatePermission && (
                            <>
                              <Tooltip title="Pagar">
                                <span>
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handlePayServiceOrder(row);
                                    }}
                                    disabled={row.was_paid}
                                  >
                                    <MonetizationOnIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip title="Estornar">
                                <span>
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleRefundServiceOrder(row);
                                    }}
                                    disabled={!row.was_paid}
                                  >
                                    <RestoreIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip title="Anexar">
                                <span>
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      onAttachments(row);
                                    }}
                                    disabled={!row.was_paid}
                                  >
                                    <AttachFileIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={6}>
                    Nenhum registro encontrado
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {financialRegistries && financialRegistries?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={financialRegistries?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        )}
        {payServiceOrder && (
          <DialogComponent
            title="Pagar Ordem de Serviço"
            description="Deseja pagar ordem de serviço?"
            onCancel={handlePayServiceOrderCancel}
            onConfirm={handlePayServiceOrderConfirm}
          />
        )}
        {refundServiceOrder && (
          <DialogComponent
            title="Estornar Ordem de Serviço"
            description="Deseja estornar ordem de serviço?"
            onCancel={handleRefundServiceOrderCancel}
            onConfirm={handleRefundServiceOrderConfirm}
          />
        )}
      </Paper>
    </Box>
  );
};
