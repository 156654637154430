import { AuxiliaryRoutes } from './auxiliary';
import { DashboardRoutes } from './dashboard';
import { FinancialRoutes } from './financial';
import { RegistersRoutes } from './registers';
import { ServiceOrderRoutes } from './service-order';

// ==============================|| MENU ITEMS ||============================== //

export const MenuItems = {
  items: [DashboardRoutes, ServiceOrderRoutes, RegistersRoutes, FinancialRoutes, AuxiliaryRoutes]
};
