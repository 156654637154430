import { ManualsPageContext } from '../../../sections/@manuals-page/context/ManualsPageContext';
import { ManualsPage } from '../../../sections/@manuals-page/ManualsPage';

export const ManualsPageWrapper = () => {
  return (
    <ManualsPageContext>
      <ManualsPage />
    </ManualsPageContext>
  );
};
