import { CompaniesPage } from '../../../sections/@companies-page/CompaniesPage';
import { CompaniesPageContext } from '../../../sections/@companies-page/context/CompaniesPageContext';

export const CompaniesPageWrapper = () => {
  return (
    <CompaniesPageContext>
      <CompaniesPage />
    </CompaniesPageContext>
  );
};
