import { DefectsGroupsPage } from '../../../sections/@defects-groups/DefectsGroupsPage';
import { DefectsGroupsPageContext } from '../../../sections/@defects-groups/context/DefectsGroupsPageContext';

export const DefectsGroupsPageWrapper = () => {
  return (
    <DefectsGroupsPageContext>
      <DefectsGroupsPage />
    </DefectsGroupsPageContext>
  );
};
