import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Divider, Grid, IconButton, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../../../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../../../../../components/basics/ControlledComboBox';
import { Client } from '../../../../../../../models/Client';
import { Expense } from '../../../../../../../models/Expense';
import { Material, ResaleMaterial } from '../../../../../../../models/Material';
import { ServiceOrder } from '../../../../../../../models/ServiceOrder';
import { ErpProductsService } from '../../../../../../../services/ErpProducts.service';
import { ExpensesService } from '../../../../../../../services/Expenses.service';
import { showSnackbarAlert } from '../../../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../../../utils/api/response';
import { formatCurrency, formatCurrencyToNumber } from '../../../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../../../context/ServiceOrderContext';

interface MaterialObj extends Material {
  deleted: boolean;
}

interface ResaleMaterialObj extends ResaleMaterial {
  deleted: boolean;
}

interface ExpenseObj extends Expense {
  multiplier: number;
  deleted: boolean;
}

export interface EvidencesMaterialsHandles {
  handleSubmit: () => any;
}

interface EvidenceMaterialsProps {
  ref: any;
}

export const EvidencesMaterialsExpenses = forwardRef<EvidencesMaterialsHandles, EvidenceMaterialsProps>((props, ref) => {
  const dispatch = useDispatch();
  const { serviceOrder, fetchServiceOrder, serviceOrderPartialUpdate } = useServiceOrderFormContext();

  const [materialsOptions, setMaterialsOptions] = useState<any[]>([]);
  const [materialFields, setMaterialFields] = useState<Partial<MaterialObj>[]>([]);

  const [resaleMaterialsFields, setResaleMaterialsFields] = useState<Partial<ResaleMaterialObj>[]>([]);

  const [expensesOptions, setExpensesOptions] = useState<any[]>([]);
  const [expenseFields, setExpenseFields] = useState<Partial<ExpenseObj>[]>([]);

  const FINISHED_ORDER = serviceOrder.status == 'REPROVED' || serviceOrder.status == 'COMPLETED';

  const quantityFieldRef = useRef(null);

  const formValidations = () => {
    if (materialFields.length > 0) {
      if (materialFields.filter((material) => !material.deleted).some((item) => !item.material || !item.final_quantity)) {
        dispatch(
          showSnackbarAlert({
            severity: 'error',
            title: 'Erro ao salvar',
            message: 'Preencha todos os campos de material'
          })
        );
        return false;
      }
    }

    if (resaleMaterialsFields.length > 0) {
      if (
        resaleMaterialsFields
          .filter((material) => !material.deleted)
          .some((item) => !item.material_description || !item.final_quantity || !item.final_price)
      ) {
        dispatch(
          showSnackbarAlert({
            severity: 'error',
            title: 'Erro ao salvar',
            message: 'Preencha todos os campos de material de revenda'
          })
        );
        return false;
      }
    }

    if (expenseFields.length > 0) {
      if (expenseFields.filter((expense) => !expense.deleted).some((item) => !item.expense || !item.final_quantity || !item.final_cost)) {
        dispatch(
          showSnackbarAlert({
            severity: 'error',
            title: 'Erro ao salvar',
            message: 'Preencha todos os campos de despesa'
          })
        );
        return false;
      }
    }

    return true;
  };

  const onSubmit = () => {
    if (!formValidations()) return false;

    let dataToSubmit = {
      id: serviceOrder.id,
      client: (serviceOrder?.client as Client).id as number,
      serial_number: serviceOrder.product.serial_number,
      company_materials: materialFields.map((item) => ({
        ...item,
        quantity: Number(item.quantity)
      })),
      resale_materials: resaleMaterialsFields.map((item) => ({
        ...item,
        price: formatCurrencyToNumber(item.price || '0'),
        final_price: formatCurrencyToNumber(item.final_price!)
      })),
      expenses: expenseFields.map((item) => ({
        ...item,
        cost: formatCurrencyToNumber(item.cost || '0'),
        final_cost: formatCurrencyToNumber(item.final_cost!)
      }))
    };

    return dataToSubmit;
  };

  const fetchMaterials = async (searchTerm: string) => {
    try {
      const response = await ErpProductsService.searchProduct(searchTerm);
      if (checkResponseStatus(response)) {
        setMaterialsOptions(
          response?.data?.results?.map((material: any) => ({ id: material.id, name: material.id + ' - ' + material.description }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          severity: 'error',
          message: error?.message || 'Erro ao buscar materiais'
        })
      );
    }
  };

  const fetchExpenses = async (searchTerm: string) => {
    try {
      const response = await ExpensesService.searchExpenses(searchTerm);
      if (checkResponseStatus(response)) {
        setExpensesOptions(
          response?.data?.results.map((expense: any) => ({
            id: expense.id,
            name: expense.description,
            multiplier: expense.multiplier || 1
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          severity: 'error',
          message: error?.message || 'Erro ao buscar despesas'
        })
      );
    }
  };

  const handleAddMaterial = () => {
    setMaterialsOptions([]);
    setMaterialFields([
      ...materialFields,
      {
        id: null,
        name: '',
        material: '',
        quantity: 0,
        price: '',
        final_quantity: 0,
        final_price: '',
        description: '',
        deleted: false
      }
    ]);
  };

  const handleDeleteMaterial = (index: number) => {
    //ao deletar um material, o valor final e a quantidade final devem ser zerados, além de marcar o campo como deletado
    let updatedMaterialFields = [...materialFields];
    updatedMaterialFields[index].final_price = '0';
    updatedMaterialFields[index].final_quantity = 0;
    updatedMaterialFields[index].deleted = true;
    setMaterialFields(updatedMaterialFields);
  };

  const handleAddResaleMaterial = () => {
    setMaterialsOptions([]);
    setResaleMaterialsFields([
      ...resaleMaterialsFields,
      {
        id: null,
        name: '',
        material: '',
        quantity: 0,
        price: '',
        final_quantity: 0,
        final_price: '',
        description: '',
        deleted: false
      }
    ]);
  };

  const handleDeleteResaleMaterial = (index: number) => {
    //ao deletar um material de revenda, o valor final e a quantidade final devem ser zerados, além de marcar o campo como deletado
    let updatedResaleMaterialsFields = [...resaleMaterialsFields];
    updatedResaleMaterialsFields[index].final_price = '0';
    updatedResaleMaterialsFields[index].final_quantity = 0;
    updatedResaleMaterialsFields[index].deleted = true;
    setResaleMaterialsFields(updatedResaleMaterialsFields);
  };

  const handleAddExpense = () => {
    setExpensesOptions([]);
    setExpenseFields([
      ...expenseFields,
      {
        id: null,
        description: '',
        expense: 0,
        quantity: 0,
        cost: '',
        final_quantity: 0,
        final_cost: '',
        multiplier: 1,
        deleted: false
      }
    ]);
  };

  const handleDeleteExpense = (index: number) => {
    //ao deletar uma despesa, o valor final e a quantidade final devem ser zerados, além de marcar o campo como deletado
    let updatedExpenseFields = [...expenseFields];
    updatedExpenseFields[index].final_cost = '0';
    updatedExpenseFields[index].final_quantity = 0;
    updatedExpenseFields[index].deleted = true;
    setExpenseFields(updatedExpenseFields);
  };

  const handleSelectMaterial = (value: SelectOption, index: number) => {
    let updatedMaterialFields = [...materialFields];
    updatedMaterialFields[index].material = value.id;
    updatedMaterialFields[index].description = value.name!;
    setMaterialFields(updatedMaterialFields);
  };

  const handleSelectResaleMaterial = (value: string, index: number) => {
    let updatedMaterialFields = [...resaleMaterialsFields];
    updatedMaterialFields[index].material_description = value;
    setResaleMaterialsFields(updatedMaterialFields);
  };

  const handleSelectExpense = (value: SelectOption, index: number) => {
    let updatedExpenseFields = [...expenseFields];
    updatedExpenseFields[index].expense = value.id;
    updatedExpenseFields[index].multiplier = value.multiplier || 1;
    updatedExpenseFields[index].description = value.name!;
    setExpenseFields(updatedExpenseFields);
  };

  const handleChangeMaterialQuantity = (event: any, index: number) => {
    const updatedMaterialFields = [...materialFields];
    updatedMaterialFields[index].final_quantity = event.target.value;
    setMaterialFields(updatedMaterialFields);
  };

  const handleChangeResaleMaterialQuantity = (event: any, index: number) => {
    const updatedMaterialFields = [...resaleMaterialsFields];
    updatedMaterialFields[index].final_quantity = event.target.value;
    setResaleMaterialsFields(updatedMaterialFields);
  };

  const handleChangeResaleMaterialValue = (event: any, index: number) => {
    const updatedMaterialFields = [...resaleMaterialsFields];
    updatedMaterialFields[index].final_price = event.target.value;
    setResaleMaterialsFields(updatedMaterialFields);
  };

  const handleChangeExpenseQuantity = (event: any, index: number) => {
    const updatedExpenseFields = [...expenseFields];
    updatedExpenseFields[index].final_quantity = event.target.value;
    updatedExpenseFields[index].final_cost = String(Number(updatedExpenseFields[index].multiplier) * Number(event.target.value));
    setExpenseFields(updatedExpenseFields);
  };

  const handleChangeExpenseValue = (event: any, index: number) => {
    const updatedExpenseFields = [...expenseFields];
    updatedExpenseFields[index].final_cost = event.target.value;
    setExpenseFields(updatedExpenseFields);
  };

  const checkReproveReason = () => {
    if (serviceOrder?.reproved_description) {
      dispatch(
        showSnackbarAlert({
          title: 'Aviso',
          message: `A ordem de serviço foi reprovada pelo motivo: ${serviceOrder.reproved_description}`,
          severity: 'warning'
        })
      );
    }
  };

  const loadDefaultValues = (serviceOrder: ServiceOrder) => {
    if (serviceOrder.company_materials.length > 0) {
      setMaterialFields(
        serviceOrder?.company_materials?.map((item: Material) => ({
          ...item,
          material: item.material,
          final_quantity: item.final_quantity ? item.final_quantity : item.quantity,
          description: item.material + ' - ' + item.description
        }))
      );
    }

    if (serviceOrder.resale_materials.length > 0) {
      setResaleMaterialsFields(
        serviceOrder?.resale_materials?.map((item: ResaleMaterial) => ({
          ...item,
          material_description: item.material_description,
          price: formatCurrency(item.price),
          final_price: formatCurrency(item.final_price ? item.final_price : item.price),
          final_quantity: item.final_quantity ? item.final_quantity : item.quantity
        }))
      );
    }

    if (serviceOrder.expenses.length > 0) {
      setExpenseFields(
        serviceOrder?.expenses?.map((item: Expense) => ({
          ...item,
          expense: item.expense,
          cost: formatCurrency(item.cost),
          final_cost: formatCurrency(item.final_cost ? item.final_cost : item.cost),
          final_quantity: item.final_quantity ? item.final_quantity : item.quantity,
          description: item.description?.toString() || '',
          multiplier: item.multiplier || 1,
          deleted: false
        }))
      );
    }

    if (serviceOrder.status == 'REPROVED') {
      checkReproveReason();
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: onSubmit
  }));

  useEffect(() => {
    loadDefaultValues(serviceOrder);
  }, [serviceOrder]);

  return (
    <Box sx={{ mt: 8 }}>
      {/* Conteúdo da aba Análise Técnica */}
      <Typography variant="h3" gutterBottom>
        Materiais e Despesas
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ pb: materialFields.length > 0 ? 3 : 0 }}>
            Peças/Materiais
          </Typography>
          <Stack rowGap={2}>
            {materialFields.length > 0 &&
              materialFields.map((item, index) => (
                <>
                  {
                    //se o material foi deletado, não exibe o campo
                    item.deleted ? (
                      <></>
                    ) : (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={10}>
                          <InputLabel htmlFor="material">Material</InputLabel>
                          <AutocompleteSearch
                            value={item.material ? { id: item.material, name: item.description } : null}
                            onSearch={fetchMaterials}
                            selectOptions={materialsOptions}
                            nextFieldRef={quantityFieldRef}
                            onSelect={(event, value) => handleSelectMaterial(value, index)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                            <Box>
                              <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                              <TextField
                                value={item.final_quantity}
                                type="number"
                                size="small"
                                fullWidth={FINISHED_ORDER}
                                inputRef={quantityFieldRef}
                                onChange={(event) => handleChangeMaterialQuantity(event, index)}
                              />
                            </Box>
                            {!FINISHED_ORDER && (
                              <IconButton onClick={() => handleDeleteMaterial(index)} color="primary">
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    )
                  }
                </>
              ))}
          </Stack>

          {!FINISHED_ORDER && (
            <Button variant="outlined" onClick={handleAddMaterial} startIcon={<AddIcon />} sx={{ mt: 4 }}>
              Adicionar Item
            </Button>
          )}
          <Divider sx={{ mt: 4 }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ pb: resaleMaterialsFields.length > 0 ? 3 : 0 }}>
            Peças/Materiais Revenda
          </Typography>
          <Stack rowGap={2}>
            {resaleMaterialsFields.length > 0 &&
              resaleMaterialsFields.map((item, index) => (
                <>
                  {item.deleted ? (
                    <></>
                  ) : (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={8}>
                        <InputLabel htmlFor="material">Material</InputLabel>
                        <TextField
                          value={item.material_description}
                          size="small"
                          fullWidth
                          onChange={(event) => handleSelectResaleMaterial(event.target.value, index)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                          <Box>
                            <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                            <TextField
                              name="quantity"
                              value={item.final_quantity}
                              type="number"
                              size="small"
                              fullWidth={FINISHED_ORDER}
                              inputRef={quantityFieldRef}
                              onChange={(event) => handleChangeResaleMaterialQuantity(event, index)}
                            />
                          </Box>
                          <Box>
                            <InputLabel htmlFor="cost">Valor</InputLabel>
                            <NumericFormat
                              value={item.final_price}
                              fixedDecimalScale
                              decimalScale={2}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$ "
                              allowNegative={false}
                              customInput={TextField}
                              InputProps={{ size: 'small', placeholder: 'Valor' }}
                              onChange={(event) => handleChangeResaleMaterialValue(event, index)}
                              fullWidth={FINISHED_ORDER}
                            />
                          </Box>
                          {!FINISHED_ORDER && (
                            <IconButton onClick={() => handleDeleteResaleMaterial(index)} color="primary">
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
          </Stack>

          {!FINISHED_ORDER && (
            <Button variant="outlined" onClick={handleAddResaleMaterial} startIcon={<AddIcon />} sx={{ mt: 4 }}>
              Adicionar Item
            </Button>
          )}
          <Divider sx={{ mt: 4 }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ pb: expenseFields.length > 0 ? 3 : 0 }}>
            Despesas
          </Typography>
          <Stack rowGap={2}>
            {expenseFields.length > 0 &&
              expenseFields.map((item, index) => (
                <>
                  {item.deleted ? (
                    <></>
                  ) : (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={8}>
                        <InputLabel htmlFor="expense">Despesa</InputLabel>
                        <AutocompleteSearch
                          value={item.expense ? { id: item.expense, name: item.description } : null}
                          onSearch={fetchExpenses}
                          selectOptions={expensesOptions}
                          nextFieldRef={quantityFieldRef}
                          onSelect={(event, value) => handleSelectExpense(value, index)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                          <Box>
                            <InputLabel htmlFor="quantity">Quantidade</InputLabel>
                            <TextField
                              value={item.final_quantity}
                              type="number"
                              size="small"
                              fullWidth={FINISHED_ORDER}
                              inputRef={quantityFieldRef}
                              onChange={(event) => handleChangeExpenseQuantity(event, index)}
                            />
                          </Box>
                          <Box>
                            <InputLabel htmlFor="cost">Valor</InputLabel>
                            <NumericFormat
                              value={item.final_cost}
                              fixedDecimalScale
                              decimalScale={2}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$ "
                              allowNegative={false}
                              customInput={TextField}
                              InputProps={{ size: 'small', placeholder: 'Valor' }}
                              onChange={(event) => handleChangeExpenseValue(event, index)}
                              fullWidth={FINISHED_ORDER}
                            />
                          </Box>
                          {!FINISHED_ORDER && (
                            <IconButton onClick={() => handleDeleteExpense(index)} color="primary">
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
          </Stack>

          {!FINISHED_ORDER && (
            <Button variant="outlined" onClick={handleAddExpense} startIcon={<AddIcon />} sx={{ mt: 4 }}>
              Adicionar Despesa
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});
