// project import
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import SimpleBar from '../../../../components/third-party/SimpleBar';
import Navigation from './Navigation';

// ==============================|| DRAWER CONTENT ||============================== //

export const DrawerContent = () => {
  const theme = useTheme();

  return (
    <Box sx={{ height: '100%', backgroundColor: theme.palette.common.white, marginTop: '1rem' }}>
      <SimpleBar
        sx={{
          scrollbarWidth: 'none',
          '& .simplebar-content': {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto'
          }
        }}
      >
        <Navigation />
      </SimpleBar>
    </Box>
  );
};
