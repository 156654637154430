import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const ClientsUrl = '/erp/clients/';

export const ErpClientsService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ClientsUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: any, page: number = 0, page_size: number = 1000) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ClientsUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  retrieve: async (id: string) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${ClientsUrl}${id}`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getBySerialNumber: async (serialNumber: string) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ClientsUrl, {
        params: {
          serial_number: serialNumber
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  searchClient: async (search: string) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ClientsUrl, {
        params: {
          search
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
