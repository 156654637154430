import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { SelectOption } from '../ControlledComboBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  label?: string;
  value: SelectOption | SelectOption[] | null;
  defaultValue?: SelectOption | SelectOption[] | null;
  selectOptions?: SelectOption[];
  onSearch?: (searchValue: string) => Promise<any>;
  onSelect?: (event: SyntheticEvent<Element, Event>, value: SelectOption) => void;
  errorMessage?: string;
  disabled?: boolean;
  multiple?: boolean;
  nextFieldRef?: React.RefObject<HTMLInputElement>;
}

export const AutocompleteSearch = ({
  label,
  value,
  defaultValue,
  selectOptions = [],
  onSearch,
  onSelect,
  errorMessage,
  disabled,
  multiple,
  nextFieldRef,

  ...props
}: Props) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string | undefined>(
    (defaultValue as SelectOption[])?.length > 0 ? (defaultValue as SelectOption[])?.map((item) => item.name) : value?.name || ''
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SelectOption[]>(selectOptions);
  const [currentHoveredValue, setCurrentHoveredValue] = useState<SelectOption | null | undefined>(null);

  const handleMouseEnterOption = (option: SelectOption) => {
    setCurrentHoveredValue(option);
  };

  const handleInputChange = async (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleSelectChange = (event: SyntheticEvent<Element, Event>, value: SelectOption | SelectOption[] | null) => {
    if (!value) return;
    onSelect && onSelect(event, value as SelectOption);
    if (nextFieldRef && nextFieldRef.current) {
      nextFieldRef.current.focus();
    }
    if (textFieldRef.current) {
      textFieldRef.current.blur();
    }
  };

  useEffect(() => {
    const debounce = setTimeout(async () => {
      if (inputValue) {
        setLoading(true);
        onSearch && (await onSearch(inputValue));
        setLoading(false);
      }
    }, 500);
    return () => clearTimeout(debounce);
  }, [inputValue]);

  useEffect(() => {
    setSearchResults(selectOptions);
    setCurrentHoveredValue(selectOptions.length > 0 ? selectOptions[0] : null);
  }, [selectOptions]);

  const handleKeyPressed = (event: React.KeyboardEvent, value: any, onChange: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      if (currentHoveredValue) {
        if (multiple) {
          if (Array.isArray(value) && !value.find((option) => option?.name == currentHoveredValue?.name)) {
            onChange([...((value ? value : []) as SelectOption[]), currentHoveredValue]);
          } else if (Array.isArray(value) && value.find((option) => option?.name == currentHoveredValue?.name)) {
            onChange(value.filter((option) => option?.name != currentHoveredValue?.name));
          }
        } else {
          onChange(event, currentHoveredValue);
        }
      }
    }
  };

  return (
    <Autocomplete
      {...props}
      autoHighlight
      size="small"
      disabled={disabled}
      options={searchResults}
      getOptionLabel={(option: SelectOption) => option.name || ''}
      filterOptions={(options, state) => options}
      multiple={multiple}
      noOptionsText="Nenhum resultado encontrado"
      loading={loading}
      loadingText="Carregando..."
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      defaultValue={defaultValue}
      value={value}
      onKeyDown={(event) => handleKeyPressed(event, value, handleSelectChange)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || option?.name === value?.name}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={`${option?.id}.${option?.name}`} onMouseEnter={() => handleMouseEnterOption(option)}>
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={multiple ? checkedIcon : null}
                style={{ marginRight: 8 }}
                checked={selected}
                size="small"
                key={`${option?.id}.${option?.name}`}
              />
            )}
            {option?.inputValue ? option?.inputValue : option?.name}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onMouseEnter={() => handleMouseEnterOption(value as SelectOption)}
            label={label}
            variant="outlined"
            placeholder="Digite para pesquisar"
            error={!!errorMessage}
            helperText={errorMessage}
          />
        );
      }}
    />
  );
};
