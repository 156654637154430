import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useCrudPermissions } from '../../../hooks/useCrudPermissionsHook';
import { FinancialRegistry } from '../../../models/FinancialRegistry';
import { PayServiceOrderFilterDialog } from '../PayServiceFilterDialog';
import { PayServiceOrderAttachmentsDialog } from '../PayServiceOrderAttachmentsDialog';
import { PayServiceOrderPreviewDialog } from '../PayServiceOrderDialog';
import { PayServiceOrderTable } from '../PayServiceOrderTable';
import { usePayServiceOrderPageContext } from '../context/PayServiceOrderPageContext';
import { ButtonsContainer } from './styles';

export const PayServiceOrderPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'FINANCIAL_REGISTRY'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [viewItem, setViewItem] = useState<FinancialRegistry | undefined>(undefined);
  const [attachmentItem, setAttachmentItem] = useState<FinancialRegistry | undefined>(undefined);
  const [saved, setSaved] = useState(0);

  const { fetchFinancialRegistries } = usePayServiceOrderPageContext();

  const updateSaved = (value) => {
    setSaved(value);
  };

  const handlePreviewItem = (item: FinancialRegistry) => {
    setViewItem(item);
  };

  const handleAttachmentsItem = (item: FinancialRegistry) => {
    setAttachmentItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setViewItem(undefined);
    setAttachmentItem(undefined);
    fetchFinancialRegistries();
    updateSaved(0);
  };

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <Tooltip title="Filtros">
            <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
              Filtros
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <PayServiceOrderTable onPreview={handlePreviewItem} onAttachments={handleAttachmentsItem} permissions={permissions} saved={saved} />
      {viewItem && <PayServiceOrderPreviewDialog item={viewItem} onClose={handleCloseDialogs} />}
      {attachmentItem && <PayServiceOrderAttachmentsDialog item={attachmentItem} onClose={handleCloseDialogs} updateSaved={updateSaved} />}
      {showFilter && permissions.hasReadPermission && <PayServiceOrderFilterDialog onClose={handleCloseDialogs} />}
    </>
  );
};
