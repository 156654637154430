import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const ServiceOrderRoutes: INavGroup = {
  id: 'group-service-order',
  title: 'Ordem de Serviço',
  icon: <MiscellaneousServicesIcon />,
  type: 'group',
  module: 'WORK_ORDER',
  level: 1,
  children: [
    {
      id: 'service-orders',
      title: 'Ordens de Serviço',
      type: 'item',
      url: ROUTES.authenticated.serviceOrder.serviceOrders,
      icon: <EngineeringOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'WORK_ORDER'
    },
    {
      id: 'defects',
      title: 'Defeitos',
      type: 'item',
      url: ROUTES.authenticated.serviceOrder.defects,
      icon: <ReportProblemOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'DEFECT'
    },
    {
      id: 'defects-groups',
      title: 'Grupos de Defeitos',
      type: 'item',
      url: ROUTES.authenticated.serviceOrder.defectsGroups,
      icon: <HubOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'DEFECT_GROUP'
    },
    {
      id: 'defects-locations',
      title: 'Locais de Defeitos',
      type: 'item',
      url: ROUTES.authenticated.serviceOrder.defectsLocations,
      icon: <FmdBadOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'DEFECT_LOCATION'
    },
    {
      id: 'expenses',
      title: 'Despesas',
      type: 'item',
      url: ROUTES.authenticated.serviceOrder.expenses,
      icon: <RequestPageOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'EXPENSE'
    }
  ]
};
