import DownloadIcon from '@mui/icons-material/Download';
import { Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { DialogComponent } from '../../../../../../components/basics/DialogComponent';
import { useDownloadFileHook } from '../../../../../../hooks/useDownloadFileHook';
import { TechnicalAnalysis } from '../../../../../../models/TechnicalAnalysis';
import { formatDate } from '../../../../../../utils/utils';

interface Props {
  analysisDetails?: TechnicalAnalysis;
  onCancel: () => void;
}

export const DetailsModal = ({ analysisDetails, onCancel }: Props) => {
  const dispatch = useDispatch();
  const { downloadFile } = useDownloadFileHook();

  if (!analysisDetails) return <></>;

  return (
    <DialogComponent open={!!analysisDetails} onCancel={onCancel} cancelButtonText="Fechar" title="Detalhes da Análise Técnica" fullWidth>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5">Descrição:</Typography>
          <Typography variant="body1" gutterBottom>
            {analysisDetails?.description}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Endereço de Atendimento:</Typography>
          <Typography variant="body1" gutterBottom>
            {analysisDetails?.use_client_address ? 'Endereço do cliente' : analysisDetails?.support_address}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Defeitos:</Typography>
          {analysisDetails?.defects?.length > 0 ? (
            <>
              {analysisDetails.defects.map((defect: any, index: number) => (
                <div key={index}>
                  <Typography variant="subtitle1">{defect.location.description}</Typography>
                  <ul>
                    {defect.defects_groups_relationships.map((relationship: any, subIndex: number) => (
                      <li key={subIndex}>
                        {relationship.group.description}: {relationship.defect.description}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <Divider />
            </>
          ) : (
            <Typography variant="body2" gutterBottom>
              Nenhum defeito encontrado.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Data da Análise:</Typography>
          <Typography variant="body1" gutterBottom>
            {formatDate(analysisDetails?.date)}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Anexos (Fotos, Vídeos e Arquivos):</Typography>
          {analysisDetails?.attachments?.length > 0 ? (
            <List>
              {analysisDetails.attachments.map((file: any, index: number) => (
                <ListItem key={index} divider>
                  <ListItemText primary={file.name} />
                  <ListItemSecondaryAction>
                    {file?.file && (
                      <IconButton onClick={() => downloadFile(file)}>
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" gutterBottom>
              Nenhum anexo encontrado.
            </Typography>
          )}
        </Grid>
      </Grid>
    </DialogComponent>
  );
};
