import { DefectsLocationsPage } from '../../../sections/@defects-locations-page/DefectsLocationsPage';
import { DefectsLocationsPageContext } from '../../../sections/@defects-locations-page/context/DefectsPlacesPageContext';

export const DefectsLocationsPageWrapper = () => {
  return (
    <DefectsLocationsPageContext>
      <DefectsLocationsPage />
    </DefectsLocationsPageContext>
  );
};
