import ConstructionIcon from '@mui/icons-material/Construction';
import { Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import AuthBackground1 from '../../../assets/images/media/logo.png';
import { ServiceOrderForm } from '../../../sections/@public-service-order-register/ServiceOrderForm';
import { ServiceOrderWrapper } from '../../../sections/@public-service-order-register/ServiceOrderWrappers/ServiceOrderWrapper';
import { ServiceOrderNumberComponent } from '../../../sections/@public-service-order-register/ServicerOrderNumberComponent';
import { ContainerRow } from '../../../themes/theme/GlobalStyledComponents';

export const ServiceOrderPage = () => {
  const [serviceOrderNumber, setServiceOrderNumber] = useState<string>('');

  const handleServiceOrderVerification = (serviceOrder: string) => {
    setServiceOrderNumber(serviceOrder);
  };

  return (
    <ServiceOrderWrapper>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src={AuthBackground1}
            alt="AuthBackground"
            style={{
              width: '200px'
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <ContainerRow style={{ alignItems: 'center' }}>
              <ConstructionIcon />
              <Typography variant="h5" ml={2} mt={2}>
                Precisa de Ajuda? <br /> Registre-se e abra sua ordem de serviço!
              </Typography>
            </ContainerRow>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {serviceOrderNumber ? (
            <ServiceOrderForm serviceOrderNumber={serviceOrderNumber} onBack={() => setServiceOrderNumber('')} />
          ) : (
            <ServiceOrderNumberComponent onRegister={handleServiceOrderVerification} />
          )}
        </Grid>
      </Grid>
    </ServiceOrderWrapper>
  );
};
