import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Expense } from '../../../models/Expense';
import { ExpensesService } from '../../../services/Expenses.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ExpensesFilterFields extends Expense {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface ExpensesResult extends ApiResults {
  results: Expense[];
}

interface ExpensesContextProps {
  expenses: ExpensesResult | undefined;
  fetchExpenses: () => void;
  deleteExpense: (id: number) => Promise<void>;
  deleteManyExpenses: (items: string[]) => Promise<void>;
  filterFields: ExpensesFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ExpensesFilterFields>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ExpensesContext = createContext<ExpensesContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ExpensesPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [expenses, setExpenses] = React.useState<ExpensesResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<ExpensesFilterFields>({} as ExpensesFilterFields);

  const fetchExpenses = async () => {
    dispatch(showSpinner());
    try {
      const response = await ExpensesService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setExpenses(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao buscar os registros de despesas',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteExpense = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ExpensesService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchExpenses();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyExpenses = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ExpensesService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchExpenses();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchExpenses();
  }, [filterFields, page, rowsPerPage]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchExpenses();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const contextValue: ExpensesContextProps = {
    expenses,
    fetchExpenses,
    deleteExpense,
    deleteManyExpenses,
    filterFields,
    setFilterFields,
    setShouldRefresh,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ExpensesContext.Provider value={contextValue}>{children}</ExpensesContext.Provider>;
};

export const useExpensesPageContext = () => {
  const context = useContext(ExpensesContext);
  if (!context) {
    throw new Error('useExpensesContext must be used within a ExpensesProvider');
  }
  return context;
};
