import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import MainCard from '../../../components/@extended/MainCard';

interface ServiceOrderCardProps {
  children: ReactNode;
}

export const ServiceOrderCard: React.FC<ServiceOrderCardProps> = ({ children, ...other }) => (
  <MainCard
    sx={{
      maxWidth: { xs: 400, lg: 475 },
      margin: { xs: 2, md: 2.5 },
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
      }
    }}
    content={false}
    {...other}
    border={false}
    boxShadow
  >
    <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>{children}</Box>
  </MainCard>
);
