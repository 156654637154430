import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { SERVICE_ORDER_STATUS_OPTIONS } from '../../../models/ServiceOrder';
import { findSelectedOption } from '../../../utils/utils';
import { ServiceOrdersFilterFields, useServiceOrdersPageContext } from '../context/ServiceOrdersPageContext';

interface Props {
  onClose: () => void;
}

const PAYMENT_AUTHORIZED_OPTIONS: SelectOption[] = [
  { id: 'true', name: 'Autorizado' },
  { id: 'false', name: 'Não Autorizado' }
];

const ASSISTANCE_OPTIONS: SelectOption[] = [
  { id: 'true', name: 'Sim' },
  { id: 'false', name: 'Não' }
];

const getDefaultValues = (filter?: ServiceOrdersFilterFields): ServiceOrdersFilterFields => {
  let selectedStatus = findSelectedOption(SERVICE_ORDER_STATUS_OPTIONS, filter?.status);
  let selectedPaymentAuthorized = findSelectedOption(PAYMENT_AUTHORIZED_OPTIONS, filter?.payment_authorized);
  let selectedIsAssistance = findSelectedOption(ASSISTANCE_OPTIONS, filter?.is_assistance);

  return {
    status: selectedStatus || null,
    payment_authorized: selectedPaymentAuthorized || null,
    created_after: filter?.created_after || '',
    created_before: filter?.created_before || '',
    is_assistance: selectedIsAssistance || null
  };
};

export const ServiceOrdersFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useServiceOrdersPageContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Partial<ServiceOrdersFilterFields>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit = async (data: Partial<ServiceOrdersFilterFields>) => {
    if ((data.status as SelectOption)?.id == null) {
      delete data.status;
    } else {
      data.status = (data.status as SelectOption)?.id;
    }

    if ((data.payment_authorized as SelectOption)?.id == null) {
      delete data.payment_authorized;
    } else {
      data.payment_authorized = (data.payment_authorized as SelectOption)?.id;
    }

    if ((data.is_assistance as SelectOption)?.id == null) {
      delete data.is_assistance;
    } else {
      data.is_assistance = (data.is_assistance as SelectOption)?.id;
    }

    setFilterFields({ ...data } as ServiceOrdersFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ServiceOrdersFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="status">Status da Ordem</InputLabel>
            <ControlledComboBox
              name="status"
              placeholder="Status"
              control={control}
              errorMessage={errors.status?.message?.toString()}
              selectOptions={SERVICE_ORDER_STATUS_OPTIONS.slice(1)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="payment_authorized">Pagamento Autorizado</InputLabel>
            <ControlledComboBox
              name="payment_authorized"
              placeholder="Pagamento Autorizado"
              control={control}
              errorMessage={errors.payment_authorized?.message?.toString()}
              selectOptions={PAYMENT_AUTHORIZED_OPTIONS}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="created_after">Criado Após</InputLabel>
            <ControlledTextInput
              name="created_after"
              placeholder="Criado Após"
              control={control}
              errorMessage={errors.created_after?.message?.toString()}
              type="date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="created_before">Criado Antes</InputLabel>
            <ControlledTextInput
              name="created_before"
              placeholder="Criado Antes"
              control={control}
              errorMessage={errors.created_before?.message?.toString()}
              type="date"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="is_assistance">Responsável é assistência técnica?</InputLabel>
            <ControlledComboBox
              name="is_assistance"
              placeholder="Sim ou Não"
              control={control}
              errorMessage={errors.is_assistance?.message?.toString()}
              selectOptions={ASSISTANCE_OPTIONS}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
          Limpar
        </Button>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
