import { Box, Card, CardContent, Grid, InputLabel, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { DropAttachmentComponent } from '../../../../../components/basics/DropAttachmentComponent';
import { ServiceOrder } from '../../../../../models/ServiceOrder';
import { formatDate, hexToRgba } from '../../../../../utils/utils';
import { ExpensesComparisonValuesTable } from './components/ExpensesComparisonValuesTable';
import { MaterialsComparisonValuesTable } from './components/MaterialsComparisonValuesTable';
import { ResaleMaterialsComparisonValuesTable } from './components/ResaleMaterialsComparisonValuesTable';
import { TotalValuesTable } from './components/TotalValuesTable';

interface Props {
  serviceOrder: ServiceOrder;
}

export const ConclusionTab = ({ serviceOrder }: Props) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: {
      conclusion_date: serviceOrder.conclusion_date ? formatDate(serviceOrder.conclusion_date) : '',
      conclusion_description: serviceOrder.conclusion_description
    }
  });

  const [uploadedFiles, setUploadedFiles] = useState<any[]>(serviceOrder.conclusion_attachments || []);
  const [invoiceFiles, setInvoiceFiles] = useState<any[]>(serviceOrder.invoices_return_attachments || []);

  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Conclusão */}
      <Typography variant="h3" gutterBottom>
        Conclusão
      </Typography>

      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Dados do Produto
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nro de Série: {serviceOrder?.product?.serial_number}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Descrição: {serviceOrder?.product?.description}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Legenda
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: hexToRgba(theme.palette.error.main, 0.2)
                  }}
                />
                <Typography variant="body1" gutterBottom>
                  Valores reais acima dos valores estimados
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: hexToRgba(theme.palette.success.main, 0.2)
                  }}
                />
                <Typography variant="body1" gutterBottom>
                  Valores reais abaixo dos valores estimados
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              {serviceOrder.company_materials.length > 0 ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Materiais Ipacol
                  </Typography>
                  <MaterialsComparisonValuesTable materials={serviceOrder.company_materials} />
                </>
              ) : (
                <Typography variant="h5" gutterBottom>
                  Não há materiais Ipacol
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              {serviceOrder.resale_materials.length > 0 ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Materiais Revenda
                  </Typography>
                  <ResaleMaterialsComparisonValuesTable materials={serviceOrder.resale_materials} />
                </>
              ) : (
                <>
                  <Typography variant="h5" gutterBottom>
                    Não há materiais Revenda
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              {serviceOrder.expenses.length > 0 ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    Despesas
                  </Typography>
                  <ExpensesComparisonValuesTable expenses={serviceOrder.expenses} />
                </>
              ) : (
                <>
                  <Typography variant="h5" gutterBottom>
                    Não há despesas
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Totais
              </Typography>
              <TotalValuesTable serviceOrder={serviceOrder} />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Data de Conclusão</InputLabel>
              <ControlledTextInput
                name="conclusion_date"
                placeholder="dd/mm/aaaa"
                control={control}
                fullWidth
                mask="99/99/9999"
                errorMessage={errors.conclusion_date?.message?.toString()}
                required
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Descreva a conclusão da ordem de serviço</InputLabel>
              <ControlledTextInput
                name="conclusion_description"
                placeholder="Conclusão"
                control={control}
                fullWidth
                minRows={4}
                errorMessage={errors.conclusion_description?.message?.toString()}
                required
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Anexos (Fotos, Vídeos e Arquivos)
              </Typography>
              <DropAttachmentComponent uploadedFiles={uploadedFiles} disabled />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Nota Fiscal de Devolução dos itens (se houver)
              </Typography>
              <DropAttachmentComponent uploadedFiles={invoiceFiles} disabled />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
