import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useAuthContext } from '../context/AuthContextProvider';
import { Attachment } from '../models/Attachment';
import { showSnackbarAlert } from '../store/slicers/snackbarAlert.slicer';

export const useDownloadFileHook = () => {
  const dispatch = useDispatch();
  const { accessToken } = useAuthContext();

  const downloadFile = async (file: Attachment) => {
    if (!accessToken) {
      console.error('Token de acesso não disponível.');
      return;
    }

    try {
      const response = await axios.get(file.file, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao fazer o download do arquivo', severity: 'error' }));
    }
  };

  return { downloadFile };
};
