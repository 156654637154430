import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, Dialog, DialogTitle, Divider, Grid, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { capitalize } from 'lodash';
import { formatServiceOrderNumber, getSeverityByStatus } from '../../../models/ServiceOrder';
import { hexToRgba } from '../../../utils/utils';
import { ApproveTab } from './TabsContent/ApproveTab';
import { ConclusionTab } from './TabsContent/ConclusionTab';
import { EvidencesTab } from './TabsContent/EvidencesTab';
import { MaterialsExpensesTab } from './TabsContent/MaterialsExpensesTab';
import { RegisterTab } from './TabsContent/RegisterTab';
import { TechnicalAnalysisTab } from './TabsContent/TechnicalAnalysisTab';
import { useServiceOrderFormContext } from './context/ServiceOrderContext';

interface Props {
  editItem?: any;
  onClose: () => void;
}

export const ServiceOrderFormDialog = ({ editItem, onClose }: Props) => {
  const { tabs, currentTab, handleChangeTab, serviceOrder } = useServiceOrderFormContext();
  const theme = useTheme();

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '1rem' }}>
        <DialogTitle variant="h4" color="primary">
          {editItem?.id ? 'Editar' : 'Nova'} Ordem de Serviço{editItem?.id ? `: ${formatServiceOrderNumber(editItem.number)}` : ''}
        </DialogTitle>
        <Button onClick={onClose} startIcon={<CloseIcon />} variant="text">
          Fechar
        </Button>
      </Stack>
      <Divider />

      <Grid container>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentTab}
            onChange={(event, newValue) => {
              handleChangeTab(newValue);
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                disabled={(index > 0 && !serviceOrder?.id) || tab.disabled}
                label={tab.label}
                style={{
                  textTransform: 'none',
                  backgroundColor: currentTab === index ? hexToRgba(theme.palette.primary.main, 0.1) : 'inherit'
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          {serviceOrder?.status && (
            <Box p={3} pb={0}>
              <Alert severity={getSeverityByStatus(serviceOrder?.status)} variant="standard">
                <Typography variant="body1">Situação: {capitalize(serviceOrder?.status_label)}</Typography>
              </Alert>
            </Box>
          )}
          {currentTab === 0 && <RegisterTab />}
          {currentTab === 1 && <TechnicalAnalysisTab />}
          {currentTab === 2 && <MaterialsExpensesTab />}
          {currentTab === 3 && <ApproveTab />}
          {currentTab === 4 && <EvidencesTab onClose={onClose} />}
          {currentTab === 5 && <ConclusionTab />}
          {/* {currentTab === 5 && <RefoundTab />} */}
        </Grid>
      </Grid>
    </Dialog>
  );
};
