import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { ServiceOrder } from '../../../models/ServiceOrder';
import { ServiceOrdersService } from '../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ServiceOrdersFilterFields extends ServiceOrder {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface ServiceOrdersResult extends ApiResults {
  results: ServiceOrder[];
}

interface ServiceOrdersContextData {
  serviceOrders: ServiceOrdersResult | undefined;
  fetchServiceOrders: () => void;
  deleteServiceOrder: (id: number) => Promise<void>;
  deleteManyServiceOrders: (items: string[]) => Promise<void>;
  filterFields: ServiceOrdersFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ServiceOrdersFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ServiceOrdersContext = createContext<ServiceOrdersContextData | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ServiceOrdersPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [serviceOrders, setServiceOrders] = React.useState<ServiceOrdersResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<ServiceOrdersFilterFields>({} as ServiceOrdersFilterFields);

  const fetchServiceOrders = async () => {
    dispatch(showSpinner());
    try {
      const response = await ServiceOrdersService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setServiceOrders(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteServiceOrder = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ServiceOrdersService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchServiceOrders();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyServiceOrders = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ServiceOrdersService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchServiceOrders();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    (async () => {
      await fetchServiceOrders();
    })();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: ServiceOrdersContextData = {
    serviceOrders,
    fetchServiceOrders,
    deleteServiceOrder,
    deleteManyServiceOrders,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ServiceOrdersContext.Provider value={contextValue}>{children}</ServiceOrdersContext.Provider>;
};

export const useServiceOrdersPageContext = () => {
  const context = useContext(ServiceOrdersContext);
  if (!context) {
    throw new Error('useServiceOrdersContext must be used within a ServiceOrdersProvider');
  }
  return context;
};
