import { FieldName } from 'react-hook-form';
import { SelectOption } from '../components/basics/ControlledComboBox';

export const formatDateToApi = (date: string) => {
  //formata a data de dd/mm/yyyy para yyyy-mm-dd
  let dateParts = date.split('/');
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
};

export function formatDate(dateString: string): string {
  const date: Date = new Date(dateString);

  const formattedDate: string = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;

  return formattedDate;
}

export function formatSimpleDate(dateString: string): string {
  const date: Date = new Date(dateString + ' 00:00:00');
  const formattedDate: string = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;

  return formattedDate;
}

export function formatTime(dateString: string): string {
  const date: Date = new Date(dateString);

  const formattedTime: string = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

  return formattedTime;
}

export function formatDateTime(dateString: string): string {
  const formattedDate: string = formatDate(dateString);
  const formattedTime: string = formatTime(dateString);

  const formattedDateTime: string = `${formattedDate} - ${formattedTime}`;

  return formattedDateTime;
}

export function setInputErrorsFromApi<T>(setError: any, errorsObject: any) {
  let formError: string[] = [];

  if (typeof errorsObject !== 'object') {
    return ['Ocorreu um erro interno.'];
  }

  Object.keys(errorsObject).forEach((key) => {
    const keyOf = key as FieldName<T>;
    if (!['detail', 'non_field_errors'].includes(keyOf)) {
      setError(keyOf, { message: errorsObject[key][0], type: 'manual' });
    } else {
      let errors = errorsObject[key];
      if (!Array.isArray(errors)) errors = [errors];

      formError = formError.concat(errors);
    }
  });

  if (formError.length > 0) return formError;
}

export function findSelectedOption(options: SelectOption[], query: any): SelectOption | undefined {
  const selectedOption = options?.find((option) => option.id == query);
  return selectedOption as any;
}

export function findSelectedOptions(options: SelectOption[], queries?: any[]): SelectOption[] {
  if (!queries || queries?.length === 0) {
    return [];
  }

  const queryIds = queries?.map((query) => query?.id);
  const foundOptions = options?.filter((item) => queryIds?.includes(item.id));

  return foundOptions;
}

export const validateCpf = (cpf: string) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return true;
  }

  var digitoVerificador = cpf.substring(9);
  var soma = 0;

  for (var i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }

  var resto = soma % 11;
  var primeiroDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(0)) !== primeiroDigito) {
    return false;
  }

  soma = 0;

  for (var i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }

  resto = soma % 11;
  var segundoDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(1)) !== segundoDigito) {
    return false;
  }

  return true;
};

export const validateCnpj = (cnpj) => {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (caso contrário, passaria na validação do tamanho)
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Calcula os dígitos verificadores
  const calcDigit = (str, factor) => {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += parseInt(str.charAt(i), 10) * factor--;
      if (factor < 2) {
        factor = 9;
      }
    }
    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const firstDigit = calcDigit(cnpj.substring(0, 12), 5);
  const secondDigit = calcDigit(cnpj.substring(0, 13), 6);

  // Verifica se os dígitos verificadores calculados são iguais aos dígitos do CNPJ
  if (parseInt(cnpj.charAt(12), 10) !== firstDigit || parseInt(cnpj.charAt(13), 10) !== secondDigit) {
    return false;
  }

  return true;
};

export const hexToRgba = (hex: string, alpha: number): string => {
  // Remova o caractere '#' do início, se presente.
  hex = hex.replace('#', '');

  // Converta os valores de cor em números inteiros.
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Garanta que o valor alfa esteja no intervalo válido.
  alpha = Math.min(1, Math.max(0, alpha));

  // Crie a representação RGBA da cor.
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const formatCurrency = (value: number | string) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');
  }

  const numericValue = parseFloat(value as string);
  if (isNaN(numericValue)) {
    return '';
  }

  return numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const formatCurrencyToNumber = (value: number | string) => {
  let numericValue = String(value).replace('R$', '').replaceAll('.', '').replace(',', '.').trim();
  return Number(numericValue);
};

export const isValidDate = (value: string | undefined, validateIfBeforeToday: boolean = false) => {
  if (!value) return false; // Empty value is handled by "required"

  const dateParts = value.split('/');
  if (dateParts.length !== 3) return false; // Deve ter formato "dd/mm/yyyy"

  const day = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]);
  const year = parseInt(dateParts[2]);

  if (day < 1 || day > 31 || month < 1 || month > 12 || year > 3000) {
    return false; // Validação de dia, mês e ano
  }

  if (validateIfBeforeToday) {
    const currentDate = new Date();
    const inputDate = new Date(year, month - 1, day); // O mês deve ser subtraído em 1 pois os meses em JavaScript são indexados a partir de 0
    if (inputDate < currentDate) {
      return false; // Data é anterior a hoje
    }
  }

  return true; // Data válida
};
