import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { SERVICE_ORDER_STATUS_OPTIONS } from '../../../models/ServiceOrder';
import { findSelectedOption } from '../../../utils/utils';
import { ClientServiceOrderFilterFields, useClientServiceOrderPageContext } from '../context/ClientServiceOrderPageContext';

interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: ClientServiceOrderFilterFields): ClientServiceOrderFilterFields => {
  let selectedStatus = findSelectedOption(SERVICE_ORDER_STATUS_OPTIONS, filter?.status);
  return {
    status: selectedStatus || null
  };
};

export const ClientServiceOrderFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useClientServiceOrderPageContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Partial<ClientServiceOrderFilterFields>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit = async (data: Partial<ClientServiceOrderFilterFields>) => {
    if ((data.status as SelectOption)?.id == null) {
      delete data.status;
    } else {
      data.status = (data.status as SelectOption)?.id;
    }

    setFilterFields({ ...data } as ClientServiceOrderFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ClientServiceOrderFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="status">Status da Ordem</InputLabel>
            <ControlledComboBox
              name="status"
              placeholder="Status"
              control={control}
              errorMessage={errors.status?.message?.toString()}
              selectOptions={SERVICE_ORDER_STATUS_OPTIONS}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
          Limpar
        </Button>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
