import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { PaymentAuthorizationFilterFields } from '../sections/@payment-authorization/context/PaymentAuthorizationPageContext';

const PaymentAuthorizationsUrl = '/financial/payment-authorization';

export const PaymentAuthorizationService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(PaymentAuthorizationsUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: PaymentAuthorizationFilterFields, page: number = 0, page_size: number = 1000) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(PaymentAuthorizationsUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getById: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${PaymentAuthorizationsUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
