import { Box, Card, CardContent, Typography } from '@mui/material';
import { ServiceOrder } from '../../../../../models/ServiceOrder';
import { EvidencesData } from './components/EvidencesData';
import { EvidencesMaterialsExpenses } from './components/EvidencesMaterialsExpenses';

interface Props {
  serviceOrder: ServiceOrder;
}

export const EvidencesTab = ({ serviceOrder }: Props) => {
  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Conclusão */}
      <Typography variant="h3" gutterBottom>
        Evidências
      </Typography>

      {/* CARD de dados das evidencias */}
      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          {/* componente com as informações básicas das evidências */}
          <EvidencesData serviceOrder={serviceOrder} />

          {/* Componente com os materiais e despesas das evidencias */}
          <EvidencesMaterialsExpenses serviceOrder={serviceOrder} />
        </CardContent>
      </Card>
    </Box>
  );
};
