import { useAuthContext } from '../../../context/AuthContextProvider';
import { ClientServiceOrderPage } from '../../../sections/@client-service-order-page/ClientServiceOrderPage';
import { ClientServiceOrderPageContext } from '../../../sections/@client-service-order-page/context/ClientServiceOrderPageContext';
import { ServiceOrdersPage } from '../../../sections/@service-order-page/ServiceOrdersPage';
import { ServiceOrdersPageContext } from '../../../sections/@service-order-page/context/ServiceOrdersPageContext';

export const ServiceOrderWrapper = () => {
  const { user } = useAuthContext();

  return (
    <>
      {user?.type == 'CLIENT' ? (
        //se for um cliente, renderiza a página de ordens de serviço do cliente
        <ClientServiceOrderPageContext>
          <ClientServiceOrderPage />
        </ClientServiceOrderPageContext>
      ) : (
        <ServiceOrdersPageContext>
          <ServiceOrdersPage />
        </ServiceOrdersPageContext>
      )}
    </>
  );
};
