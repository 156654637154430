import { motion } from 'framer-motion';
import React from 'react';

export interface AnimateButtonProps {
  children: React.ReactNode;
  type?: 'slide' | 'scale' | 'rotate';
}

export const AnimateButton: React.FC<AnimateButtonProps> = ({ children, type = 'scale' }) => {
  switch (type) {
    case 'rotate':
    case 'slide':
    case 'scale':
    default:
      return (
        <motion.div whileHover={{ scale: 1 }} whileTap={{ scale: 0.9 }}>
          {children}
        </motion.div>
      );
  }
};
