import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { Button, Grid, InputLabel, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../../../../components/basics/ControlledTextInput';
import { DialogComponent } from '../../../../../../components/basics/DialogComponent';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { useServiceOrderFormContext } from '../../../context/ServiceOrderContext';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmitForm: () => void;
}

export const ApproveReasonDialog = ({ open, onClose, onSubmitForm }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({});

  const dispatch = useDispatch();

  const { serviceOrderPartialUpdate, fetchServiceOrder } = useServiceOrderFormContext();

  const onSubmit = async (data: any) => {
    try {
      onSubmitForm();
      dispatch(showSpinner());

      const response = await serviceOrderPartialUpdate({ status: 'WAITING_EVIDENCES', approved_description: data.reason });

      if (checkResponseStatus(response)) {
        dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'Ordem de serviço aprovada com sucesso', severity: 'success' }));
        await fetchServiceOrder();
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message || 'Erro ao aprovar ordem de serviço', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <DialogComponent open={open} onClose={onClose} title="Aprovação" fullWidth showCloseButton>
      <Grid container>
        <Grid item xs={12}>
          <InputLabel htmlFor="reason" sx={{ pb: 1 }}>
            Observação
          </InputLabel>
          <ControlledTextInput
            control={control}
            name="reason"
            placeholder="Observação (caso necessário)"
            minRows={4}
            errorMessage={errors.reason?.message?.toString()}
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="success"
              startIcon={<AssignmentTurnedInOutlinedIcon />}
              sx={{ ml: 2 }}
              onClick={handleSubmit(onSubmit)}
            >
              Aprovar
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};
