import { TableCell, tableCellClasses } from '@mui/material';
import styled from 'styled-components';

export const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    paddingVertical: 16,
    fontWeight: 'bold'
  }
}));
