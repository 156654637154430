import { Box } from '@mui/material';
import styled from 'styled-components';

export const DrawerHeaderStyled = styled(Box).attrs({})`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(0, 1)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`;
