import { Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Paper, Table, TableContainer, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledTableBody } from '../../../../../../components/basics/StyledTableBody';
import { StyledTableCell } from '../../../../../../components/basics/StyledTableCell';
import { StyledTableHead } from '../../../../../../components/basics/StyledTableHead';
import { StyledTableRow } from '../../../../../../components/basics/StyledTableRow';
import { StyledTableCellHeader } from '../../../../../../components/basics/TableHeaderComponent/styles';
import { ServiceTypeEnum, TechnicalAnalysis } from '../../../../../../models/TechnicalAnalysis';
import { ServiceOrderTechnicalAnalysesService } from '../../../../../../services/ServiceOrderTechnicalAnalyses.service';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { formatDate } from '../../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../../context/ServiceOrderContext';
import { DetailsModal } from '../DetailsModal';
import { EditModal } from '../EditModal';

export const AnalysisHistoryCard = () => {
  const dispatch = useDispatch();
  const { serviceOrder } = useServiceOrderFormContext();

  const REPROVED_ORDER = serviceOrder.status == 'REPROVED';

  const [analysisHistory, setAnalysisHistory] = useState<TechnicalAnalysis[]>([]);
  const [analysisDetails, setAnalysisDetails] = useState<TechnicalAnalysis | null>(null);

  const [analysisToEdit, setAnalysisToEdit] = useState<TechnicalAnalysis | null>(null);

  const fetchTechnicalAnalyses = async () => {
    try {
      const response = await ServiceOrderTechnicalAnalysesService.getByServiceOrderId(serviceOrder.id);
      setAnalysisHistory(response?.data?.results);
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar análises técnicas', severity: 'error' }));
    }
  };

  const handleShowAnalysisDetails = (analysis: any) => {
    setAnalysisDetails(analysis);
  };

  const handleHideAnalysisDetails = () => {
    setAnalysisDetails(null);
  };

  const handleShowEditModal = (analysis: any) => {
    setAnalysisToEdit(analysis);
  };

  const handleHideEditModal = () => {
    setAnalysisToEdit(null);
  };

  useEffect(() => {
    fetchTechnicalAnalyses();
  }, []);

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Histórico de Análises
      </Typography>
      {analysisHistory?.length > 0 ? (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="analysis table">
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCellHeader>Tipo</StyledTableCellHeader>
                <StyledTableCellHeader>Local Defeito</StyledTableCellHeader>
                <StyledTableCellHeader>Defeitos</StyledTableCellHeader>
                <StyledTableCellHeader>Local de Atendimento</StyledTableCellHeader>
                <StyledTableCellHeader>Descrição</StyledTableCellHeader>
                <StyledTableCellHeader>Data</StyledTableCellHeader>
                <StyledTableCellHeader>Detalhes</StyledTableCellHeader>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {analysisHistory?.map((row, indexHistory) => (
                <StyledTableRow key={indexHistory}>
                  <StyledTableCell>{ServiceTypeEnum[row.service_type] || ' - '}</StyledTableCell>
                  <StyledTableCell>
                    {row?.defects?.map((defect) => (
                      <div key={defect.id}>{defect?.location?.description}</div>
                    ))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.defects?.map((defect, indexDefect) => (
                      <Fragment key={indexDefect}>
                        {defect.defects_groups_relationships?.map((defectGroup, indexRelationship) => (
                          <div key={indexRelationship}>{defectGroup?.defect.description}</div>
                        ))}
                      </Fragment>
                    ))}
                  </StyledTableCell>
                  <StyledTableCell>{row?.support_address || 'Endereço do Cliente'}</StyledTableCell>
                  <StyledTableCell>{row?.description}</StyledTableCell>
                  <StyledTableCell>{formatDate(row?.date)}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={() => handleShowAnalysisDetails(row)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleShowEditModal(row)}>
                      <Edit />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" gutterBottom>
          Nenhum histórico de análise encontrado.
        </Typography>
      )}
      <DetailsModal analysisDetails={analysisDetails} onCancel={handleHideAnalysisDetails} />
      {analysisToEdit && <EditModal analysisEdition={analysisToEdit} onCancel={handleHideEditModal} />}
    </Box>
  );
};
