import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { DefectGroup } from '../../../models/DefectGroup';
import { DefectLocation } from '../../../models/DefectLocation';
import { DefectsGroupsService } from '../../../services/DefectsGroups.service';
import { DefectsLocationsService } from '../../../services/DefectsLocations.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../store/store';
import { checkResponseStatus } from '../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useDefectsLocationsPageContext } from '../context/DefectsPlacesPageContext';

interface Props {
  editItem?: DefectLocation;
  onClose: () => void;
}

export const DefectsLocationsFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchDefectsLocations } = useDefectsLocationsPageContext();
  const { loading } = useSelector((state: RootState) => state.globalSpinner);

  const [defectsGroups, setDefectsGroups] = useState<DefectLocation[]>([]);

  const getDefaultValues = (): DefectLocation => {
    return {
      id: editItem?.id ?? 0,
      description: editItem?.description ?? '',
      is_active: editItem?.is_active ?? true,
      groups: (editItem?.groups?.map((group: any) => ({ id: group.id, name: group.description })) as SelectOption[]) ?? []
    };
  };

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<DefectLocation>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues()
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const createOrUpdateDefect: SubmitHandler<DefectLocation> = async (data: DefectLocation) => {
    let formSubmit = getValues();
    formSubmit.groups = formSubmit.groups?.map((group: SelectOption) => group.id);

    dispatch(showSpinner());
    try {
      editItem?.id ? await DefectsLocationsService.update(formSubmit) : await DefectsLocationsService.create(formSubmit);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Local de defeito atualizado com sucesso!' : 'Local de defeito criado com sucesso!',
          severity: 'success'
        })
      );
      fetchDefectsLocations();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  const fetchDefectsGroups = async () => {
    dispatch(showSpinner());
    try {
      const response = await DefectsGroupsService.getAll(true);
      if (checkResponseStatus(response)) {
        setDefectsGroups(
          response?.data.results.map((group: DefectGroup) => ({ id: group.id, name: group.description })) as DefectLocation[]
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchDefectsGroups();
  }, []);

  if (loading) return <></>;

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Local do Defeito
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateDefect)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="status">Descrição</InputLabel>
              <ControlledTextInput name="description" control={control} errorMessage={errors.description?.message?.toString()} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="status">Grupo</InputLabel>
              <ControlledComboBox
                name="groups"
                control={control}
                errorMessage={errors.groups?.message?.toString()}
                selectOptions={defectsGroups}
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="status">Situação</InputLabel>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateDefect)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
