import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../localstorage/LocalStorage.keys';
import { decryptUserTokens } from '../middlewares/axios.interceptors';
import { User } from '../models/User';
import { ROUTES } from '../routes/routes';
import { AuthService } from '../services/Auth.service';
import { hideSpinner, showSpinner } from '../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../utils/api/response';

interface IAuthContext {
  user: User | null;
  setUser: (user: User | null) => void;
  clearUser: () => void;
  accessToken: string | null;
}

interface Props {
  children: ReactNode;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const fetchUserData = async () => {
    try {
      dispatch(showSpinner());
      const response = await AuthService.getUserSessionData();
      if (response && checkResponseStatus(response)) {
        setUser(response.data);
      }
    } catch (error: any) {
      clearUser();
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar dados do usuário, você foi deslogado',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const checkUserLoggedIn = () => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (token) {
      const access_token = decryptUserTokens()?.access_token;
      if (access_token) {
        setAccessToken(access_token);
      }
      fetchUserData();
    } else {
      navigate(ROUTES.public.login);
    }
  };

  const clearUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    setUser(null);
    navigate(ROUTES.public.login);
  };

  const isPublicUrl = () => {
    return Object.values(ROUTES.public).filter((path) => matchPath(path, location.pathname)).length > 0;
  };

  useEffect(() => {
    if (!isPublicUrl()) {
      checkUserLoggedIn();
    }
  }, [navigate]);

  return <AuthContext.Provider value={{ user, setUser, clearUser, accessToken }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
