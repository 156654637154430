import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { FinancialRegistry } from '../../../models/FinancialRegistry';
import { FinancialRegistryService } from '../../../services/FinancialRegistry.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface PayServiceOrderFilterFields extends FinancialRegistry {
  order_by?: string;
  page?: number;
  page_size?: number;
}

export interface PayServiceOrderResult extends ApiResults {
  results: FinancialRegistry[];
}

interface PayServiceOrderContextProps {
  financialRegistries: PayServiceOrderResult | undefined;
  fetchFinancialRegistries: () => void;
  filterFields: PayServiceOrderFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<PayServiceOrderFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const PayServiceOrderPageContext = createContext<PayServiceOrderContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const PayServiceOrderPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [financialRegistries, setFinancialRegistries] = useState<PayServiceOrderResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<PayServiceOrderFilterFields>({} as PayServiceOrderFilterFields);

  const fetchFinancialRegistries = async () => {
    dispatch(showSpinner());

    try {
      const response = await FinancialRegistryService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        const registries = response?.data?.results.map((registry) => {
          let totalResaleMaterials = 0;
          let totalExpenses = 0;

          registry.resale_materials.map((material) => {
            totalResaleMaterials += material.final_price;
          });

          registry.expenses.map((expense) => {
            totalExpenses += expense.final_cost;
          });

          const total = totalResaleMaterials + totalExpenses;
          return { ...registry, total };
        });
        setFinancialRegistries(registries);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchFinancialRegistries();
  }, [filterFields]);

  const contextValue: PayServiceOrderContextProps = {
    financialRegistries,
    fetchFinancialRegistries,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <PayServiceOrderPageContext.Provider value={contextValue}>{children}</PayServiceOrderPageContext.Provider>;
};

export const usePayServiceOrderPageContext = () => {
  const context = useContext(PayServiceOrderPageContext);
  if (!context) {
    throw new Error('usePayServiceOrderPageContext must be used within a PayServiceOrderPageContextProvider');
  }
  return context;
};
