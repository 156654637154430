import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Dialog, DialogTitle, Divider, Grid, InputLabel, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { DropAttachmentComponent } from '../../../components/basics/DropAttachmentComponent';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { useUploadFile } from '../../../hooks/useUploadFileHook';
import { ErpProductsService } from '../../../services/ErpProducts.service';
import { ServiceOrdersService } from '../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../utils/utils';

interface Props {
  editItem?: any;
  onClose: () => void;
}

export const ClientServiceOrderFormDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();

  const { user } = useAuthContext();
  const { uploadFiles } = useUploadFile();

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const handleUploadFiles = (files: any) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDeleteFile = (file: any) => {
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const {
    control,
    setValue,
    setError,
    reset,
    formState: { errors },
    clearErrors,
    handleSubmit
  } = useForm<any>({
    defaultValues: {}
  });

  const watchSerialNumber = useWatch({
    control,
    name: 'serial_number'
  });

  const formValidations = (data: any) => {
    if (!data.serial_number) {
      setError('serial_number', { message: 'Produto é obrigatório!', type: 'manual' });
      return false;
    }

    return true;
  };

  const onSubmit = async (data: any) => {
    if (!formValidations(data)) return;

    const filesUrls = await uploadFiles(uploadedFiles);
    const formSubmit = {
      serial_number: data.serial_number,
      description: data.description,
      attachments: filesUrls
    };

    try {
      const response = await ServiceOrdersService.create(formSubmit);
      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Ordem de serviço criada com sucesso',
            severity: 'success'
          })
        );
        onClose();
      }
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(showSnackbarAlert({ title: 'Erro', message: formError, severity: 'error' }));
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchProductBySerialNumber = async (serialNumber: string) => {
    dispatch(showSpinner({ message: 'Aguarde, buscando dados' }));
    try {
      const response = await ErpProductsService.getBySerialNumber(serialNumber);
      if (checkResponseStatus(response)) {
        if (response?.data.results.length == 0) {
          dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar produto', severity: 'error' }));
        } else {
          setValue('product_name', response?.data?.results[0]?.id + ' - ' + response?.data.results[0]?.description);
          clearErrors('serial_number');
        }
      } else {
        dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar produto', severity: 'error' }));
      }
    } catch {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar produto', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    if (watchSerialNumber) {
      const search = setTimeout(() => {
        fetchProductBySerialNumber(watchSerialNumber);
      }, 500);
      return () => clearTimeout(search);
    }
  }, [watchSerialNumber]);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '1rem' }}>
        <DialogTitle variant="h4" color="primary">
          Nova Ordem de Serviço
        </DialogTitle>
        <Button onClick={onClose} startIcon={<CloseIcon />} variant="text">
          Fechar
        </Button>
      </Stack>
      <Divider />

      <Box p={4} m={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">Produto</Typography>
            </Grid>
            <Grid item sm={4}>
              <InputLabel htmlFor="serial_number">Número de Série</InputLabel>
              <ControlledTextInput
                control={control}
                name="serial_number"
                placeholder="Número de Série"
                helperText="Digite o número de série e aguarde a busca."
                errorMessage={errors.serial_number?.message?.toString()}
              />
            </Grid>
            <Grid item sm={8}>
              <InputLabel htmlFor="product_name">Descrição do Produto</InputLabel>
              <ControlledTextInput control={control} name="product_name" placeholder="Descrição do Produto" disabled />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Problema</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="description">Registro do Problema</InputLabel>
              <ControlledTextInput control={control} name="description" minRows={4} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Anexos</Typography>
            </Grid>
            <Grid item xs={12}>
              <DropAttachmentComponent uploadedFiles={uploadedFiles} onUploadFile={handleUploadFiles} onDeleteFile={handleDeleteFile} />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />} sx={{ ml: 2 }}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
};
