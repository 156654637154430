import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissionsHook';
import { Client } from '../../../models/Client';
import { ClientsDetailsDialog } from '../ClientsDetailsDialog';
import { ClientsFilterDialog } from '../ClientsFilterDialog';
import { ClientsTable } from '../ClientsTable';
import { useClientsPageContext } from '../context/ClientsPageContext';
import { ButtonsContainer } from './styles';

export const ClientsPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'CLIENT_ACTIVATION'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [viewItem, setViewItem] = useState<Client | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields, fetchClients } = useClientsPageContext();

  const handleViewItem = (item: Client) => {
    setViewItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setViewItem(undefined);
    fetchClients();
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 1000);
      debounced();
      return debounced.cancel;
    } else {
      setFilterFields({
        ...filterFields,
        search: undefined
      });
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Box mr={1}>
              <ControlledTextInput
                control={control}
                name="search"
                label="Busca de Clientes"
                placeholder="Digite para Pesquisar"
                sx={{ mr: 1 }}
                type="search"
              />
            </Box>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros
              </Button>
            </Tooltip>
          </>
        )}
      </ButtonsContainer>
      {permissions.hasReadPermission && <ClientsTable onView={handleViewItem} permissions={permissions} />}
      {showFilter && permissions.hasReadPermission && <ClientsFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <ClientsDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
    </>
  );
};
