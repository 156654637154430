import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { capitalize } from 'lodash';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { StyledTableCell } from '../../../components/basics/StyledTableCell';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissionsHook';
import { Client } from '../../../models/Client';
import { formatServiceOrderNumber, getSeverityByStatus, ServiceOrder } from '../../../models/ServiceOrder';
import { getUserName, User } from '../../../models/User';
import { formatDate } from '../../../utils/utils';
import { useClientServiceOrderPageContext } from '../context/ClientServiceOrderPageContext';

const headCells: readonly HeadCell[] = [
  {
    id: 'number',
    align: 'left',
    disablePadding: true,
    disableSorting: false,
    label: 'Ordem'
  },
  {
    id: 'serial_number',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Número de Série'
  },
  {
    id: 'description',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Descrição Produto'
  },
  {
    id: 'client',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Cliente'
  },
  {
    id: 'description',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Descrição Problema'
  },
  {
    id: 'created_at',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Criado Em'
  },
  {
    id: 'owner',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Responsável'
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Status'
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    disableSorting: true,
    label: 'Ações'
  }
];

interface Props {
  onView: (item: ServiceOrder) => void;
  permissions: PermissionsObj;
}

export const ClientServiceOrderTable = ({ onView, permissions }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ServiceOrder>('order');
  const [selected, setSelected] = useState<string[]>([]);

  const { serviceOrders, page, rowsPerPage, handleOrderChange, handlePageChange, handleOnChangeRowsPerPage } =
    useClientServiceOrderPageContext();

  const handleRequestSort = (property: keyof ServiceOrder) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (selectedAll: boolean) => {
    if (selectedAll) {
      const newSelected = serviceOrders?.results.map((item: ServiceOrder) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    setSelected([]);
  }, [serviceOrders]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={serviceOrders?.count || 0}
            />
            <TableBody>
              {serviceOrders && serviceOrders?.count > 0 ? (
                <>
                  {serviceOrders?.results?.map((row: ServiceOrder, index: number) => {
                    const isItemSelected = isSelected(row?.id!);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow
                        hover
                        onClick={(event) => handleClick(event, String(row.id))}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <StyledTableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" id={labelId} scope="row" padding="none" align="left">
                          {formatServiceOrderNumber(row.number)}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.product.serial_number}</StyledTableCell>
                        <StyledTableCell align="left">{row.product.description}</StyledTableCell>
                        <StyledTableCell align="left">{(row?.client as Client)?.company_name}</StyledTableCell>
                        <StyledTableCell align="left">{row?.description}</StyledTableCell>
                        <StyledTableCell align="left">{formatDate(row.created_at)}</StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.technical ? getUserName(row.technical as User) : 'Não informado'}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {
                            <Alert severity={getSeverityByStatus(row?.status)} variant="standard" sx={{ py: 0.2, borderRadius: 5 }}>
                              <Typography variant="body2">{capitalize(row?.status_label)}</Typography>
                            </Alert>
                          }
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {permissions.hasReadPermission && (
                            <Tooltip title="Visualizar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onView(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={8}>
                    Nenhum registro encontrado
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {serviceOrders && serviceOrders?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={serviceOrders?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        )}
      </Paper>
    </Box>
  );
};
