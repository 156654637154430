import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { useDownloadFileHook } from '../../../hooks/useDownloadFileHook';
import { Attachment } from '../../../models/Attachment';
import { PreviewImage } from './PreviewImage';

interface Props {
  uploadedFiles: any[];
  onUploadFile?: (files: any[]) => void;
  onDeleteFile?: (file: any) => void;
  disabled?: boolean;
}

export const DropAttachmentThumbComponent = ({ uploadedFiles, onUploadFile, onDeleteFile, disabled }: Props) => {
  const { downloadFile } = useDownloadFileHook();
  const [imageThumbnails, setImageThumbnails] = useState<string[]>([]);
  const { accessToken } = useAuthContext();
  const [viewItem, setViewItem] = useState<Attachment | undefined>(undefined);
  const [uploadedThumbnails, setUploadedThumbnails] = useState<{ file: File; url: string }[]>([]);

  const handlePreviewItem = (item: Attachment) => {
    setViewItem(item);
  };
  const handleClosePreview = () => {
    setViewItem(undefined);
  };

  const handleDeleteImage = (index: number) => {
    const newThumbnails = [...imageThumbnails];
    newThumbnails.splice(index, 1);
    setImageThumbnails(newThumbnails);
    onDeleteFile && onDeleteFile(uploadedFiles[index]);
  };

  const isImageFile = (fileName: string) => {
    return /\.(jpeg|jpg|png)$/i.test(fileName);
  };

  useEffect(() => {
    const fetchImageThumbnails = async () => {
      const thumbnailUrls = await Promise.all(
        uploadedFiles.map(async (file) => {
          try {
            const response = await fetch(file.file, {
              headers: {
                Authorization: 'Bearer ' + accessToken
              }
            });
            if (response.ok) {
              const blob = await response.blob();
              return URL.createObjectURL(blob);
            } else {
              console.error('Erro ao carregar a imagem:', response.statusText);
              return null;
            }
          } catch (error) {
            console.error('Erro ao carregar a imagem:', error);
            return null;
          }
        })
      );
      setImageThumbnails((prevThumbnails) => [...prevThumbnails, ...thumbnailUrls]);
    };

    fetchImageThumbnails();

    return () => {
      imageThumbnails.forEach(URL.revokeObjectURL);
    };
  }, [uploadedFiles, accessToken]);

  const handleImageUpload = async (acceptedFiles: File[]) => {
    const imageFiles = acceptedFiles.filter((file) => file);
    const filesUnder50MB = imageFiles.filter((file) => file.size <= 50 * 1024 * 1024);

    if (filesUnder50MB.length === imageFiles.length) {
      onUploadFile && onUploadFile(filesUnder50MB);

      const uploadedFilesWithUrls = await Promise.all(
        filesUnder50MB.map(async (file) => {
          const url = URL.createObjectURL(file);
          return { file, url };
        })
      );

      setImageThumbnails((prevThumbnails) => [...prevThumbnails, ...uploadedFilesWithUrls.map((obj) => obj.url)]);
      setUploadedThumbnails((prevThumbnails) => [...prevThumbnails, ...uploadedFilesWithUrls]);
    } else {
      alert('O tamanho do arquivo deve ser no máximo 50 MB.');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageUpload
  });

  return (
    <>
      {!disabled && (
        <div {...getRootProps()}>
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            sx={{
              display: 'flex',
              flex: 1,
              p: 2,
              border: 'solid 0.5px #d9d9d9',
              borderRadius: '4px',
              justifyContent: 'space-evenly'
            }}
          >
            <AttachFileIcon fontSize="small" />
            <Typography variant="body2" gutterBottom>
              Arraste e largue seu(s) arquivo(s) aqui para anexar ou <Link to="#">localize em seu computador.</Link>
              <br />
              Você pode anexar vários arquivos de uma vez.
            </Typography>
            <Typography variant="caption">Tamanho máximo de 50 MB</Typography>
          </Stack>
          <input {...getInputProps()} />
        </div>
      )}

      {uploadedFiles?.length > 0 ? (
        <List sx={{ mt: disabled ? 0 : 2 }}>
          <Typography variant="body1" gutterBottom color="secondary">
            Arquivos anexados:
          </Typography>
          <Divider />
          {uploadedFiles?.map((file, index) => {
            const uploadedThumbnail = uploadedThumbnails.find((obj) => obj.file === file);
            return (
              <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                {file && isImageFile(file.name) && (
                  <IconButton onClick={() => handlePreviewItem(file)} disabled={!file?.file}>
                    <img
                      src={uploadedThumbnail?.url || imageThumbnails.find((url, i) => i === index)}
                      alt="Thumbnail"
                      style={{ width: 50, height: 50, objectFit: 'cover' }}
                    />
                  </IconButton>
                )}
                <ListItemText primary={file ? file.name : ''} sx={{ marginLeft: '10px' }} />
                <ListItemSecondaryAction>
                  {onDeleteFile && (
                    <IconButton onClick={() => handleDeleteImage(index)}>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  )}
                  {file?.file && (
                    <IconButton onClick={() => downloadFile(file)}>
                      <DownloadIcon color="primary" />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography variant="caption">Nenhum arquivo anexado.</Typography>
      )}
      {viewItem && <PreviewImage item={viewItem} onClose={handleClosePreview} />}
    </>
  );
};

export default DropAttachmentThumbComponent;
