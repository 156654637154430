import AddIcon from '@mui/icons-material/Add';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Card, CardContent, Divider, Grid, InputLabel, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../../../components/basics/AutocompleteSearch';
import { ControlledComboBox, SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { DropAttachmentThumbComponent } from '../../../../../components/basics/DropAttachmentThumbComponent';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import { useUploadFile } from '../../../../../hooks/useUploadFileHook';
import { Client } from '../../../../../models/Client';
import { User, getUserName } from '../../../../../models/User';
import { ClientsService } from '../../../../../services/Clients.service';
import { ErpProductsService } from '../../../../../services/ErpProducts.service';
import { ServiceOrdersService } from '../../../../../services/ServiceOrderRegister.service';
import { UsersService } from '../../../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../context/ServiceOrderContext';
import { NewClientDialog } from './NewClientDialog';
import { TransferenceDialog } from './TranferenceDialog';

export interface ClientOptions extends SelectOption {
  from_erp: boolean;
}

export const RegisterTab = () => {
  const dispatch = useDispatch();

  const { user } = useAuthContext();
  const { uploadFiles } = useUploadFile();
  const { handleSetServiceOrder, serviceOrder, handleChangeTab } = useServiceOrderFormContext();

  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);
  const [showTransferenceDialog, setShowTransferenceDialog] = useState(false);

  const [showNewClientDialog, setShowNewClientDialog] = useState(false);
  const [clientsOptions, setClientsOptions] = useState<ClientOptions[]>([]);
  const [selectedClient, setSelectedClient] = useState<SelectOption | null>(null);
  const [clientError, setClientError] = useState<string | null>(null);

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const serialNumberRef = useRef(null);

  const FINISHED_ORDER = serviceOrder.status == 'REPROVED' || serviceOrder.status == 'COMPLETED';
  const NEW_ORDER = !serviceOrder.id;

  const ALLOW_CHANGE_TECHNICIAN = ['IPACOL', 'ADMIN'].includes(user?.type as string);
  const IS_ASSISTANCE = user?.type === 'ASSISTANCE';

  const [componentKey, setComponentKey] = useState(0);

  const handleUploadFiles = (files: any) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDeleteFile = (file: any) => {
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleTransferenceDialog = () => {
    setShowTransferenceDialog(!showTransferenceDialog);
  };

  const {
    control,
    setValue,
    setError,
    reset,
    formState: { errors },
    clearErrors,
    handleSubmit,
    getValues
  } = useForm<any>({
    defaultValues: {}
  });

  const watchSerialNumber = useWatch({
    control,
    name: 'serial_number'
  });

  const handleNewClient = () => {
    setShowNewClientDialog(true);
  };

  const formValidations = (data: any) => {
    if (!selectedClient?.id) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Cliente é obrigatório!', severity: 'error' }));
      return false;
    }

    if (!data.serial_number) {
      setError('serial_number', { message: 'Produto é obrigatório!', type: 'manual' });
      return false;
    }

    if (!serviceOrder?.id && !data.technician?.id && ALLOW_CHANGE_TECHNICIAN) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Técnico é obrigatório!',
          severity: 'error'
        })
      );
      return false;
    }

    return true;
  };

  const onSubmit = async (data: any) => {
    if (!formValidations(data)) return;

    const filesUrls = await uploadFiles(uploadedFiles);
    const formSubmit = {
      client: selectedClient?.id,
      client_from_erp: (selectedClient as ClientOptions).from_erp ? true : false,
      serial_number: data.serial_number,
      description: data.description,
      client_observation: data.client_observation,
      attachments: filesUrls,
      technical: data.technician?.id
    };

    try {
      const response = await ServiceOrdersService.create(formSubmit);
      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Ordem de serviço criada com sucesso',
            severity: 'success'
          })
        );
        handleSetServiceOrder(response?.data);
        handleChangeTab(1);
      }
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(showSnackbarAlert({ title: 'Erro', message: formError, severity: 'error' }));
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectClient = (value: SelectOption) => {
    setSelectedClient(value);
  };

  const saveUploadFiles = async () => {
    const filesUrls = await uploadFiles(uploadedFiles);
    const payload = {
      attachments: filesUrls
    };

    try {
      const response = await ServiceOrdersService.partialUpdate(serviceOrder.id, payload);

      setTimeout(() => {
        setComponentKey((prevKey) => prevKey + 1);
      }, 10);

      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Anexos Salvos com sucesso',
            severity: 'success'
          })
        );
        handleSetServiceOrder(response?.data);
      }
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(showSnackbarAlert({ title: 'Erro', message: formError, severity: 'error' }));
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchProductBySerialNumber = async (serialNumber: string) => {
    if (!serialNumber) return;

    dispatch(showSpinner({ message: 'Aguarde, buscando dados' }));
    setValue('product_name', '');

    try {
      const response = await ErpProductsService.getBySerialNumber(serialNumber);
      if (checkResponseStatus(response)) {
        if (response?.data.results.length == 0) {
          dispatch(showSnackbarAlert({ title: 'Alerta', message: 'Número de série não encontrado', severity: 'warning' }));
        } else {
          setValue('product_name', response?.data?.results[0]?.id + ' - ' + response?.data.results[0]?.description);
          clearErrors('serial_number');
        }
      } else {
        dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao consultar número de série', severity: 'error' }));
      }
    } catch {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao consultar número de série', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchClients = async (watchClientSearch?: string) => {
    try {
      const response = await ClientsService.getWithErp({
        search: watchClientSearch
      });
      if (checkResponseStatus(response)) {
        setClientsOptions(
          response?.data.results.map((client: Client) => {
            let clientName = (client as Client)?.company_name
              ? (client?.code_erp ? client?.code_erp + ' - ' : '') + (client as Client)?.company_name
              : (client?.code_erp ? client?.code_erp + ' - ' : '') + getUserName(client as any);

            return {
              id: client.id,
              name: clientName,
              from_erp: client.from_erp
            };
          })
        );
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar clientes', severity: 'error' }));
    }
  };

  const retrieveClientFromUser = async (userId: string) => {
    try {
      const response = await ClientsService.get({ user: userId }, 0, 1);
      if (checkResponseStatus(response) && response?.data?.results) {
        if (response.data.results.length === 0) {
          setClientError('Nenhum cliente selecionado. Usuário logado não possui vínculo com cliente.');
          return;
        }
        const client = response.data.results[0] as Client;
        const code = client?.code_erp ? `${client?.code_erp} - ` : '';
        setSelectedClient({ id: client.id, name: `${code}${client?.company_name}` });
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar clientes', severity: 'error' }));
    }
  };

  const fetchUsers = async () => {
    if (!ALLOW_CHANGE_TECHNICIAN) return;
    dispatch(showSpinner({ message: 'Aguarde, buscando dados' }));
    try {
      const response = await UsersService.get({
        type: ['IPACOL', 'ASSISTANCE'],
        page_size: 0
      });
      if (checkResponseStatus(response)) {
        setUserOptions(
          response?.data.results.map((user: User) => ({
            id: user.id,
            name: user.first_name && user.last_name ? user.first_name + ' ' + user.last_name : user.email
          }))
        );
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar técnicos', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const setFormDefaultValues = () => {
    let clientName = (serviceOrder?.client as Client)?.company_name
      ? (serviceOrder?.client as Client)?.code_erp + ' - ' + (serviceOrder?.client as Client)?.company_name
      : (serviceOrder?.client as Client)?.code_erp + ' - ' + getUserName(serviceOrder?.client as any);

    setSelectedClient({
      id: serviceOrder?.client?.id,
      name: clientName
    });

    setClientsOptions([
      {
        id: serviceOrder?.client?.id,
        name: clientName,
        from_erp: serviceOrder?.client_from_erp
      }
    ]);

    reset({
      serial_number: serviceOrder?.product?.serial_number,
      product_name: serviceOrder?.product?.description,
      description: serviceOrder?.description,
      client_observation: serviceOrder?.client_observation
    });

    setUploadedFiles(serviceOrder?.attachments);
  };

  useEffect(() => {
    if (serviceOrder.id) {
      setFormDefaultValues();
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (user?.type === 'ASSISTANCE') {
      retrieveClientFromUser(String(user.id));
    }
  }, [user]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box key={componentKey} p={2} m={2}>
      {/* Conteúdo da aba Registro */}
      <Typography variant="h3" gutterBottom>
        Registro
      </Typography>

      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">Cliente</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <InputLabel htmlFor="client">Cliente</InputLabel>
                <AutocompleteSearch
                  value={selectedClient}
                  onSearch={fetchClients}
                  selectOptions={clientsOptions}
                  disabled={IS_ASSISTANCE}
                  onSelect={(event, value: SelectOption) => {
                    handleSelectClient(value);
                  }}
                  errorMessage={errors.client?.id?.message || clientError}
                />
              </Box>
              {!FINISHED_ORDER && !IS_ASSISTANCE && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={() => handleNewClient()} startIcon={<AddIcon />}>
                    Novo Cliente
                  </Button>
                </Box>
              )}
            </Grid>
            {IS_ASSISTANCE && (
              <Grid item sm={12}>
                <InputLabel htmlFor="client_observation">Observações do Cliente</InputLabel>
                <ControlledTextInput
                  control={control}
                  name="client_observation"
                  placeholder="Observações do Cliente"
                  onBlur={() => fetchProductBySerialNumber(watchSerialNumber)}
                  errorMessage={errors.client_observation?.message?.toString()}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Produto</Typography>
            </Grid>
            <Grid item sm={4}>
              <InputLabel htmlFor="serial_number">Número de Série</InputLabel>
              <ControlledTextInput
                control={control}
                name="serial_number"
                placeholder="Número de Série"
                helperText="Digite o número de série e aguarde a busca."
                onBlur={() => fetchProductBySerialNumber(watchSerialNumber)}
                errorMessage={errors.serial_number?.message?.toString()}
                inputRef={serialNumberRef}
              />
            </Grid>
            <Grid item sm={8}>
              <InputLabel htmlFor="product_name">Descrição do Produto</InputLabel>
              <Typography variant="h6" color="text.primary" sx={{ justifyContent: 'end', marginTop: 1 }}>
                {getValues('product_name') || ' - '}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Problema</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="description">Registro do Problema</InputLabel>
              <ControlledTextInput control={control} name="description" minRows={4} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Anexos</Typography>
            </Grid>
            <Grid item xs={12}>
              <DropAttachmentThumbComponent
                uploadedFiles={uploadedFiles}
                onUploadFile={handleUploadFiles}
                onDeleteFile={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              {serviceOrder.id && (
                <Button variant="contained" color="success" onClick={saveUploadFiles} startIcon={<SaveIcon />}>
                  Salvar Anexos
                </Button>
              )}
            </Grid>
            {NEW_ORDER ? (
              <>
                {ALLOW_CHANGE_TECHNICIAN && (
                  <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">Técnico</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="technician">Técnico</InputLabel>
                      <ControlledComboBox
                        control={control}
                        name="technician"
                        placeholder="Selecione um técnico"
                        selectOptions={userOptions}
                        errorMessage={errors.technician?.message?.toString()}
                        required
                      />
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Técnico</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="technical">Técnico</InputLabel>
                  <Typography>{getUserName(serviceOrder.technical as User)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="status">Tipo do Técnico</InputLabel>
                  <Typography>{serviceOrder?.technical?.type}</Typography>
                </Grid>
              </>
            )}

            {!FINISHED_ORDER && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {serviceOrder.id && ALLOW_CHANGE_TECHNICIAN && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AssignmentIndIcon />}
                      onClick={() => handleTransferenceDialog()}
                    >
                      Transferir ordem para outro técnico
                    </Button>
                  )}
                  {!serviceOrder.id && (
                    <Button variant="contained" color="success" startIcon={<SaveIcon />} sx={{ ml: 2 }} onClick={handleSubmit(onSubmit)}>
                      Salvar
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* Dialogs */}
      <TransferenceDialog
        open={Boolean(showTransferenceDialog && serviceOrder?.id)}
        onClose={handleTransferenceDialog}
        userOptions={userOptions}
      />
      <NewClientDialog open={showNewClientDialog} onClose={() => setShowNewClientDialog(false)} />
    </Box>
  );
};
