import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Manual } from '../../../models/Manual';
import { ManualsService } from '../../../services/Manuals.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ManualsFilterFields extends Manual {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface ManualsResult extends ApiResults {
  results: Manual[];
}

interface ManualsContextProps {
  manuals: ManualsResult | undefined;
  fetchManuals: () => void;
  deleteManual: (id: number) => Promise<void>;
  deleteManyManuals: (items: string[]) => Promise<void>;
  filterFields: ManualsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ManualsFilterFields>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ManualsContext = createContext<ManualsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ManualsPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [manuals, setManuals] = React.useState<ManualsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<ManualsFilterFields>({});

  const fetchManuals = async () => {
    dispatch(showSpinner());
    try {
      const response = await ManualsService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setManuals(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManual = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ManualsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchManuals();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyManuals = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ManualsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchManuals();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchManuals();
  }, [filterFields, page, rowsPerPage]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchManuals();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const contextValue: ManualsContextProps = {
    manuals,
    fetchManuals,
    deleteManual,
    deleteManyManuals,
    filterFields,
    setFilterFields,
    setShouldRefresh,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ManualsContext.Provider value={contextValue}>{children}</ManualsContext.Provider>;
};

export const useManualsPageContext = () => {
  const context = useContext(ManualsContext);
  if (!context) {
    throw new Error('useManualsContext must be used within a ManualsProvider');
  }
  return context;
};
