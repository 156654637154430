import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { ServiceOrder } from '../models/ServiceOrder';
import { ServiceOrdersFilterFields } from '../sections/@service-order-page/context/ServiceOrdersPageContext';

const ServiceOrdersUrl = '/work-orders/';

export const ServiceOrdersService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ServiceOrdersUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: ServiceOrdersFilterFields, page: number = 0, page_size: number = 1000) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ServiceOrdersUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getById: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${ServiceOrdersUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async <Type>(data: Type) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(ServiceOrdersUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: Partial<ServiceOrder>) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${ServiceOrdersUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  partialUpdate: async (id: number, data: Partial<ServiceOrder>) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.patch(`${ServiceOrdersUrl}${id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${ServiceOrdersUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${ServiceOrdersUrl}batch-delete/`, {
        ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  transferTo: async <Type>(data: Type) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${ServiceOrdersUrl}transfers/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
