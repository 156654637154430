import { yupResolver } from '@hookform/resolvers/yup';
import { CloseOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, InputLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../../components/basics/ControlledTextInput';
import { ServiceOrdersService } from '../../../../../../services/ServiceOrderRegister.service';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../../utils/api/response';
import { TransferSchema } from '../../../../../../utils/forms/validations/formValidations';
import { useServiceOrderFormContext } from '../../../context/ServiceOrderContext';

interface Props {
  open: boolean;
  userOptions: SelectOption[];
  onClose: () => void;
}

export const TransferenceDialog = ({ open, userOptions, onClose }: Props) => {
  const dispatch = useDispatch();
  const { serviceOrder, fetchServiceOrder } = useServiceOrderFormContext();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      technician: '',
      reason: ''
    },
    resolver: yupResolver(TransferSchema)
  });

  const onSubmit = async (data: any) => {
    let submitData = {
      work_order: serviceOrder.id,
      to_technical: data.technician.id,
      description: data.reason
    };

    try {
      dispatch(showSpinner());
      const response = await ServiceOrdersService.transferTo(submitData);
      if (checkResponseStatus(response)) {
        dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'Ordem de serviço transferida com sucesso', severity: 'success' }));
        await fetchServiceOrder();
        onClose();
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error.message, severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    //dialogo com material ui com opção de selecionar o técnico e botões de confirmar e cancelar
    <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Transferir Ordem para Outro Técnico
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel htmlFor="clientes">Técnico</InputLabel>
            <ControlledComboBox
              control={control}
              name="technician"
              placeholder="Selecione um técnico"
              selectOptions={userOptions}
              required
              errorMessage={errors.technician?.message}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <InputLabel htmlFor="clientes">Motivo</InputLabel>
            <ControlledTextInput
              control={control}
              name="reason"
              placeholder="Informe o motivo da transferência"
              minRows={4}
              required
              errorMessage={errors.reason?.message}
            />
          </Grid>
          <Grid item xs={12} mt={2} columnGap={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outlined" startIcon={<CloseOutlined />} sx={{ ml: 2 }} onClick={onClose}>
              Cancelar
            </Button>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleSubmit(onSubmit)}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
