import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, InputLabel, Typography } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../../../components/basics/ControlledTextInput';
import DropAttachmentThumbComponent from '../../../../../../../components/basics/DropAttachmentThumbComponent';
import { useUploadFile } from '../../../../../../../hooks/useUploadFileHook';
import { Client } from '../../../../../../../models/Client';
import { showSnackbarAlert } from '../../../../../../../store/slicers/snackbarAlert.slicer';
import { EvidencesSchema } from '../../../../../../../utils/forms/validations/formValidations';
import { findSelectedOption, formatDate, formatDateToApi } from '../../../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../../../context/ServiceOrderContext';

export interface EvidencesDataHandles {
  handleFormSubmit: () => Promise<any>;
}

interface EvidencesDataProps {
  ref: any;
}

const situationOptions = [
  { id: '', name: 'Selecione uma opção', disabled: true },
  { id: 'RESOLVED', name: 'Sim' },
  { id: 'NOT_SOLVED', name: 'Não' },
  { id: 'PARTIALLY_RESOLVED', name: 'Parcial' }
];

export const EvidencesData = forwardRef<EvidencesDataHandles, EvidencesDataProps>((props, ref) => {
  const dispatch = useDispatch();

  const { uploadFiles } = useUploadFile();
  const { serviceOrder } = useServiceOrderFormContext();

  const FINISHED_ORDER = serviceOrder.status == 'COMPLETED' || serviceOrder.status == 'REPROVED';

  const [uploadedFiles, setUploadedFiles] = useState<any[]>(serviceOrder.evidence_attachments || []);

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: {
      resolved: findSelectedOption(situationOptions, serviceOrder.resolved) || { id: '', name: 'Selecione uma opção' },
      evidence_date: serviceOrder.evidence_date ? formatDate(serviceOrder.evidence_date) : '',
      evidence_observation: serviceOrder.evidence_observation
    },
    resolver: yupResolver(EvidencesSchema)
  });

  const watchResolved = useWatch({ control, name: 'resolved' });
  const watchEvidenceDate = useWatch({ control, name: 'evidence_date' });
  const watchEvidenceObservation = useWatch({ control, name: 'evidence_observation' });

  const uploadAttachments = async () => {
    let uploadedAttachments: any[] = [];

    if (uploadedFiles.length > 0) {
      try {
        uploadedAttachments = await uploadFiles(uploadedFiles);
      } catch (error) {
        dispatch(showSnackbarAlert({ message: 'Erro ao enviar anexos.', severity: 'error' }));
      }
    }

    return {
      uploadedAttachments
    };
  };

  const prepareSubmitData = async () => {
    const data = getValues();
    const { uploadedAttachments } = await uploadAttachments();

    let submitData = {
      ...data,
      id: serviceOrder.id,
      resolved: (data.resolved as SelectOption).id,
      client: (serviceOrder.client as Client).id,
      serial_number: serviceOrder.product.serial_number,
      evidence_date: data.evidence_date ? formatDateToApi(data.evidence_date) : null,
      evidence_observation: data.evidence_observation,
      evidence_attachments: uploadedAttachments
    };

    return submitData;
  };

  const onSubmit = async () => {
    if (!(await trigger())) {
      return false;
    } else {
      const submitData = await prepareSubmitData();
      return submitData;
    }
  };

  const handleUploadFiles = (files: any) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDeleteFile = (file: any) => {
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  useImperativeHandle(ref, () => ({
    handleFormSubmit: onSubmit
  }));

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Dados do Produto
          </Typography>
          <Typography variant="body2" gutterBottom>
            {serviceOrder.product.serial_number} - {serviceOrder.product.description}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Resolvido
          </Typography>
          <ControlledComboBox
            control={control}
            name="resolved"
            placeholder="Selecione uma opção"
            selectOptions={situationOptions}
            required
            errorMessage={errors.resolved?.message?.toString()}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Data da Evidência</InputLabel>
          <ControlledTextInput
            name="evidence_date"
            placeholder="dd/mm/aaaa"
            control={control}
            fullWidth
            mask="99/99/9999"
            errorMessage={errors.evidence_date?.message?.toString()}
            required
            disabled={FINISHED_ORDER}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Observações das evidências</InputLabel>
          <ControlledTextInput
            name="evidence_observation"
            placeholder="Observações"
            control={control}
            fullWidth
            minRows={4}
            errorMessage={errors.evidence_observation?.message?.toString()}
            required
            disabled={FINISHED_ORDER}
          />
        </Grid>

        {/* ANEXOS DAS EVIDENCIAS */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Anexos (Fotos, Vídeos e Arquivos)
          </Typography>
          <DropAttachmentThumbComponent
            uploadedFiles={uploadedFiles}
            onUploadFile={handleUploadFiles}
            onDeleteFile={handleDeleteFile}
            disabled={FINISHED_ORDER}
          />
        </Grid>
      </Grid>
    </>
  );
});
