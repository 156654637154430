import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { ClientsService } from '../../../services/Clients.service';
import { registerValidations } from '../../../utils/forms/validations/formValidations';
import { validateCnpj, validateCpf } from '../../../utils/utils';

interface ServiceOrderForm {
  cnpj: string;
  cpf: string;
  razao_social: string;
  nome_responsavel: string;
  email: string;
  telefone: string;
}

interface SubmitData {
  email: string;
  first_name: string;
  last_name: string;
  is_juridical: boolean;
  identification_number: string;
  company_name: string;
  phone: string;
  product_serial_number: string;
  product_quality_sequence: string;
}

interface Props {
  serviceOrderNumber: string;
  onBack?: () => void;
}

export const ServiceOrderForm = ({ serviceOrderNumber, onBack }: Props) => {
  const [formMessage, setFormMessage] = useState<{ severity: 'success' | 'error'; message: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [personType, setPersonType] = useState('J');

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ServiceOrderForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(registerValidations),
    defaultValues: {
      cnpj: '',
      cpf: '',
      razao_social: '',
      nome_responsavel: '',
      email: '',
      telefone: ''
    }
  });

  const handleChangePerson = (event: React.ChangeEvent<{ value: string }>) => {
    setPersonType(event.target.value as string);
  };

  const formValidations = (data: ServiceOrderForm) => {
    if (personType === 'F') {
      if (!data.cpf) {
        setError('cpf', { message: 'CPF é obrigatório!', type: 'manual' });
        return false;
      }
      if (!validateCpf(data.cpf)) {
        setError('cpf', { message: 'CPF inválido!', type: 'manual' });
        return false;
      }
    }

    if (personType === 'J') {
      if (!data.cnpj) {
        setError('cnpj', { message: 'CNPJ é obrigatório!', type: 'manual' });
        return false;
      }
      if (!validateCnpj(data.cnpj)) {
        setError('cnpj', { message: 'CNPJ inválido!', type: 'manual' });
        return false;
      }
    }

    if (data.nome_responsavel && data.nome_responsavel.split(' ').length < 2) {
      setFormMessage({
        severity: 'error',
        message: 'Nome do responsável deve conter nome e sobrenome!'
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (data: ServiceOrderForm) => {
    if (!formValidations(data)) return;
    setIsSubmitting(true);

    let firstName = data.nome_responsavel.split(' ')[0];
    let lastName = data.nome_responsavel.split(' ')[data.nome_responsavel.split(' ').length - 1];

    let SubmitObj: SubmitData = {
      email: data.email,
      first_name: firstName,
      last_name: lastName,
      is_juridical: personType === 'J',
      identification_number: data.cnpj || data.cpf,
      company_name: data.razao_social,
      phone: data.telefone,
      product_serial_number: serviceOrderNumber,
      product_quality_sequence: serviceOrderNumber
    };

    try {
      await ClientsService.create(SubmitObj);
      setFormMessage({
        severity: 'success',
        message: 'Cadastro realizado com sucesso! Após a aprovação você receberá um e-mail com os dados de acesso.'
      });
    } catch (error: any) {
      console.log('catch', error);
      setFormMessage({
        severity: 'error',
        message: error?.data?.serial_number || 'Houve um erro ao processar a sua solicitação'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {formMessage?.message && (
          <Grid item xs={12}>
            <Alert severity={formMessage.severity} variant="outlined" sx={{ width: '100%' }}>
              {formMessage.message}
            </Alert>
          </Grid>
        )}
        {formMessage?.severity != 'success' && (
          <>
            <Grid container spacing={2} overflow="visible">
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="personType">Pessoa</InputLabel>
                <Select name="personType" value={personType} fullWidth onChange={(event) => handleChangePerson(event)} size="small">
                  <MenuItem value="J">Jurídica</MenuItem>
                  <MenuItem value="F">Física</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="nome_responsavel">Nome do Responsável</InputLabel>
                <ControlledTextInput
                  placeholder="Nome do Responsável"
                  name="nome_responsavel"
                  control={control}
                  errorMessage={errors.nome_responsavel?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {personType === 'F' ? (
                  <>
                    <InputLabel htmlFor="cpf">CPF</InputLabel>
                    <ControlledTextInput
                      placeholder="CPF"
                      name="cpf"
                      control={control}
                      errorMessage={errors.cpf?.message}
                      mask="999.999.999-99"
                    />
                  </>
                ) : (
                  <>
                    <InputLabel htmlFor="cnpj">CNPJ</InputLabel>
                    <ControlledTextInput
                      placeholder="CNPJ"
                      name="cnpj"
                      control={control}
                      errorMessage={errors.cnpj?.message}
                      mask="99.999.999/9999-99"
                    />
                  </>
                )}
              </Grid>
              {personType === 'J' && (
                <Grid item xs={6}>
                  <InputLabel htmlFor="razao_social">Razão Social</InputLabel>
                  <ControlledTextInput
                    placeholder="Razão Social"
                    name="razao_social"
                    control={control}
                    errorMessage={errors.razao_social?.message}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <InputLabel htmlFor="email">E-mail</InputLabel>
                <ControlledTextInput placeholder="E-mail" name="email" control={control} errorMessage={errors.email?.message} />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="telefone">Telefone</InputLabel>
                <ControlledTextInput
                  placeholder="Telefone"
                  name="telefone"
                  control={control}
                  errorMessage={errors.telefone?.message}
                  mask={personType === 'F' ? '(99) 99999-9999' : '(99) 9999-9999'}
                />
              </Grid>
            </Grid>
            <Stack sx={{ display: 'flex', flexDirection: 'row', mt: 4 }} gap={2}>
              <Button disableElevation disabled={isSubmitting} fullWidth size="large" onClick={onBack} variant="outlined" color="primary">
                Voltar
              </Button>
              <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                Criar conta
              </Button>
            </Stack>
          </>
        )}
      </form>
    </>
  );
};
