import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import { StyledTableHead } from '../../../../../../../components/basics/StyledTableHead';
import { Expense } from '../../../../../../../models/Expense';
import { formatCurrency, hexToRgba } from '../../../../../../../utils/utils';

interface Props {
  expenses: Expense[];
}

export const ExpensesComparisonValuesTable = ({ expenses }: Props) => {
  const theme = useTheme();

  const returnBackgroundColor = (expense: Expense) => {
    return Number(expense?.final_quantity) > expense.quantity ||
      Number(expense?.final_cost) > Number(expense.cost) ||
      expense.final_quantity === 0
      ? hexToRgba(theme.palette.error.main, 0.2)
      : hexToRgba(theme.palette.success.main, 0.2);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <StyledTableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Quantidade Estimada</TableCell>
            <TableCell>Quantidade Real</TableCell>
            <TableCell>Valor Estimado</TableCell>
            <TableCell>Valor Real</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {expenses.map((expense) => (
            <TableRow key={expense.id} sx={{ backgroundColor: returnBackgroundColor(expense) }}>
              <TableCell>{expense.expense}</TableCell>
              <TableCell>{expense.description}</TableCell>
              <TableCell>{expense.quantity}</TableCell>
              <TableCell>{expense.final_quantity}</TableCell>
              <TableCell>{formatCurrency(expense.cost ?? 0)}</TableCell>
              <TableCell>{formatCurrency(expense.final_cost ?? 0)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
