import { TableHead, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { StyledTableCell } from '../../../components/basics/StyledTableCell';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { StyledTableCellHeader } from '../../../components/basics/TableHeaderComponent/styles';
import { ApiResults } from '../../../models/ApiResults';
import { formatServiceOrderNumber, ServiceOrder } from '../../../models/ServiceOrder';
import { Technical } from '../../../models/Technical';
import { PaymentAuthorizationService } from '../../../services/PaymentAuthorization.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { formatCurrency, formatSimpleDate } from '../../../utils/utils';
import { usePaymentAuthorizationPageContext } from '../context/PaymentAuthorizationPageContext';

export interface TechnicalOrder extends Technical {
  work_orders: ServiceOrder[];
}

export interface WorkServiceOrderResult extends ApiResults {
  results: ServiceOrder[];
}

interface Props {
  updateSelectedItem: (item: string) => void;
  saved?: number;
}

export const PaymentAuthorizationTable = ({ updateSelectedItem, saved }: Props) => {
  const [selected, setSelected] = useState<string[]>([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [serviceOrders, setServiceOrders] = React.useState<WorkServiceOrderResult>();
  const [orderedServiceOrders, setOrderedServiceOrders] = useState<TechnicalOrder[]>([]);

  const { filterFields } = usePaymentAuthorizationPageContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [id];
    } else if (selectedIndex === 0) {
      newSelected = [];
    }
    updateSelectedItem(id);
    setSelected(newSelected);
  };

  const groupOrdersByTechnical = () => {
    const groupedOrders = {};
    if (serviceOrders) {
      serviceOrders.forEach((order) => {
        const { technical } = order;
        if (!groupedOrders[technical.username]) {
          groupedOrders[technical.username] = {
            ...technical,
            work_orders: [order]
          };
        } else {
          groupedOrders[technical.username].work_orders.push(order);
        }
      });
      const groupedOrdersArray = Object.values(groupedOrders);
      setOrderedServiceOrders(groupedOrdersArray);

      return groupedOrdersArray;
    } else {
      return [];
    }
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;
  const fetchServiceOrders = async () => {
    dispatch(showSpinner());
    try {
      const response = await PaymentAuthorizationService.get({
        ...filterFields,
        is_assistance: 1,
        status: 'COMPLETED',
        payment_authorized: false
      });
      if (response && checkResponseStatus(response)) {
        const result = response?.data?.results;
        setServiceOrders(result);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    if (serviceOrders) {
      groupOrdersByTechnical();
    }
  }, [serviceOrders]);

  useEffect(() => {
    (async () => {
      dispatch(showSpinner());
      await fetchServiceOrders();
      dispatch(hideSpinner());
    })();
  }, [saved, filterFields]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <TableHead>
              <TableRow>
                <StyledTableCellHeader>Assistência</StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderedServiceOrders && orderedServiceOrders.length > 0 ? (
                <>
                  {orderedServiceOrders.map((technical, index) => (
                    <React.Fragment key={index}>
                      <StyledTableRow sx={{ bgcolor: theme.palette.grey[300] }}>
                        <StyledTableCell align="left" colSpan={6}>
                          {technical.first_name} {technical.last_name}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow sx={{ bgcolor: theme.palette.grey[100] }}>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell align="center">
                          <strong>Ordem</strong>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <strong>Data da Conclusão</strong>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <strong>Situação</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <strong>
                            Valor
                            <br />
                            <span style={{ fontSize: '0.8em' }}>(Materias Revenda + Despesas)</span>
                          </strong>
                        </StyledTableCell>
                      </StyledTableRow>
                      {technical.work_orders.map((serviceOrder, indexOrder) => (
                        <StyledTableRow key={indexOrder}>
                          <StyledTableCell padding="checkbox" align="left">
                            <Checkbox
                              color="primary"
                              checked={isSelected(serviceOrder.id.toString())}
                              onChange={(event) => handleChange(event, serviceOrder.id.toString())}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">{formatServiceOrderNumber(serviceOrder.id)}</StyledTableCell>
                          <StyledTableCell align="left">{formatSimpleDate(serviceOrder.conclusion_date)}</StyledTableCell>
                          <StyledTableCell align="left">{capitalize(serviceOrder.status_label)}</StyledTableCell>
                          <StyledTableCell align="center">{formatCurrency(serviceOrder.total_expenses)}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={8}>
                    Nenhum registro encontrado
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
