import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Expense } from '../../../models/Expense';
import { ExpensesService } from '../../../services/Expenses.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { formatCurrencyToNumber, setInputErrorsFromApi } from '../../../utils/utils';
import { useExpensesPageContext } from '../context/ExpensesPageContext';

interface Props {
  editItem?: Expense;
  onClose: () => void;
}

interface FormFields {
  id: number;
  description: string;
  multiplier: number;
  is_active: boolean;
}

export const ExpensesFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { setShouldRefresh } = useExpensesPageContext();

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: editItem?.id ?? 0,
      description: editItem?.description ?? '',
      multiplier: editItem?.multiplier ?? 0,
      is_active: editItem?.is_active ?? true
    }
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const createOrUpdateExpense = async (data: FormFields) => {
    let submitData = {
      ...data,
      multiplier: formatCurrencyToNumber(data.multiplier.toString())
    };

    dispatch(showSpinner());
    try {
      editItem?.id ? await ExpensesService.update<FormFields>(submitData) : await ExpensesService.create<FormFields>(submitData);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Despesa atualizada com sucesso!' : 'Despesa criada com sucesso!',
          severity: 'success'
        })
      );
      setShouldRefresh(true);
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Despesa
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateExpense)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Insira a descrição da despesa"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="multiplier">Multiplicador</InputLabel>
              <ControlledTextInput
                currency
                name="multiplier"
                placeholder="Insira o multiplicador da despesa"
                control={control}
                errorMessage={errors.multiplier?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateExpense)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
