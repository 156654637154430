import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Defect } from '../../../models/Defect';
import { DefectsGroupsService } from '../../../services/DefectsGroups.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface DefectsGroupsFilterFields extends Defect {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface DefectsGroupsResult extends ApiResults {
  results: Defect[];
}

interface DefectsGroupsContextProps {
  defects: DefectsGroupsResult | undefined;
  fetchDefectsGroups: () => Promise<any>;
  deleteDefect: (id: number) => Promise<void>;
  deleteManyDefectsGroups: (items: string[]) => Promise<void>;
  filterFields: DefectsGroupsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<DefectsGroupsFilterFields>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const DefectsGroupsContext = createContext<DefectsGroupsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const DefectsGroupsPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();

  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [defectsGroups, setDefectsGroups] = React.useState<DefectsGroupsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<DefectsGroupsFilterFields>({} as DefectsGroupsFilterFields);

  const fetchDefectsGroups = async () => {
    dispatch(showSpinner());
    try {
      const response = await DefectsGroupsService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setDefectsGroups(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteDefect = async (id: number) => {
    dispatch(showSpinner());
    try {
      await DefectsGroupsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchDefectsGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyDefectsGroups = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await DefectsGroupsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchDefectsGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchDefectsGroups();
  }, [filterFields, page, rowsPerPage]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchDefectsGroups();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const contextValue: DefectsGroupsContextProps = {
    defects: defectsGroups,
    fetchDefectsGroups,
    deleteDefect,
    deleteManyDefectsGroups,
    filterFields,
    setFilterFields,
    setShouldRefresh,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <DefectsGroupsContext.Provider value={contextValue}>{children}</DefectsGroupsContext.Provider>;
};

export const useDefectsGroupsPageContext = () => {
  const context = useContext(DefectsGroupsContext);
  if (!context) {
    throw new Error('useDefectsGroupsContext must be used within a DefectsGroupsProvider');
  }
  return context;
};
