import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Defect } from '../../../models/Defect';
import { DefectLocation } from '../../../models/DefectLocation';
import { DefectsService } from '../../../services/Defects.service';
import { DefectsGroupsService } from '../../../services/DefectsGroups.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../store/store';
import { checkResponseStatus } from '../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useDefectsGroupsPageContext } from '../context/DefectsGroupsPageContext';

interface Props {
  editItem?: Defect;
  onClose: () => void;
}

export const DefectsGroupsFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state: RootState) => state.globalSpinner);
  const { fetchDefectsGroups } = useDefectsGroupsPageContext();

  const [defectsOptions, setDefectsOptions] = useState<SelectOption[]>([]);

  const getDefaultValues = (): DefectLocation => {
    return {
      id: editItem?.id ?? 0,
      description: editItem?.description ?? '',
      is_active: editItem?.is_active ?? true,
      defects: editItem?.defects ? editItem?.defects.map((defect) => ({ id: defect.id, name: defect.description })) : []
    };
  };

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<Defect>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues()
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const createOrUpdateDefect = async (data: DefectLocation) => {
    let formSubmit = getValues();
    formSubmit.defects = formSubmit.defects.map((defect) => defect.id) as Number[];

    dispatch(showSpinner());
    try {
      editItem?.id ? await DefectsGroupsService.update(formSubmit) : await DefectsGroupsService.create(formSubmit);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Defeito atualizado com sucesso!' : 'Defeito criado com sucesso!',
          severity: 'success'
        })
      );
      fetchDefectsGroups();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  const fetchDefects = async () => {
    dispatch(showSpinner());
    try {
      const response = await DefectsService.getAll(true);
      if (checkResponseStatus(response)) {
        setDefectsOptions(response?.data.results.map((defect: Defect) => ({ id: defect.id, name: defect.description })));
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchDefects();
  }, []);

  if (loading) return <></>;

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Grupo de Defeitos
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateDefect)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="status">Nome do Grupo</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Digite o nome do grupo de defeitos"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="status">Defeitos</InputLabel>
              <ControlledComboBox
                name="defects"
                placeholder="Selecione os defeitos do grupo"
                control={control}
                selectOptions={defectsOptions}
                multiple
                errorMessage={errors.defects?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="status">Situação</InputLabel>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateDefect)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
