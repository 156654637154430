import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Client } from '../../../models/Client';
import { ClientsFilterFields, useClientsPageContext } from '../context/ClientsPageContext';
interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: Client): ClientsFilterFields => {
  return {
    company_name: filter?.company_name ?? '',
    identification_number: filter?.identification_number ?? '',
    username: filter?.username ?? '',
    email: filter?.email ?? ''
  };
};

export const ClientsFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useClientsPageContext();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ClientsFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<ClientsFilterFields> = async (data: ClientsFilterFields) => {
    setFilterFields({ ...data } as ClientsFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ClientsFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary.main">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <ControlledTextInput name="email" placeholder="E-mail" control={control} errorMessage={errors.email?.message?.toString()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="first_name">Nome</InputLabel>
              <ControlledTextInput
                name="first_name"
                placeholder="Nome"
                control={control}
                errorMessage={errors.first_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="last_name">Sobrenome</InputLabel>
              <ControlledTextInput
                name="last_name"
                placeholder="Sobrenome"
                control={control}
                errorMessage={errors.last_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company_name">Nome Empresa</InputLabel>
              <ControlledTextInput
                placeholder="Nome Empresa"
                name="company_name"
                control={control}
                errorMessage={errors.company_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="identification_number">CPF/CNPJ</InputLabel>
              <ControlledTextInput
                placeholder="CPF/CNPJ"
                name="identification_number"
                control={control}
                errorMessage={errors.identification_number?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
