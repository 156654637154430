import { ProductsPageContext } from '../../../sections/@products-page/context/ProductsPageContext';
import { ProductsPage } from '../../../sections/@products-page/ProductsPage';

export const ProductsPageWrapper = () => {
  return (
    <ProductsPageContext>
      <ProductsPage />
    </ProductsPageContext>
  );
};
