import * as yup from 'yup';
import { isValidDate } from '../../utils';

export const loginValidations = yup.object().shape({
  username: yup.string().required('Usuário é obrigatório'),
  password: yup.string().required('Senha é obrigatória')
});

export const registerValidations = yup.object().shape({
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  nome_responsavel: yup.string().required('Nome do responsável é obrigatório'),
  telefone: yup.string().required('Telefone é obrigatório')
});

export const CompanySchema = yup.object().shape({
  company_name: yup.string().required('Nome é obrigatório'),
  trade_name: yup.string().required('Nome fantasia é obrigatório')
});

export const DefectSchema = yup.object().shape({
  description: yup.string().required('Descrição é obrigatória')
});

export const ProductSchema = yup.object().shape({
  serial_number: yup.string().required('Número de série é obrigatório'),
  quality_sequence: yup.string().required('Sequência de qualidade é obrigatória')
});

export const UserSchema = yup.object().shape({
  username: yup.string().required('Usuário é obrigatório'),
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  first_name: yup.string().required('Nome é obrigatório'),
  last_name: yup.string().required('Sobrenome é obrigatório'),
  type: yup.object().required('Tipo é obrigatório').nullable()
});

export const ServiceOrderReprovedSchema = yup.object().shape({
  reason: yup.string().required('O motivo de reprovação é obrigatório')
});

export const ServiceOrderRegistrationSchema = yup.object().shape({});

export const TransferSchema = yup.object().shape({
  technician: yup.object().required('O técnico é obrigatório').nullable(),
  reason: yup.string().required('A descrição é obrigatória')
});

export const TechnicalAnalysisSchema = yup.object().shape({
  service_type: yup.object().required('O tipo é obrigatório').nullable(),
  date: yup
    .string()
    .test('isValidDate', 'Data de análise inválida', (value) => isValidDate(value))
    .required('Data de análise obrigatória'),
  description: yup.string().required('A descrição é obrigatória'),
  support_address: yup.string().when('use_client_address', {
    is: false,
    then: yup.string().required('O endereço é obrigatório')
  })
});

export const EvidencesSchema = yup.object().shape({
  evidence_date: yup.string().required('A data da evidência é obrigatória'),
  evidence_observation: yup.string().required('A descrição é obrigatória'),
  resolved: yup
    .object()
    .test('not-empty', 'A situação é obrigatória', function (value) {
      if (!value || !value.id || value.id === '') {
        return this.createError({ message: 'A situação é obrigatória', path: this.path });
      }
      return true;
    })
    .nullable()
});

export const ConclusionSchema = yup.object().shape({
  conclusion_date: yup.string().required('A data de conclusão é obrigatória'),
  conclusion_description: yup.string().required('A descrição é obrigatória')
});
