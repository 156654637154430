import { Box, Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import AuthBackground1 from '../../../assets/images/media/banner_1.jpg';
import { ServiceOrderCard } from './ServiceOrderCard';

interface ServiceOrderWrapperProps {
  children: ReactNode;
}

export const ServiceOrderWrapper: React.FC<ServiceOrderWrapperProps> = ({ children }) => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <img
        src={AuthBackground1}
        alt="AuthBackground"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
          transition: 'opacity 0.5s ease-in-out'
        }}
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{
          minHeight: '100vh'
        }}
      >
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
            <Grid item>
              <ServiceOrderCard>{children}</ServiceOrderCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
