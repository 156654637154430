import { lazy } from 'react';
import { Loadable } from '../components/basics/LazyLoaderComponent/Loadable';
import { MinimalLayout } from '../layout/MinimalLayout';
import { AccountConfirmationPage } from '../pages/public/AuthenticationPages/AccountConfirmationPage';
import { ServiceOrderPage } from '../pages/public/ServiceOrderPage';
import { ROUTES } from './routes';

const AuthLogin = Loadable(lazy(() => import('../pages/public/AuthenticationPages/LoginPage')));
const AuthRegister = Loadable(lazy(() => import('../pages/public/AuthenticationPages/RegisterPage')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  // element: <MinimalLayout />,
  children: [
    {
      path: ROUTES.public.login,
      element: <AuthLogin />
    },
    {
      path: ROUTES.public.signUp,
      element: <AuthRegister />
    },
    {
      path: `${ROUTES.public.accountConfirmation}/:token`,
      element: <AccountConfirmationPage />
    },
    {
      path: ROUTES.public.serviceOrder,
      element: <ServiceOrderPage />
    }
  ]
};

export default LoginRoutes;
