import { UsersPage } from '../../../sections/@users-page/UsersPage';
import { UsersPageContextProvider } from '../../../sections/@users-page/context/UsersPageContext';

export const UsersPageWrapper = () => {
  return (
    <UsersPageContextProvider>
      <UsersPage />
    </UsersPageContextProvider>
  );
};
