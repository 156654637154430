import { Box } from '@mui/material';
import { ServiceOrder } from '../../../../../models/ServiceOrder';
import { AnalysisHistoryCard } from './AnalysisHistoryCard';

interface DefectsList {
  location: string;
  defects_groups_relationships: number[];
}

interface FormFields {
  support_address: string;
  use_client_address: boolean;
  work_order: number;
  attachments: number[];
  service_type: 'WARRANTY' | 'ASSISTANCE' | 'TECHNICAL_DELIVERY';
  analysis_date: string;
  description: string;
  defects: {
    location: string;
    defects_groups_relationships: number[];
  }[];
}

interface Props {
  serviceOrder: ServiceOrder;
}

export const TechnicalAnalysisTab = ({ serviceOrder }: Props) => {
  return (
    <Box p={2} m={2}>
      <AnalysisHistoryCard serviceOrder={serviceOrder} />
    </Box>
  );
};
