import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { FinancialRegistry } from '../models/FinancialRegistry';
import { PayServiceOrderFilterFields } from '../sections/@pay-service-orders/context/PayServiceOrderPageContext';

const FinancialRegistryUrl = '/financial/financial-registries/';

export const FinancialRegistryService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(FinancialRegistryUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: PayServiceOrderFilterFields, page: number = 0, page_size: number = 1000) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(FinancialRegistryUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: FinancialRegistry) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(FinancialRegistryUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  partialUpdate: async (id: number, data: Partial<FinancialRegistry>) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.patch(`${FinancialRegistryUrl}${id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${FinancialRegistryUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
