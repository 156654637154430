import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissionsHook';
import { Defect } from '../../../models/Defect';
import { DefectsGroupsDetailsDialog } from '../DefectsGroupsDetailsDialog';
import { DefectsGroupsFilterDialog } from '../DefectsGroupsFilterDialog';
import { DefectsGroupsFormDialog } from '../DefectsGroupsFormDialog';
import { DefectsGroupsTable } from '../DefectsGroupsTable';
import { useDefectsGroupsPageContext } from '../context/DefectsGroupsPageContext';
import { ButtonsContainer } from './styles';

export const DefectsGroupsPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'DEFECT_GROUP'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<Defect | undefined>(undefined);
  const [viewItem, setViewItem] = useState<Defect | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields, fetchDefectsGroups } = useDefectsGroupsPageContext();

  const handleAddDefect = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: Defect) => {
    setViewItem(item);
  };

  const handleEditItem = (item: Defect) => {
    setEditItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setViewItem(undefined);
  };

  const handleCloseFormDialog = () => {
    setNewItem(false);
    setEditItem(undefined);
    fetchDefectsGroups();
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 1000);
      debounced();
      return debounced.cancel;
    } else {
      setFilterFields({
        ...filterFields,
        search: undefined
      });
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Box mr={1}>
              <ControlledTextInput
                control={control}
                name="search"
                label="Buscar Grupos"
                placeholder="Digite para Pesquisar"
                sx={{ mr: 1 }}
                type="search"
              />
            </Box>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Defeito">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddDefect}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <DefectsGroupsTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} />
      {showFilter && <DefectsGroupsFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <DefectsGroupsDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && <DefectsGroupsFormDialog editItem={editItem} onClose={handleCloseFormDialog} />}
    </>
  );
};
