import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FinancialRegistry } from '../../../models/FinancialRegistry';
import { formatCurrency } from '../../../utils/utils';
import { ExpensesComparisonValuesTable } from '../../@service-order-page/ServiceOrderDetailsDialog/TabsContent/ConclusionTab/components/ExpensesComparisonValuesTable';
import { MaterialsComparisonValuesTable } from '../../@service-order-page/ServiceOrderDetailsDialog/TabsContent/ConclusionTab/components/MaterialsComparisonValuesTable';

interface Props {
  item: FinancialRegistry;
  onClose: () => void;
}

export const PayServiceOrderPreviewDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes Pagamento da Ordem
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {item.resale_materials.length > 0 ? (
              <>
                <Typography variant="h5" gutterBottom>
                  Materiais Revenda
                </Typography>
                <MaterialsComparisonValuesTable materials={item.resale_materials} />
              </>
            ) : (
              <>
                <Typography variant="h5" gutterBottom>
                  Não há materiais Revenda
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {item.expenses.length > 0 ? (
              <>
                <Typography variant="h5" gutterBottom>
                  Despesas
                </Typography>
                <ExpensesComparisonValuesTable expenses={item.expenses} />
              </>
            ) : (
              <>
                <Typography variant="h5" gutterBottom>
                  Não há despesas
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Total: {formatCurrency(item.total)}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
