import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const TechnicalAnalysesUrl = '/work-orders/technical-analyses/';

export const ServiceOrderTechnicalAnalysesService = {
  getByServiceOrderId: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(TechnicalAnalysesUrl, {
        params: {
          work_order: id
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async <Type>(data: Type) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(TechnicalAnalysesUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async <Type>(id: number, data: Type) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${TechnicalAnalysesUrl}${id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
