import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

export const FinancialRoutes: INavGroup = {
  id: 'group-financial',
  title: 'Financeiro',
  icon: <WalletOutlinedIcon />,
  type: 'group',
  module: 'FINANCIAL',
  level: 1,
  children: [
    {
      id: 'group-payment-authorization',
      title: 'Autorizar Pagamento de Ordens',
      type: 'item',
      url: ROUTES.authenticated.financial.paymentAutorization,
      icon: <CreditScoreOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'PAYMENT_ATHORIZATION'
    },
    {
      id: 'group-pay-service-order',
      title: 'Pagar Ordens de Serviço',
      type: 'item',
      url: ROUTES.authenticated.financial.payServiceOrder,
      icon: <PaymentOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'FINANCIAL_REGISTRY'
    }
  ]
};
