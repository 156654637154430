import BuildIcon from '@mui/icons-material/Build';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

export const AuxiliaryRoutes: INavGroup = {
  id: 'group-auxiliary',
  title: 'Auxiliares',
  icon: <BuildIcon />,
  type: 'group',
  module: 'AUXILIARY',
  level: 1,
  children: [
    {
      id: 'group-permissions',
      title: 'Grupos Permissões',
      type: 'item',
      url: ROUTES.authenticated.auxiliary.permissionGroups,
      icon: <ManageAccountsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'GROUP'
    },
    {
      id: 'client-activation',
      title: 'Ativação Clientes',
      type: 'item',
      url: ROUTES.authenticated.auxiliary.customerActivation,
      icon: <ContactMailOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'CLIENT_ACTIVATION'
    },
    {
      id: 'parameters',
      title: 'Parâmetros',
      type: 'item',
      url: ROUTES.authenticated.auxiliary.parameters,
      icon: <DisplaySettingsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'PARAMETER'
    }
  ]
};
