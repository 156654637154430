import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const RegistersRoutes: INavGroup = {
  id: 'group-registers',
  title: 'Cadastros',
  icon: <AppRegistrationIcon />,
  type: 'group',
  module: 'REGISTER',
  level: 1,
  children: [
    {
      id: 'companies',
      title: 'Empresas',
      type: 'item',
      url: ROUTES.authenticated.registers.companies,
      icon: <FactoryOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'COMPANY' // Módulo para verificar permissão
    },
    {
      id: 'users',
      title: 'Usuários',
      type: 'item',
      url: ROUTES.authenticated.registers.users,
      icon: <ManageAccountsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'USER' // Módulo para verificar permissão
    },
    {
      id: 'products',
      title: 'Vínculo Produtos',
      type: 'item',
      url: ROUTES.authenticated.registers.products,
      icon: <AgricultureOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'PRODUCT' // Módulo para verificar permissão
    },
    {
      id: 'manuals',
      title: 'Manuais',
      type: 'item',
      url: ROUTES.authenticated.registers.manuals,
      icon: <ArticleOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'MANUAL'
    }
  ]
};
