import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Defect } from '../../../models/Defect';
import { DefectsService } from '../../../services/Defects.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useDefectsPageContext } from '../context/DefectsPageContext';

interface Props {
  editItem?: Defect;
  onClose: () => void;
}

export const DefectsFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchDefects } = useDefectsPageContext();

  const getDefaultValues = (): Partial<Defect> => {
    return {
      id: editItem?.id ?? 0,
      description: editItem?.description ?? '',
      is_active: editItem?.is_active ?? true
    };
  };

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<Defect>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues()
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const createOrUpdateDefect: SubmitHandler<Defect> = async (data: Defect) => {
    let formSubmit = getValues();

    dispatch(showSpinner());
    try {
      editItem?.id ? await DefectsService.update(formSubmit) : await DefectsService.create(formSubmit);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Defeito atualizado com sucesso!' : 'Defeito criado com sucesso!',
          severity: 'success'
        })
      );
      fetchDefects();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Defeito
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateDefect)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="status">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Informe o nome do defeito"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="status">Situação</InputLabel>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateDefect)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
