import { Alert, Divider, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogHeader } from '../../../components/basics/DialogHeader';
import { Client } from '../../../models/Client';

interface Props {
  item: Client;
  onClose: () => void;
}

export const ClientsDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogHeader title="Detalhes do cliente" onClose={onClose} />
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Nome Usuário:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.username}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Email:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Situação:
            </Typography>
            <>{item.is_active ? <Alert severity="success">Ativo</Alert> : <Alert severity="error">Inativo</Alert>}</>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
