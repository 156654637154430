import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, IconButton, Snackbar, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../store/store';

export const SnackbarAlertComponent = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { snackbarData } = useSelector((state: RootState) => state.snackbarAlert);

  let MESSAGE =
    typeof snackbarData.message === 'string'
      ? snackbarData.message
      : typeof snackbarData.message === 'object'
      ? JSON.stringify(snackbarData.message)
      : '';

  const handleClose = () => {
    dispatch(hideSnackbarAlert());
  };

  const renderAction = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar open={snackbarData.open} autoHideDuration={snackbarData.duration} onClose={handleClose}>
      <Alert
        severity={snackbarData.severity}
        action={renderAction}
        sx={{
          border: 'solid',
          borderWidth: '0.5px',
          borderColor: theme.palette.secondary.light
        }}
      >
        <AlertTitle>{snackbarData.title}</AlertTitle>
        <Typography variant="body1">{MESSAGE}</Typography>
      </Alert>
    </Snackbar>
  );
};
