import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Client } from '../../../models/Client';
import { Product } from '../../../models/Product';
import { ClientsService } from '../../../services/Clients.service';
import { ErpProductsService } from '../../../services/ErpProducts.service';
import { ProductsService } from '../../../services/Products.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { ProductSchema } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useProductsPageContext } from '../context/ProductsPageContext';

interface Props {
  editItem?: Product;
  onClose: () => void;
}

export const ProductsFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchProducts } = useProductsPageContext();

  const [clientsOptions, setClientsOptions] = useState<SelectOption[]>([]);
  const [selectedClient, setSelectedClient] = useState<SelectOption>({} as SelectOption);

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    setValue,
    formState: { errors }
  } = useForm<Partial<Product>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ProductSchema),
    defaultValues: editItem ?? {
      client: {} as Client,
      serial_number: '',
      product_name: '',
      description: '',
      quality_sequence: ''
    }
  });

  const watchSerialNumber = useWatch({
    control,
    name: 'serial_number',
    defaultValue: editItem?.serial_number ?? ''
  });

  const formValidations = () => {
    if (!selectedClient?.id) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Selecione um cliente',
          severity: 'error'
        })
      );
      return false;
    }

    return true;
  };

  const createOrUpdateProduct = async (data: Partial<Product>) => {
    dispatch(showSpinner());

    if (!formValidations()) return;

    data.client = (selectedClient as SelectOption)?.id;
    try {
      editItem?.id ? await ProductsService.update(data) : await ProductsService.create(data);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Produto atualizado com sucesso!' : 'Produto criado com sucesso!',
          severity: 'success'
        })
      );
      fetchProducts();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectClient = (value: SelectOption) => {
    setSelectedClient(value);
  };

  const handleClose = () => {
    onClose();
  };

  const fetchProduct = async () => {
    dispatch(showSpinner());
    try {
      const response = await ErpProductsService.getBySerialNumber(String(watchSerialNumber));
      if (response && checkResponseStatus(response) && response.data?.results?.length > 0) {
        setValue('product_name', response.data?.results?.[0]?.description);
      }
    } catch (error: any) {
      console.log('error: ', error.data);
      const formError = setInputErrorsFromApi(setError, error.data);
      if (formError) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError,
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchClients = async (watchClientSearch?: string) => {
    try {
      const response = await ClientsService.getWithErp({
        search: watchClientSearch
      });
      if (checkResponseStatus(response)) {
        setClientsOptions(
          response?.data.results.map((client: Client) => ({
            id: client.id,
            name: client.first_name ? `${client.first_name} ${client.last_name}` : client.company_name,
            from_erp: client.from_erp
          }))
        );
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar clientes', severity: 'error' }));
    }
  };

  useEffect(() => {
    if (editItem?.client) {
      const client = editItem.client as Client;
      setSelectedClient({ id: client.id, name: client.company_name });
      setClientsOptions([{ id: client.id, name: client.company_name }]);
    }
  }, [editItem]);

  useEffect(() => {
    if (watchSerialNumber) {
      const debounced = debounce(async () => {
        await fetchProduct();
      }, 1000);
      debounced();
      return debounced.cancel;
    }
  }, [watchSerialNumber]);

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Produto
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateProduct)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="client">Cliente</InputLabel>
              <AutocompleteSearch
                value={selectedClient}
                defaultValue={editItem?.client ? { id: editItem.client, name: editItem.company_name } : undefined}
                onSearch={fetchClients}
                selectOptions={clientsOptions}
                onSelect={(event, value: SelectOption) => handleSelectClient(value)}
                errorMessage={errors.client?.id?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="serial_number">Número de Série</InputLabel>
              <ControlledTextInput
                name="serial_number"
                placeholder="Número de Série"
                control={control}
                errorMessage={errors.serial_number?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="product_name">Nome do Produto</InputLabel>
              <ControlledTextInput
                name="product_name"
                placeholder="Nome do Produto"
                control={control}
                errorMessage={errors.description?.message?.toString()}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="quality_sequence">Sequência de Qualidade</InputLabel>
              <ControlledTextInput
                name="quality_sequence"
                placeholder="Sequência de Qualidade"
                control={control}
                errorMessage={errors.quality_sequence?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateProduct)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
