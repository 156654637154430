import { Grid, InputLabel, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlledComboBox } from '../../../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../../../components/basics/ControlledTextInput';
import { DropAttachmentComponent } from '../../../../../../../components/basics/DropAttachmentComponent';
import { useDownloadFileHook } from '../../../../../../../hooks/useDownloadFileHook';
import { ServiceOrder } from '../../../../../../../models/ServiceOrder';
import { findSelectedOption, formatDate } from '../../../../../../../utils/utils';

export interface EvidencesDataHandles {
  handleFormSubmit: () => Promise<any>;
}

interface Props {
  serviceOrder: ServiceOrder;
}

const situationOptions = [
  { id: 'RESOLVED', name: 'Sim' },
  { id: 'NOT_SOLVED', name: 'Não' },
  { id: 'PARTIALLY_RESOLVED', name: 'Parcial' }
];

export const EvidencesData = ({ serviceOrder }: Props) => {
  const { downloadFile } = useDownloadFileHook();
  const FINISHED_ORDER = serviceOrder.status == 'COMPLETED' || serviceOrder.status == 'REPROVED';

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: {
      resolved: findSelectedOption(situationOptions, serviceOrder.resolved),
      evidence_date: serviceOrder.evidence_date ? formatDate(serviceOrder.evidence_date) : '',
      evidence_observation: serviceOrder.evidence_observation
    }
  });

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Dados do Produto
          </Typography>
          <Typography variant="body2" gutterBottom>
            {serviceOrder.product.serial_number} - {serviceOrder.product.description}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Resolvido
          </Typography>
          <ControlledComboBox
            control={control}
            name="resolved"
            placeholder="Selecione uma opção"
            selectOptions={situationOptions}
            required
            errorMessage={errors.resolved?.message?.toString()}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Data da Evidência</InputLabel>
          <ControlledTextInput
            name="evidence_date"
            placeholder="dd/mm/aaaa"
            control={control}
            fullWidth
            mask="99/99/9999"
            errorMessage={errors.evidence_date?.message?.toString()}
            required
            disabled={FINISHED_ORDER}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Observações das evidências</InputLabel>
          <ControlledTextInput
            name="evidence_observation"
            placeholder="Observação"
            control={control}
            fullWidth
            minRows={4}
            errorMessage={errors.evidence_observation?.message?.toString()}
            required
            disabled={FINISHED_ORDER}
          />
        </Grid>

        {/* ANEXOS DAS EVIDENCIAS */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Anexos (Fotos, Vídeos e Arquivos)
          </Typography>
          {serviceOrder.evidence_attachments && serviceOrder.evidence_attachments.length > 0 ? (
            <DropAttachmentComponent uploadedFiles={serviceOrder.evidence_attachments || []} disabled />
          ) : (
            <Typography variant="body1" gutterBottom>
              Nenhum anexo disponível
            </Typography>
          )}
        </Grid>

        {/* ASSINATURA DAS EVIDÊNCIAS 
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Assinatura
          </Typography>
          <>
            {serviceOrder.signature && (
              <Stack direction="row" columnGap={2} alignItems="flex-end">
                <Typography variant="body1" gutterBottom>
                  Assinatura realizada em {formatDate(serviceOrder.signature.created_at)}
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  startIcon={<Download />}
                  onClick={() => downloadFile(serviceOrder.signature)}
                >
                  Baixar
                </Button>
              </Stack>
            )}
          </>
        </Grid> */}
      </Grid>
    </>
  );
};
