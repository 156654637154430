import { ClientsPage } from '../../../sections/@clients-page/ClientsPage';
import { ClientsPageContextProvider } from '../../../sections/@clients-page/context/ClientsPageContext';

export const ClientsPageWrapper = () => {
  return (
    <ClientsPageContextProvider>
      <ClientsPage />
    </ClientsPageContextProvider>
  );
};
