import HomeIcon from '@mui/icons-material/Home';
import { INavGroup } from '../models/Menu';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const DashboardRoutes: INavGroup = {
  id: 'group-dashboard',
  title: 'Dashboard',
  icon: <HomeIcon />,
  type: 'item',
  url: '/inicio',
  level: 1
};
