import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox } from '../../../../../components/basics/ControlledComboBox';
import { PermissionModule } from '../../../../../models/Permission';
import { User } from '../../../../../models/User';
import { UserPermission } from '../../../../../models/UserPermission';
import { PermissionsService } from '../../../../../services/Permissions.service';
import { UserPermissionsService } from '../../../../../services/UserPermissions.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { hexToRgba } from '../../../../../utils/utils';

interface Props {
  user: User;
  onClose: () => void;
}

export const ModulePermissionsTab = ({ user, onClose }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(true);

  const [userPermissionData, setUserPermissionData] = useState<UserPermission>({});
  const [permissionStructure, setPermissionStructure] = useState<PermissionModule[]>([]);

  const { control, handleSubmit, reset, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const watchModule = useWatch({
    control,
    name: 'permissionModule'
  });

  const onSubmit = async (data: any) => {
    let permissionsIds: number[] = [];

    //se o módulo no index tiver permissão full, então tem que ler todos os submódulos e permissões e guardar os ids das permissões
    data.permissionModule.forEach((permissionModule: any, index: number) => {
      if (permissionModule?.type?.id === 'full') {
        permissionStructure[index].submodules.forEach((submodule) => {
          submodule.permissions.forEach((permission) => {
            permissionsIds.push(permission.id!);
          });
        });
      }
    });

    //se o módulo tiver permissão specific, então tem que ler os submódulos e permissões selecionados e guardar os ids das permissões
    data.permissionModule.forEach((permissionModule: any) => {
      if (permissionModule?.type?.id === 'specific') {
        permissionModule.submodule.forEach((submodule: any) => {
          submodule.forEach((permission: any) => {
            permissionsIds.push(permission.id);
          });
        });
      }
    });

    try {
      const response = await UserPermissionsService.update({
        id: userPermissionData.id,
        user: (userPermissionData.user as User).id?.toString(),
        permissions: permissionsIds
      });
      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Permissões do usuário atualizadas com sucesso',
            severity: 'success'
          })
        );
        onClose();
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao atualizar permissões do usuário',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchUserPermissions = async () => {
    dispatch(showSpinner());
    try {
      const response = await UserPermissionsService.get({
        user: user?.id?.toString()
      });
      if (checkResponseStatus(response)) {
        let userPermission = response?.data.results?.[0];
        setUserPermissionData(userPermission);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchPermissionsStructure = async () => {
    try {
      dispatch(showSpinner());
      const response = await PermissionsService.get();
      if (response?.data?.results) {
        const permissions = response?.data?.results;
        setPermissionStructure(permissions);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar permissões',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    if (userPermissionData?.id) {
      fetchPermissionsStructure();
    }
  }, [userPermissionData]);

  useEffect(() => {
    if (user?.id) {
      fetchUserPermissions();
    }
  }, [user]);

  useEffect(() => {
    //este useEffect popula os campos com os valores default que vem da API.

    let permissionsArray: any = {
      permissionModule: []
    };

    //para cada módulo
    permissionStructure.forEach((moduleItem, index) => {
      let fullPermissions = true;
      let hasSpecificPermissions = false;

      permissionsArray.permissionModule[index] = {
        type: { id: 'none', name: 'Sem Permissão' },
        submodule: []
      };

      // For each submodule
      moduleItem.submodules.forEach((submodule, subIndex) => {
        permissionsArray.permissionModule[index].submodule[subIndex] = [];

        // For each permission
        submodule.permissions.forEach((permission, permissionIndex) => {
          if (userPermissionData?.permissions?.find((item: any) => item.id == permission.id)) {
            permissionsArray.permissionModule[index].submodule[subIndex].push({
              id: permission.id,
              name: permission.permission_label
            });
          } else {
            fullPermissions = false;
          }
        });
      });

      permissionsArray.permissionModule[index].submodule.forEach((submodule: any) => {
        if (submodule.length > 0) {
          hasSpecificPermissions = true;
        }
      });

      // Determine the type based on the presence of permissions
      if (fullPermissions) {
        permissionsArray.permissionModule[index].type = { id: 'full', name: 'Gerenciamento Completo' };
      } else if (hasSpecificPermissions) {
        permissionsArray.permissionModule[index].type = { id: 'specific', name: 'Permissões Específicas' };
      } else {
        permissionsArray.permissionModule[index].type = { id: 'none', name: 'Sem Permissão' };
      }
    });

    reset(permissionsArray);
    setLoading(false);
  }, [permissionStructure]);

  useEffect(() => {
    const emptyField = watchModule?.find((moduleItem: any) => moduleItem.type == null);
    if (emptyField) {
      setValue(`permissionModule.${watchModule.indexOf(emptyField)}.type`, { id: 'none', name: 'Sem Permissão' });
    }
  }, [watchModule]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!loading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
          <Box sx={{ ml: 2, pb: 1 }}>
            <Typography variant="body1">Selecione os módulos que o usuário terá acesso.</Typography>
            <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
              Você pode selecionar as permissões de cada módulo e submódulo.
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            {permissionStructure.map((moduleItem, index) => (
              <Accordion key={index} expanded={watchModule?.[index]?.type?.id == 'specific'} elevation={1}>
                <AccordionSummary
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor:
                      watchModule?.[index]?.type?.id == 'full'
                        ? hexToRgba(theme.palette.success.light, 0.08)
                        : watchModule?.[index]?.type?.id == 'specific'
                        ? hexToRgba(theme.palette.info.main, 0.1)
                        : 'inherit',
                    '&.Mui-expanded': {
                      backgroundColor: 'inherit' // Override the expanded background color
                    }
                  }}
                >
                  <Stack sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                      <Typography variant="body1">{moduleItem.module_label}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flex: 0.5 }}>
                      <ControlledComboBox
                        variant="standard"
                        control={control}
                        name={`permissionModule.${index}.type`}
                        label="Permissão"
                        defaultValue={{ id: 'none', name: 'Sem Permissão' }}
                        selectOptions={[
                          { id: 'none', name: 'Sem Permissão' },
                          { id: 'specific', name: 'Permissões Específicas' },
                          { id: 'full', name: 'Gerenciamento Completo' }
                        ]}
                      />
                    </Box>
                  </Stack>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <Box sx={{ p: 2 }}>
                    <Stack flexDirection="row" justifyContent="space-between" mt={2}>
                      <Box sx={{ display: 'flex', flex: 1 }}>
                        <Typography variant="body1" sx={{ textDecoration: 'underline' }}>
                          Submódulos
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flex: 1 }}>
                        <Typography variant="body1" sx={{ textDecoration: 'underline' }}>
                          Permissões
                        </Typography>
                      </Box>
                    </Stack>
                    {moduleItem.submodules.map((submodule, subIndex) => (
                      <Stack key={subIndex} sx={{ display: 'flex', flexDirection: 'row', mt: 4 }} divider={<Divider />}>
                        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                          <Typography>{submodule.submodule_label}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flex: 1 }}>
                          <ControlledComboBox
                            variant="standard"
                            control={control}
                            name={`permissionModule.${index}.submodule.${subIndex}`}
                            label="Permissão"
                            multiple
                            selectOptions={submodule.permissions.map((permission) => ({
                              id: permission.id,
                              name: permission.permission_label
                            }))}
                          />
                        </Box>
                      </Stack>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Stack flexDirection="row" columnGap={2} justifyContent="flex-end" mt={2}>
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancelar
            </Button>
          </Stack>
        </Box>
      )}
    </form>
  );
};
