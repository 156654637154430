import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import { Attachment } from '../../../../models/Attachment';

interface Props {
  item: Attachment;
  onClose: () => void;
}

export const PreviewImage = ({ item, onClose }: Props) => {
  const { accessToken } = useAuthContext();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    const fetchImageThumbnails = async () => {
      try {
        const response = await fetch(item.file, {
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        });
        if (response.ok) {
          const blob = await response.blob();
          setImage(URL.createObjectURL(blob));
        } else {
          console.error('Erro ao carregar a imagem:', response.statusText);
          return null;
        }
      } catch (error) {
        console.error('Erro ao carregar a imagem:', error);
        return null;
      }
    };
    fetchImageThumbnails();
  }, [accessToken]);

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Anexo
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={image} alt={item.name} style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
