import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, CardContent, Divider, Grid, Paper, Table, TableContainer, Typography } from '@mui/material';
import { useState } from 'react';
import { StyledTableBody } from '../../../../../components/basics/StyledTableBody';
import { StyledTableCell } from '../../../../../components/basics/StyledTableCell';
import { StyledTableHead } from '../../../../../components/basics/StyledTableHead';
import { StyledTableRow } from '../../../../../components/basics/StyledTableRow';
import { StyledTableCellHeader } from '../../../../../components/basics/TableHeaderComponent/styles';
import { Expense } from '../../../../../models/Expense';
import { Material, ResaleMaterial } from '../../../../../models/Material';
import { formatCurrency } from '../../../../../utils/utils';
import { useServiceOrderFormContext } from '../../context/ServiceOrderContext';
import { ApproveReasonDialog } from './ApproveReasonDialog';
import { ReproveReasonDialog } from './ReproveReasonDialog';

export const ApproveTab = () => {
  const { serviceOrder } = useServiceOrderFormContext();

  const WAITING_APPROVAL = serviceOrder.status == 'WAITING_APPROVAL';

  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showReproveReasonDialog, setShowReproveReasonDialog] = useState(false);
  // const [showOrderModal, setShowOrderModal] = useState(false);

  const handleReproveDialog = () => {
    setShowReproveReasonDialog(!showReproveReasonDialog);
  };

  const handleApproveDialog = () => {
    setShowApproveDialog(!showApproveDialog);
  };

  const totalValue = serviceOrder?.company_materials?.reduce((total, item) => total + Number(item.price) * item.quantity, 0);
  const totalResaleMaterials = serviceOrder?.resale_materials?.reduce((total, item) => total + Number(item.price) * item.quantity, 0);
  const totalExpenses = serviceOrder?.expenses?.reduce((total, expense) => total + Number(expense.cost), 0);

  const totalOrder =
    parseFloat(totalValue?.toString()) + parseFloat(totalResaleMaterials?.toString()) + parseFloat(totalExpenses?.toString());

  return (
    <Box p={2} m={2}>
      {/* Conteúdo da aba Aprovação */}
      <Typography variant="h3" gutterBottom>
        Aprovação
      </Typography>

      <Card sx={{ mt: 2, p: 2 }} elevation={2}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="h5" gutterBottom>
                Dados do Produto
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nro de Série: {serviceOrder?.product?.serial_number}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Descrição: {serviceOrder?.product?.description}
              </Typography>
            </Grid>

            {/* Tabela de Peças e Materiais */}
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Peças e Materiais Ipacol
              </Typography>
              <TableContainer component={Paper} elevation={2} sx={{ mt: 2 }}>
                <Table>
                  <StyledTableHead>
                    <StyledTableRow>
                      <StyledTableCellHeader>Descrição</StyledTableCellHeader>
                      <StyledTableCellHeader>Quantidade</StyledTableCellHeader>
                      <StyledTableCellHeader>Valor</StyledTableCellHeader>
                    </StyledTableRow>
                  </StyledTableHead>
                  <StyledTableBody>
                    {serviceOrder?.company_materials?.map((row: Material, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{row.material + ' - ' + row?.description}</StyledTableCell>
                        <StyledTableCell>{row.quantity}</StyledTableCell>
                        <StyledTableCell>{formatCurrency(row.price)}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </StyledTableBody>
                </Table>
              </TableContainer>

              {/* Totalizador de Peças e Materiais */}
              <Grid container justifyContent="flex-end" mt={4}>
                <Grid item xs={9}>
                  <Typography variant="h5" gutterBottom align="right">
                    Total Peças e Materiais:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom align="right">
                    {formatCurrency(totalValue)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 4 }} />
            </Grid>

            {/* Tabela de Peças e Materiais da Revenda */}
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Peças e Materiais Revenda
              </Typography>
              <TableContainer component={Paper} elevation={2} sx={{ mt: 2 }}>
                <Table>
                  <StyledTableHead>
                    <StyledTableRow>
                      <StyledTableCellHeader>Descrição</StyledTableCellHeader>
                      <StyledTableCellHeader>Quantidade</StyledTableCellHeader>
                      <StyledTableCellHeader>Valor</StyledTableCellHeader>
                    </StyledTableRow>
                  </StyledTableHead>
                  <StyledTableBody>
                    {serviceOrder?.resale_materials?.map((row: ResaleMaterial, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{row.material_description}</StyledTableCell>
                        <StyledTableCell>{row.quantity}</StyledTableCell>
                        <StyledTableCell>{formatCurrency(row.price)}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </StyledTableBody>
                </Table>
              </TableContainer>

              {/* Totalizador de Peças e Materiais da Revenda */}
              <Grid container justifyContent="flex-end" mt={4}>
                <Grid item xs={9}>
                  <Typography variant="h5" gutterBottom align="right">
                    Total Peças e Materiais Revenda:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom align="right">
                    {formatCurrency(totalResaleMaterials)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 4 }} />
            </Grid>

            {/* Tabela de Despesas */}
            <Grid item xs={12} mt={2}>
              <Typography variant="h5" gutterBottom>
                Despesas
              </Typography>

              {/* Tabela de Despesas */}
              <TableContainer component={Paper} sx={{ mt: 2 }} elevation={2}>
                <Table sx={{ minWidth: 650 }} aria-label="expenses table">
                  <StyledTableHead>
                    <StyledTableRow>
                      <StyledTableCellHeader>Descrição</StyledTableCellHeader>
                      <StyledTableCellHeader>Quantidade</StyledTableCellHeader>
                      <StyledTableCellHeader>Valor</StyledTableCellHeader>
                    </StyledTableRow>
                  </StyledTableHead>
                  <StyledTableBody>
                    {serviceOrder?.expenses?.map((row: Expense, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell sx={{ textTransform: 'uppercase' }}>{row?.description}</StyledTableCell>
                        <StyledTableCell sx={{ textTransform: 'uppercase' }}>{row?.quantity}</StyledTableCell>
                        <StyledTableCell sx={{ textTransform: 'uppercase' }}>{formatCurrency(row.cost!)}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </StyledTableBody>
                </Table>
              </TableContainer>

              {/* Totalizador de Despesas */}
              <Grid container justifyContent="flex-end" mt={4}>
                <Grid item xs={9}>
                  <Typography variant="h5" gutterBottom align="right">
                    Total Despesas:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom align="right">
                    {formatCurrency(totalExpenses)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 4 }} />
            </Grid>

            <Grid item xs={12}>
              <Grid container mt={4}>
                <Grid item xs={9}>
                  <Typography variant="h5" gutterBottom align="left">
                    Total da Ordem de Serviço:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom align="right">
                    {formatCurrency(totalOrder)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2 }} />
            </Grid>

            {WAITING_APPROVAL && (
              <Grid item xs={12} columnGap={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" startIcon={<CloseIcon />} onClick={() => handleReproveDialog()}>
                  Reprovar
                </Button>
                <Button variant="contained" color="success" startIcon={<CheckIcon />} onClick={() => handleApproveDialog()}>
                  Aprovar
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* DIALOGO DE REPROVAÇÃO / Motivo da reprovação da OS */}
      <ReproveReasonDialog open={showReproveReasonDialog} onClose={handleReproveDialog} />

      {/* DIÁLOGO DE APROVAÇÃO - GERAÇÃO DE PEDIDO */}
      <ApproveReasonDialog open={showApproveDialog} onClose={handleApproveDialog} onSubmitForm={handleApproveDialog} />

      {/* DIÁLOGO DE INFORMAÇÕES DO PEDIDO */}
      {/* <OrderCreationDialog open={showOrderModal} onClose={handleShowOrderModal} /> */}
    </Box>
  );
};
