import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { Defect } from '../../../models/Defect';
import { findSelectedOption } from '../../../utils/utils';
import { DefectsFilterFields, useDefectsPageContext } from '../context/DefectsPageContext';

interface Props {
  onClose: () => void;
}

const filterStatusOptions: SelectOption[] = [
  { id: null, name: 'Todos' },
  { id: true, name: 'Ativo' },
  { id: false, name: 'Inativo' }
];

const getDefaultValues = (filter?: Defect): Partial<DefectsFilterFields> => {
  return {
    is_active: findSelectedOption(filterStatusOptions, filter?.is_active)
  };
};

export const DefectsFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useDefectsPageContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<DefectsFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit = async (data: Partial<DefectsFilterFields>) => {
    data.is_active = (data.is_active as SelectOption)?.id ?? undefined;
    setFilterFields({ ...data } as DefectsFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as DefectsFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor="is_active">Situação</InputLabel>
              <ControlledComboBox
                name="is_active"
                placeholder="Situação"
                control={control}
                errorMessage={errors.is_active?.message?.toString()}
                selectOptions={filterStatusOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
