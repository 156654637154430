import { Box, Grid } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import AuthBackground1 from '../../../assets/images/media/banner_1.jpg';
import AuthBackground2 from '../../../assets/images/media/banner_2.jpg';
import AuthBackground3 from '../../../assets/images/media/banner_3.jpg';
import { AuthCard } from './AuthCard';

interface AuthWrapperProps {
  children: ReactNode;
}

const backgroundImages = [AuthBackground1, AuthBackground2, AuthBackground3];

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBackgroundIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 3000); // Troca a cada 10 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {backgroundImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt="AuthBackground"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: -1,
            transition: 'opacity 0.5s ease-in-out'
          }}
        />
      ))}
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{
          minHeight: '100vh'
        }}
      >
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
            <Grid item>
              <AuthCard>{children}</AuthCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
