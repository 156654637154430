import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { UserPermission } from '../models/UserPermission';

const baseUrl = '/users/companies-permissions/';

export const UserPermissionsService = {
  get: async (filterFields: any, page: number = 0, page_size: number = 1000) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(baseUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: UserPermission) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(baseUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: UserPermission) => {
    const axiosInstance = createAxiosInstance();
    let updateUrl = baseUrl;

    try {
      return await axiosInstance.put(updateUrl + data.id + '/', data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    let deleteUrl = baseUrl + id + '/';

    try {
      return await axiosInstance.delete(deleteUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    let deleteUrl = baseUrl + 'batch-delete/';

    try {
      return await axiosInstance.post(deleteUrl, {
        ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
