import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Company } from '../../../models/Company';
import { CompaniesService } from '../../../services/Companies.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface CompaniesFilterFields extends Company {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface CompaniesResult extends ApiResults {
  results: Company[];
}

interface CompaniesContextProps {
  companies: CompaniesResult | undefined;
  fetchCompanies: () => void;
  deleteCompany: (id: number) => Promise<void>;
  deleteManyCompanies: (items: string[]) => Promise<void>;
  filterFields: CompaniesFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<CompaniesFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const CompaniesContext = createContext<CompaniesContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const CompaniesPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [companies, setCompanies] = React.useState<CompaniesResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<CompaniesFilterFields>({});

  const [isInitialLoad, setIsInitialLoad] = React.useState<boolean>(true);

  const fetchCompanies = async () => {
    dispatch(showSpinner());
    try {
      const response = await CompaniesService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setCompanies(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteCompany = async (id: number) => {
    dispatch(showSpinner());
    try {
      await CompaniesService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchCompanies();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyCompanies = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await CompaniesService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchCompanies();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchCompanies();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: CompaniesContextProps = {
    companies,
    fetchCompanies,
    deleteCompany,
    deleteManyCompanies,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <CompaniesContext.Provider value={contextValue}>{children}</CompaniesContext.Provider>;
};

export const useCompaniesPageContext = () => {
  const context = useContext(CompaniesContext);
  if (!context) {
    throw new Error('useCompaniesContext must be used within a CompaniesProvider');
  }
  return context;
};
