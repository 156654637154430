import { Box, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ServiceOrder } from '../../../../../models/ServiceOrder';
import { TechnicalAnalysis } from '../../../../../models/TechnicalAnalysis';
import { ServiceOrderTechnicalAnalysesService } from '../../../../../services/ServiceOrderTechnicalAnalyses.service';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { formatDateTime } from '../../../../../utils/utils';

interface DefectsList {
  location: string;
  defects_groups_relationships: number[];
}

interface FormFields {
  support_address: string;
  use_client_address: boolean;
  work_order: number;
  attachments: number[];
  service_type: 'WARRANTY' | 'ASSISTANCE' | 'TECHNICAL_DELIVERY';
  analysis_date: string;
  description: string;
  defects: {
    location: string;
    defects_groups_relationships: number[];
  }[];
}

interface Props {
  serviceOrder: ServiceOrder;
}

export const TrackingTab = ({ serviceOrder }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [completedSteps, setCompletedSteps] = useState<number>(0);
  const [analysisHistory, setAnalysisHistory] = useState<TechnicalAnalysis[]>([]);
  const [steps, setSteps] = useState<any[]>([]);

  const fetchTechnicalAnalyses = async () => {
    try {
      const response = await ServiceOrderTechnicalAnalysesService.getByServiceOrderId(serviceOrder.id);
      setAnalysisHistory(response?.data?.results);
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar análises técnicas', severity: 'error' }));
    }
  };

  const getDoneSteps = (statusList: any, status: string) => {
    const index = statusList.find((item) => item.status === status)?.index;
    setCompletedSteps(index + 1);
  };

  const createSteps = () => {
    const SERVICE_ORDER_STATUS = [
      { status: 'REGISTER', name: 'Registro', index: 0, date: serviceOrder.created_at },
      { status: 'WAITING_TECHNICAL_ANALYSIS', name: 'Análise Técnica', index: 1, date: analysisHistory?.[0]?.created_at },
      { status: 'WAITING_MATERIALS_EXPENSES', name: 'Materiais e Despesas', index: 2, date: '' },
      { status: 'WAITING_APPROVAL', name: 'Aprovação', index: 3, date: '' },
      { status: 'WAITING_EVIDENCES', name: 'Evidências', index: 4, date: serviceOrder.evidence_date },
      { status: 'WAITING_CONCLUSION', name: 'Conclusão', index: 5 },
      { status: 'REPROVED', name: 'Reprovado', index: 0, date: '' },
      { status: 'COMPLETED', name: 'Completo', index: 5, date: serviceOrder.conclusion_date }
    ];
    getDoneSteps(SERVICE_ORDER_STATUS, serviceOrder.status);
    setSteps(SERVICE_ORDER_STATUS);
  };

  useEffect(() => {
    if (serviceOrder?.id) {
      (async () => {
        await fetchTechnicalAnalyses();
        setLoading(false);
      })();
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (!loading) {
      createSteps();
    }
  }, [loading]);

  return (
    <Box p={2} m={2}>
      <Paper sx={{ p: 4 }}>
        <Stepper orientation="vertical" activeStep={completedSteps - 1}>
          {steps.slice(0, completedSteps).map((item, index) => {
            //se for o ultimo item, exibe desabilitado
            return (
              <Step key={item.status}>
                <StepLabel>
                  <Typography variant="h6">{item.name}</Typography>
                  {item.date && <Typography variant="body2">{formatDateTime(item.date)}</Typography>}
                  {index === completedSteps - 1 && <Typography variant="body2">{serviceOrder.status_label}</Typography>}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
    </Box>
  );
};
